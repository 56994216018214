import React from 'react'
import { useHistory } from 'react-router-dom'
import '../styles/theme.css'
import { Jumbotron, Container, Button } from 'react-bootstrap'
import MainImage from '../helpers/MainImage'
import useIsLoggedIn from '../helpers/store/useIsLoggedIn'
import useCurrentUser from '../helpers/store/useCurrentUser'
// import subtitle from '../images/subtitle.png'
// import LuxePorn from '../images/luxury-phrase.png'


export default function HomePage() {
 
  let history = useHistory()
  const isLoggedIn = useIsLoggedIn()
  const user = useCurrentUser()

  return (
    <div className="main">
      <Jumbotron id="main">
        <Container >
          <div className="title">
            <MainImage />

            { 
              !isLoggedIn &&
              <center>
                <div className="d-flex flex-row justify-content-center">
                  <div className="p-1">
                    <Button variant="secondary" onClick={() => history.push('/register/new-account')}>
                      Sign Up Now
                    </Button>
                  </div>
                  <div className="p-1">
                    <Button variant="secondary" onClick={() => history.push('/register/log-in')}>
                      Log In
                    </Button>
                  </div>
                </div>
              </center>
            }

              {/* <center> 
                <img src={LuxePorn} alt="subtitle" className="main-subtitle" />
              </center> */}
            
          </div>

          <div>


          </div>
        </Container>
      </Jumbotron>
    </div>
  )
}