
import { useState, useContext, useEffect } from 'react'
import { UserContext } from './currentUserContext'

//NOTE: custom hook that will return true or false if user is logged in
export default function useIsLoggedIn() {

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const { state } = useContext(UserContext)

  useEffect(() => {
    if (state.user.isLoggedIn !== isLoggedIn) {
      setIsLoggedIn(state.user.isLoggedIn)
    }
  }, [state, isLoggedIn, setIsLoggedIn])
  // console.log(isLoggedIn)
  return isLoggedIn
}