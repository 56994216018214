
import React, { useState, useEffect } from 'react'
import { BsPersonPlusFill, BsPersonCheckFill, BsFillPersonDashFill } from 'react-icons/bs'

export default function FollowIcon(props) {
  //NOTE: should take in prop of whether or not user is a follow currently or not. 
  //      if is a follower should should a check mark icon, if not follower should show plus icon

  const [color, setColor] = useState('#999999')
  const [size, setSize] = useState('1.5em')

  useEffect(() => {
    if (props.color && props.color !== color) {
      setColor(props.color)
    }
    if (props.size && props.size !== size) {
      setSize(props.size)
    }
  }, [props, color, setColor, size, setSize])

  return (

    <div 
      onClick={() => props.handleClick()} 
      data-customtooltip={ props.isFollowing ? "Remove from Following" : "Follow"}
    >
      { props.isFollowing ? 
        ( <BsPersonCheckFill color={color} size={size} className="glow"/> ) 
          : 
        ( <BsPersonPlusFill color={color} size={size} /> )
      }
    </div>
  )

}