import React, { useState, useEffect } from 'react'
import { FaCommentDollar } from 'react-icons/fa'

export default function GemIcon(props) {
  const [color, setColor] = useState('#999999')
  const [size, setSize] = useState('1.5em')

  useEffect(() => {
    if (props.color && props.color !== color) {
      setColor(props.color)
    }
    if (props.size && props.size !== size) {
      setSize(props.size)
    }
    if (props.active && props.activeIconColor) {
      setColor(props.activeIconColor)
    }
    if (!props.active && props.color) {
      setColor(props.color)
    }
  }, [props, color, setColor, size, setSize])

  return (
    <FaCommentDollar 
      color={color}
      size={size}
    />
  )
}