import React, { useReducer } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";
import  { BreakpointProvider, setDefaultBreakpoints, Breakpoint } from 'react-socks';

import Nabber from './components/nav/Nabber'
import MobileNabber from './components/nav/MobileNabber'
import HomePage from './pages/HomePage'
import CreatorPage from './pages/CreatorPage'
import RegistrationPage from './pages/RegistrationPage'
import CreatorDashPage from './pages/CreatorDashPage'
import LogInPage from './pages/LogInPage'
import HelpPage from './pages/HelpPage'
// import FollowersPage from './pages/FollowersPage'
// import ShowcasePage from './pages/ShowcasePage'
import DiscoverCreatorsPage from './pages/DiscoverCreatorsPage'
import PostPage from './pages/PostPage'
import NewPostPage from './pages/NewPostPage'

import { UserContext, userReducer, userState } from './helpers/store/currentUserContext'
import { IconContext } from 'react-icons'
import { DiscoverProvider } from './helpers/store/DiscoverCreatorContext';

import UserActivityWrapper from './helpers/UserActivityWrapper'
// import useNotificationActions from './helpers/notificationActions'

setDefaultBreakpoints([
  { xs: 0 },
  { s: 376 },
  { m: 426 },
  { l: 769 },
  { xl: 1025 }
])

function App() {

  const [ state, dispatch ] = useReducer(userReducer, userState)
  // const { subscribeToNotifications } = useNotificationActions()
  // subscribeToNotifications()
  return (
    <UserContext.Provider value={{state, dispatch}}>
      <BreakpointProvider>
        <IconContext.Provider 
          value={
            { 
              size: '2.25em', 
              color: '#000000' 
            }
          }>
          <UserActivityWrapper />
          <Router>

            <Breakpoint m down>
              <MobileNabber/>
            </Breakpoint>

            <Breakpoint l up>
              <Nabber/>
            </Breakpoint>

            <Route exact path="/" component={ HomePage } />
            <Route exact path="/register/new-account" component={RegistrationPage} />
            <Route exact path="/register/log-in" component={LogInPage} />
            <Route exact path="/register/log-in/forgotpassword" render={() => {
              return ( <LogInPage forgotPassword />)
            }} 

            />
            <Route exact path="/help" component={HelpPage} />
            <Route exact path="/discover" >
              <DiscoverProvider >
                <DiscoverCreatorsPage />
              </DiscoverProvider>
            </Route>
            <Route exact path="/@:creatorID/:postID" component={PostPage} />
            <Route exact path="/@:creatorID" component={CreatorPage} />
            <Route exact path="/@:creatorID/post/new" component={NewPostPage} />

            <Switch>
              <Route exact path="/dashboard"> 
                  <CreatorDashPage/>
                </Route>
                <Route exact path={["/dashboard/:stageKey", "/dashboard/:stageKey/:convoID"]}> 
                  <CreatorDashPage stage="messages" />
                </Route>
                <Route exact path="/dashboard/:stageKey" render={(props) => 
                    {
                      if (props.match.params.stageKey.startsWith('@')) {
                        return ( <CreatorDashPage stage="my-page" /> )
                      } else return ( <CreatorDashPage /> )
                    }
                  }
                />
              </Switch>

            </Router>
          
        </IconContext.Provider>
      </BreakpointProvider>
    </UserContext.Provider>
  );
}

export default App

// state.user.isLoggedIn ? CreatorDashPage : HomePage
