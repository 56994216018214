import React, { useState, useEffect } from 'react'
import { Modal, ButtonGroup, Button } from 'react-bootstrap'
import QuickTipConfirmationCall from './QuickTipConfirmationCall'

export default function QuickTip(props) {
  
  const [ tipAmount, setTipAmount ] = useState('')
  const [ confirmationCall, setConfirmationCall ] = useState(false)
  const [ error, setError ] = useState(false)

  const handleClose = () => {
    props.handleComplete(false)
    setTipAmount('')
    setError(false)
    setConfirmationCall(false)
  }

  const handleSetTip = e => {
    setTipAmount(e.target.value)
  }

  const handleTipConfirmation = e => {
    console.log("Tipping " + tipAmount)
    tipAmount !== '' ? setConfirmationCall(true) : setError(true)
  }

  const setButtonVariant = v => tipAmount === v ? 'success' : 'secondary'

  return (
    <Modal 
      show={props.show} 
      onHide={handleClose} 
      style={{opacity: '1'}} 
    >
      
      <Modal.Header closeButton id="tip-modal-header" >   
        <div>
          <Modal.Title>
              { confirmationCall ? ( 'Tip Confirmation' ) : ( 'Token of Appreciation' ) }
          </Modal.Title>
        </div>
      </Modal.Header>

      <Modal.Body id="tip-modal-body">
        <div >

        { confirmationCall ? 

          ( < QuickTipConfirmationCall tipAmount={tipAmount} username={props.username} userID={props.userID}/> )
            
            :

          (
            <>

              <div className="p-3 d-flex flex-column justify-content-center text-center">
                How much would you like to tip {props.username}? 
              </div>

              <div className="p-3 d-flex justify-content-center">
                <ButtonGroup aria-label="tip-button-group">
                  <Button 
                    variant={setButtonVariant('1')} 
                    value="1"
                    onClick={(e) => handleSetTip(e)}
                  >
                    $1
                  </Button>
                  <Button 
                    variant={setButtonVariant('5')} 
                    value="5"
                    onClick={(e) => handleSetTip(e)}
                  >
                    $5
                  </Button>
                  <Button 
                    variant={setButtonVariant('10')} 
                    value="10"
                    onClick={(e) => handleSetTip(e)}
                  >
                    $10
                  </Button>
                  <Button 
                    variant={setButtonVariant('20')} 
                    value="20"
                    onClick={(e) => handleSetTip(e)}
                  >
                    $20
                  </Button>
                  <Button 
                    variant={setButtonVariant('50')} 
                    value="50"
                    onClick={(e) => handleSetTip(e)}
                  >
                    $50
                  </Button>
                  <Button 
                    variant={setButtonVariant('100')} 
                    value="100"
                    onClick={(e) => handleSetTip(e)}
                  >
                    $100
                  </Button>
                </ButtonGroup>
              </div>

              { error && (
                <div className="error p-1 d-flex justify-content-center">
                  Amount cannot be empty. Please select amount or cancel send tip.
                </div>
              ) }

              <div className="p-3 d-flex justify-content-center">
                <Button onClick={e => handleTipConfirmation(e)}>
                  Confirm
                </Button>
              </div>

              <div className="p-3 d-flex flex-column justify-content-center">
                <p className="disclaimer">
                  * TIPS ARE CONSIDERED A GIFT ARE NOT REQUIRED. 
                  <br/> 
                  TRANSACTIONS ARE FINAL AND NON-REFUNDABLE.
                </p>
              </div>

            </>
          ) }

        </div>

      </Modal.Body>

    </Modal>
  )

}