import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import { CgClose } from 'react-icons/cg'

export default function ImageModal(props) {
  // const [ show, setShow ] = useState(false)
  const [ image, setImage ] = useState('')

  // const handleClose = () => setShow(false)
  // const handleShow = () => setShow(true)

  return (
    
      <Modal 
        show={props.show} 
        onHide={props.handleClose}
        animation={false} 
        id="image-modal trans" 
        scrollable={false}
        size="xl"
        className="pop-modal"
        // style={{backgroundColor: 'transparent'}}
      >
    
        <div className="modal-container" >

          <div className="close-container">
            <CgClose className="close-icon" onClick={() => props.handleClose()}/>
          </div>
          <center >
            <img src={props.content} alt="pop out content" className="modal-pic"/>
          </center>
        </div>
       
      </Modal>
   
  )
}