import React from 'react'
import { Card } from 'react-bootstrap'

export default function LearnVidsTile() {
  return (
    <div className="m-1" >
      <Card>
        <Card.Header>
          Getting Started
        </Card.Header>
        <Card.Body>

          <div className="d-flex flex-row justify-content-evenly flex-wrap overflow-scroll">

            <div className="p-2">
              <iframe 
                width="100%" 
                height="100%" 
                src="https://www.youtube.com/embed/F3QpgXBtDeo" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
              >
              </iframe>
            </div>

            <div className="p-2">
              <iframe 
                width="100%" 
                height="100%" 
                src="https://www.youtube.com/embed/6Gu2QMTAkEU" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
              >
              </iframe>
            </div>

            <div className="p-2">
              <iframe 
                width="100%" 
                height="100%" 
                src="https://www.youtube.com/embed/rYQgy8QDEBI" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
              >
                </iframe>
            </div>

            <div className="p-2">
              <iframe 
                width="100%" 
                height="100%" 
                src="https://www.youtube.com/embed/eynxyoKgpng" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
              >
              </iframe>
            </div>

          </div>

        </Card.Body>

      </Card>
    </div>
  )
}