import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'

//TODO: name, email, birthdate, age verification
export default function SignUpForm(props) {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [username, setUsername] = useState('')
  const [month, setMonth] = useState('')
  const [day, setDay] = useState('')
  const [year, setYear] = useState('')
  const [ passwordError, setPasswordError ] = useState(false)
  const [ underageError, setUnderageError ] = useState(false)
  // const [termsAgreement, setTermsAgreement] = useState(false)
  //NOTE: maybe after email is verified, have user upload photo of ID and photo of self?
  

const underAgeValidate = () => {
  const birthday = `${year}/${month}/${day}`
	const myBirthday = new Date(birthday)
	const currentDate = new Date().toJSON().slice(0,10)+' 01:00:00'
	const myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));
  setUnderageError(18 > myAge)
  console.log(myAge)
  return myAge < 18 ? false : true 
} 

  const handleSubmit = e => {
    //NOTE: needs to add validations to check if email and username are unique in system
    console.log("inside handle submit")
    // debugger
    e.preventDefault()
    setPasswordError( password !== passwordConfirmation )
    if ( password === passwordConfirmation && underAgeValidate() ) {
      const formData = {
        email: email, 
        password: password, 
        birthdate: { month: month, day: day, year: year } 
      }
      props.handleSubmit(formData)
    } 
    // else {
    //   setPasswordError(password === passwordConfirmation)
    // }
  }

  return (
    <div id="register-form" className="d-flex flex-column top-bumper">
      <div className="h3">
        <strong>
          Sign Up
        </strong>
      </div>
      <Form onSubmit={e => handleSubmit(e)}>

          <Form.Group controlId="form-email">
            <Form.Label>Email address</Form.Label>
            <Form.Control 
              type="email" 
              placeholder="Please enter a valid email" 
              value={email}
              required
              onChange={e => setEmail(e.target.value)}
            />
            { props.errors && (
              <Form.Text className="text-danger">
                { props.errors}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group controlId="form-password">
              <Form.Label>Password</Form.Label>
              <Form.Control 
                type="password" 
                value={password} 
                required
                onChange={e => setPassword(e.target.value)}
              />
              <Form.Text className="text-muted">
                For security purposes, passwords must contain uppercase & lowercase characters, and a number. 
              </Form.Text>
              <Form.Text className="text-muted">
                Passwords must be a minimum of 8 characters long.
              </Form.Text>
          </Form.Group>

          <Form.Group controlId="form-password-confirm">
            <Form.Label>Password Confirmation</Form.Label>
            <Form.Control 
              type="password" 
              value={passwordConfirmation} 
              required
              onChange={e => setPasswordConfirmation(e.target.value)}
            />
            { passwordError && (
              <Form.Text className="text-danger">
                Passwords do not match!
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group>
            <Form.Label>Date of Birth</Form.Label>
            <Form.Row style={{marginLeft: "0px"}}>  
              <Form.Group controlId="form-month">
                <Form.Label>Month</Form.Label>
                  <Form.Control 
                    as="select" 
                    value={month}
                    required
                    onChange={e => setMonth(e.target.value)}
                  >
                    <option></option>
                    <option>Jan</option>
                    <option>Feb</option>
                    <option>Mar</option>
                    <option>Apr</option>
                    <option>May</option>
                    <option>Jun</option>
                    <option>Jul</option>
                    <option>Aug</option>
                    <option>Sept</option>
                    <option>Oct</option>
                    <option>Nov</option>
                    <option>Dec</option>
                  </Form.Control>
              </Form.Group>
              <Form.Group controlId="form-day">
                <Form.Label>Day</Form.Label>
                  <Form.Control 
                    as="select" 
                    value={day}
                    required
                    onChange={e => setDay(e.target.value)}
                  >
                    <option></option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                    <option>21</option>
                    <option>22</option>
                    <option>23</option>
                    <option>24</option>
                    <option>25</option>
                    <option>26</option>
                    <option>27</option>
                    <option>28</option>
                    <option>29</option>
                    <option>30</option>
                    <option>31</option>
                  </Form.Control>
              </Form.Group>
              <Form.Group controlId="form-year">
                <Form.Label>Year</Form.Label>
                  <Form.Control 
                    as="select" 
                    value={year}
                    required
                    onChange={e => setYear(e.target.value)}
                  >
                    <option></option>
                    <option>2003</option>
                    <option>2002</option>
                    <option>2001</option>
                    <option>2000</option>
                    <option>1999</option>
                    <option>1998</option>
                    <option>1997</option>
                    <option>1996</option>
                    <option>1995</option>
                    <option>1994</option>
                    <option>1993</option>
                    <option>1992</option>
                    <option>1991</option>
                    <option>1990</option>
                    <option>1989</option>
                    <option>1988</option>
                    <option>1987</option>
                    <option>1986</option>
                    <option>1985</option>
                    <option>1984</option>
                    <option>1983</option>
                    <option>1982</option>
                    <option>1981</option>
                    <option>1980</option>
                    <option>1979</option>
                    <option>1978</option>
                    <option>1977</option>
                    <option>1976</option>
                    <option>1975</option>
                    <option>1974</option>
                    <option>1973</option>
                    <option>1972</option>
                    <option>1971</option>
                    <option>1970</option>
                    <option>1969</option>
                    <option>1968</option>
                    <option>1967</option>
                    <option>1966</option>
                    <option>1965</option>
                    <option>1964</option>
                    <option>1963</option>
                    <option>1962</option>
                    <option>1961</option>
                    <option>1960</option>
                    <option>1959</option>
                    <option>1958</option>
                    <option>1957</option>
                    <option>1956</option>
                    <option>1955</option>
                    <option>1954</option>
                    <option>1953</option>
                    <option>1952</option>
                    <option>1951</option>
                    <option>1950</option>
                    <option>1949</option>
                    <option>1948</option>
                    <option>1947</option>
                    <option>1946</option>
                    <option>1945</option>
                    <option>1944</option>
                    <option>1943</option>
                    <option>1942</option>
                    <option>1941</option>
                    <option>1940</option>
                    <option>1939</option>
                    <option>1938</option>
                    <option>1937</option>
                    <option>1936</option>
                    <option>1935</option>
                    <option>1934</option>
                    <option>1933</option>
                    <option>1932</option>
                    <option>1931</option>
                    <option>1930</option>
                    <option>1929</option>
                    <option>1928</option>
                    <option>1927</option>
                    <option>1926</option>
                    <option>1925</option>
                  </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Text className="text-muted">
              Must be at least 18 years of age. 
            </Form.Text>
            { underageError && (
              <div className="error p-2">
                MUST BE AT LEAST 18 TO ENTER SITE!!!
              </div>
            )}
        </Form.Group>

        <Button 
          variant="secondary" 
          type="submit" 
          id="button" 
          onClick={e => handleSubmit(e)}
        >
          Sign Me Up!
        </Button>
      
      </Form>

    </div>
  )
}