/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      full_name
      email
      description
      username
      display_name
      bday
      bio
      avatar {
        bucket
        region
        key
        type
        userID
      }
      current_sub_price {
        price
      }
      discoverable
      last_online
      posts {
        items {
          id
          createdAt
          caption
          userID
          updatedAt
        }
        nextToken
      }
      content {
        bucket
        region
        key
        type
        userID
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      banner {
        default_theme
        upload {
          bucket
          region
          key
          type
          userID
        }
      }
      followers {
        items {
          id
          follower_userID
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          follower_userID
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      favorites {
        items {
          id
          user_id
          favorite_user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          notification_type
          user_id
          message
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          creatorID
          description
          price
          purchaserID
          sold
          createdAt
          updatedAt
        }
        nextToken
      }
      inventory {
        items {
          id
          creatorID
          description
          price
          purchaserID
          sold
          createdAt
          updatedAt
        }
        nextToken
      }
      current_subscriptions {
        items {
          id
          creatorID
          subscriberID
          start_date
          createdAt
          updatedAt
        }
        nextToken
      }
      current_subscribers {
        items {
          id
          creatorID
          subscriberID
          start_date
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          userID
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      full_name
      email
      description
      username
      display_name
      bday
      bio
      avatar {
        bucket
        region
        key
        type
        userID
      }
      current_sub_price {
        price
      }
      discoverable
      last_online
      posts {
        items {
          id
          createdAt
          caption
          userID
          updatedAt
        }
        nextToken
      }
      content {
        bucket
        region
        key
        type
        userID
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      banner {
        default_theme
        upload {
          bucket
          region
          key
          type
          userID
        }
      }
      followers {
        items {
          id
          follower_userID
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          follower_userID
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      favorites {
        items {
          id
          user_id
          favorite_user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          notification_type
          user_id
          message
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          creatorID
          description
          price
          purchaserID
          sold
          createdAt
          updatedAt
        }
        nextToken
      }
      inventory {
        items {
          id
          creatorID
          description
          price
          purchaserID
          sold
          createdAt
          updatedAt
        }
        nextToken
      }
      current_subscriptions {
        items {
          id
          creatorID
          subscriberID
          start_date
          createdAt
          updatedAt
        }
        nextToken
      }
      current_subscribers {
        items {
          id
          creatorID
          subscriberID
          start_date
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          userID
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      full_name
      email
      description
      username
      display_name
      bday
      bio
      avatar {
        bucket
        region
        key
        type
        userID
      }
      current_sub_price {
        price
      }
      discoverable
      last_online
      posts {
        items {
          id
          createdAt
          caption
          userID
          updatedAt
        }
        nextToken
      }
      content {
        bucket
        region
        key
        type
        userID
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      banner {
        default_theme
        upload {
          bucket
          region
          key
          type
          userID
        }
      }
      followers {
        items {
          id
          follower_userID
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          follower_userID
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      favorites {
        items {
          id
          user_id
          favorite_user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          notification_type
          user_id
          message
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          creatorID
          description
          price
          purchaserID
          sold
          createdAt
          updatedAt
        }
        nextToken
      }
      inventory {
        items {
          id
          creatorID
          description
          price
          purchaserID
          sold
          createdAt
          updatedAt
        }
        nextToken
      }
      current_subscriptions {
        items {
          id
          creatorID
          subscriberID
          start_date
          createdAt
          updatedAt
        }
        nextToken
      }
      current_subscribers {
        items {
          id
          creatorID
          subscriberID
          start_date
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          userID
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPurchaseItem = /* GraphQL */ `
  mutation CreatePurchaseItem(
    $input: CreatePurchaseItemInput!
    $condition: ModelPurchaseItemConditionInput
  ) {
    createPurchaseItem(input: $input, condition: $condition) {
      id
      creatorID
      media {
        bucket
        region
        key
        type
        userID
      }
      description
      price
      purchaserID
      sold
      tags {
        id
        tag {
          id
          tag
          createdAt
          updatedAt
        }
        user {
          id
          full_name
          email
          description
          username
          display_name
          bday
          bio
          discoverable
          last_online
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePurchaseItem = /* GraphQL */ `
  mutation UpdatePurchaseItem(
    $input: UpdatePurchaseItemInput!
    $condition: ModelPurchaseItemConditionInput
  ) {
    updatePurchaseItem(input: $input, condition: $condition) {
      id
      creatorID
      media {
        bucket
        region
        key
        type
        userID
      }
      description
      price
      purchaserID
      sold
      tags {
        id
        tag {
          id
          tag
          createdAt
          updatedAt
        }
        user {
          id
          full_name
          email
          description
          username
          display_name
          bday
          bio
          discoverable
          last_online
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePurchaseItem = /* GraphQL */ `
  mutation DeletePurchaseItem(
    $input: DeletePurchaseItemInput!
    $condition: ModelPurchaseItemConditionInput
  ) {
    deletePurchaseItem(input: $input, condition: $condition) {
      id
      creatorID
      media {
        bucket
        region
        key
        type
        userID
      }
      description
      price
      purchaserID
      sold
      tags {
        id
        tag {
          id
          tag
          createdAt
          updatedAt
        }
        user {
          id
          full_name
          email
          description
          username
          display_name
          bday
          bio
          discoverable
          last_online
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      notification_type
      user_id
      message
      seen
      metadata {
        trigger_user
        hyperlink
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      notification_type
      user_id
      message
      seen
      metadata {
        trigger_user
        hyperlink
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      notification_type
      user_id
      message
      seen
      metadata {
        trigger_user
        hyperlink
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFavoriteConnection = /* GraphQL */ `
  mutation CreateFavoriteConnection(
    $input: CreateFavoriteConnectionInput!
    $condition: ModelFavoriteConnectionConditionInput
  ) {
    createFavoriteConnection(input: $input, condition: $condition) {
      id
      user_id
      favorite_user_id
      favorite_user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFavoriteConnection = /* GraphQL */ `
  mutation UpdateFavoriteConnection(
    $input: UpdateFavoriteConnectionInput!
    $condition: ModelFavoriteConnectionConditionInput
  ) {
    updateFavoriteConnection(input: $input, condition: $condition) {
      id
      user_id
      favorite_user_id
      favorite_user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFavoriteConnection = /* GraphQL */ `
  mutation DeleteFavoriteConnection(
    $input: DeleteFavoriteConnectionInput!
    $condition: ModelFavoriteConnectionConditionInput
  ) {
    deleteFavoriteConnection(input: $input, condition: $condition) {
      id
      user_id
      favorite_user_id
      favorite_user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFollowingConnection = /* GraphQL */ `
  mutation CreateFollowingConnection(
    $input: CreateFollowingConnectionInput!
    $condition: ModelFollowingConnectionConditionInput
  ) {
    createFollowingConnection(input: $input, condition: $condition) {
      id
      follower_userID
      following_userID
      follower {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      following {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFollowingConnection = /* GraphQL */ `
  mutation UpdateFollowingConnection(
    $input: UpdateFollowingConnectionInput!
    $condition: ModelFollowingConnectionConditionInput
  ) {
    updateFollowingConnection(input: $input, condition: $condition) {
      id
      follower_userID
      following_userID
      follower {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      following {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFollowingConnection = /* GraphQL */ `
  mutation DeleteFollowingConnection(
    $input: DeleteFollowingConnectionInput!
    $condition: ModelFollowingConnectionConditionInput
  ) {
    deleteFollowingConnection(input: $input, condition: $condition) {
      id
      follower_userID
      following_userID
      follower {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      following {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      messages {
        items {
          id
          text
          author_id
          recipient_id
          conversationID
          read
          when
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        userID
      }
      seen_typing {
        userID
        last_typed
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      messages {
        items {
          id
          text
          author_id
          recipient_id
          conversationID
          read
          when
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        userID
      }
      seen_typing {
        userID
        last_typed
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      messages {
        items {
          id
          text
          author_id
          recipient_id
          conversationID
          read
          when
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        userID
      }
      seen_typing {
        userID
        last_typed
      }
      createdAt
      updatedAt
    }
  }
`;
export const createConversationLinkConnection = /* GraphQL */ `
  mutation CreateConversationLinkConnection(
    $input: CreateConversationLinkConnectionInput!
    $condition: ModelConversationLinkConnectionConditionInput
  ) {
    createConversationLinkConnection(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        members {
          userID
        }
        seen_typing {
          userID
          last_typed
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateConversationLinkConnection = /* GraphQL */ `
  mutation UpdateConversationLinkConnection(
    $input: UpdateConversationLinkConnectionInput!
    $condition: ModelConversationLinkConnectionConditionInput
  ) {
    updateConversationLinkConnection(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        members {
          userID
        }
        seen_typing {
          userID
          last_typed
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteConversationLinkConnection = /* GraphQL */ `
  mutation DeleteConversationLinkConnection(
    $input: DeleteConversationLinkConnectionInput!
    $condition: ModelConversationLinkConnectionConditionInput
  ) {
    deleteConversationLinkConnection(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        members {
          userID
        }
        seen_typing {
          userID
          last_typed
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      text
      media {
        bucket
        region
        key
        type
        userID
      }
      author_id
      recipient_id
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        members {
          userID
        }
        seen_typing {
          userID
          last_typed
        }
        createdAt
        updatedAt
      }
      read
      when
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      text
      media {
        bucket
        region
        key
        type
        userID
      }
      author_id
      recipient_id
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        members {
          userID
        }
        seen_typing {
          userID
          last_typed
        }
        createdAt
        updatedAt
      }
      read
      when
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      text
      media {
        bucket
        region
        key
        type
        userID
      }
      author_id
      recipient_id
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        members {
          userID
        }
        seen_typing {
          userID
          last_typed
        }
        createdAt
        updatedAt
      }
      read
      when
      createdAt
      updatedAt
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      tag
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      tag
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      tag
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserTag = /* GraphQL */ `
  mutation CreateUserTag(
    $input: CreateUserTagInput!
    $condition: ModelUserTagConditionInput
  ) {
    createUserTag(input: $input, condition: $condition) {
      id
      tag {
        id
        tag
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserTag = /* GraphQL */ `
  mutation UpdateUserTag(
    $input: UpdateUserTagInput!
    $condition: ModelUserTagConditionInput
  ) {
    updateUserTag(input: $input, condition: $condition) {
      id
      tag {
        id
        tag
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserTag = /* GraphQL */ `
  mutation DeleteUserTag(
    $input: DeleteUserTagInput!
    $condition: ModelUserTagConditionInput
  ) {
    deleteUserTag(input: $input, condition: $condition) {
      id
      tag {
        id
        tag
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSubPlan = /* GraphQL */ `
  mutation CreateSubPlan(
    $input: CreateSubPlanInput!
    $condition: ModelSubPlanConditionInput
  ) {
    createSubPlan(input: $input, condition: $condition) {
      id
      creatorID
      price {
        price
      }
      subscriberID
      start_date
      createdAt
      updatedAt
    }
  }
`;
export const updateSubPlan = /* GraphQL */ `
  mutation UpdateSubPlan(
    $input: UpdateSubPlanInput!
    $condition: ModelSubPlanConditionInput
  ) {
    updateSubPlan(input: $input, condition: $condition) {
      id
      creatorID
      price {
        price
      }
      subscriberID
      start_date
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubPlan = /* GraphQL */ `
  mutation DeleteSubPlan(
    $input: DeleteSubPlanInput!
    $condition: ModelSubPlanConditionInput
  ) {
    deleteSubPlan(input: $input, condition: $condition) {
      id
      creatorID
      price {
        price
      }
      subscriberID
      start_date
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      createdAt
      media {
        bucket
        region
        key
        type
        userID
      }
      caption
      likes {
        items {
          id
          postID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      comments {
        items {
          id
          text
          userID
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      createdAt
      media {
        bucket
        region
        key
        type
        userID
      }
      caption
      likes {
        items {
          id
          postID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      comments {
        items {
          id
          text
          userID
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      createdAt
      media {
        bucket
        region
        key
        type
        userID
      }
      caption
      likes {
        items {
          id
          postID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      comments {
        items {
          id
          text
          userID
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      text
      userID
      postID
      post {
        id
        createdAt
        media {
          bucket
          region
          key
          type
          userID
        }
        caption
        likes {
          nextToken
        }
        userID
        comments {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      text
      userID
      postID
      post {
        id
        createdAt
        media {
          bucket
          region
          key
          type
          userID
        }
        caption
        likes {
          nextToken
        }
        userID
        comments {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      text
      userID
      postID
      post {
        id
        createdAt
        media {
          bucket
          region
          key
          type
          userID
        }
        caption
        likes {
          nextToken
        }
        userID
        comments {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
      postID
      post {
        id
        createdAt
        media {
          bucket
          region
          key
          type
          userID
        }
        caption
        likes {
          nextToken
        }
        userID
        comments {
          nextToken
        }
        updatedAt
      }
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      id
      postID
      post {
        id
        createdAt
        media {
          bucket
          region
          key
          type
          userID
        }
        caption
        likes {
          nextToken
        }
        userID
        comments {
          nextToken
        }
        updatedAt
      }
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
      postID
      post {
        id
        createdAt
        media {
          bucket
          region
          key
          type
          userID
        }
        caption
        likes {
          nextToken
        }
        userID
        comments {
          nextToken
        }
        updatedAt
      }
      userID
      createdAt
      updatedAt
    }
  }
`;
