// import { useEffect } from 'react'
import { API } from 'aws-amplify'
import { getUser } from '../graphql/queries'
// import { useParams } from 'react-router-dom'

import useApolloClient from './useApolloClient'
import { gql } from '@apollo/client'


export const fetchPostUser = async (userID, callback) => {

  try {
    const { data: { getUser: { display_name, avatar }}} = await API.graphql({ query: getUser,
      variables: { id: userID }
    })
      callback({display_name, avatar})
    } catch (err) {
      console.log(err)
    }
  }

  export const useFetchPostUser = async (userID, callback) => {
    const client = useApolloClient()
    // const { stageKey } = useParams()
    // if (profileUser !== false){

    //   const {display_name, avatar, last_online} = profileUser
    //   callback({display_name, avatar, last_online})

    // } else {
        try {
          const { data: { getUser: { display_name, avatar, last_online }}} = await client.query({
              query: gql(getUser),
              variables: { id: userID }
            })
            // console.log(display_name, last_online)
            callback({display_name, avatar, last_online})
          } catch (err) {
            console.log(err)
          }
    // }

    }
