import React from 'react'
import { Card } from 'react-bootstrap'
import icon from '../../images/Icon.png'
import { FiMoreVertical } from 'react-icons/fi'

export default function ContentCard(props) {

  return (
  
      <div >
        <Card id="card">
          <div className="option-icon">
            <FiMoreVertical size="1.85em"/>
          </div>
          <Card.Img variant="top" src={icon} />
            
            <div className="card-text"> 
              Date Added: 07/29/19
            </div>

            <div className="card-text">
              Notes: xxx
            </div>

        </Card>
      </div>
   
  )
}