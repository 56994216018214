import React from 'react'
import { Card } from 'react-bootstrap'
import icon from '../../images/Icon.png'
import { FiMoreVertical } from 'react-icons/fi'

export default function ThumbnailCard() {

  return (
    <div>
      <Card id="thumbnail-card">
        {/* <Card.ImgOverlay> */}
          <div className="option-icon">
            <span>07/29/2019</span>
            <FiMoreVertical size="1.25em"/>
          </div>
          <div className="option-icon">
            <Card.Img src={icon} />
          </div>
          
        {/* </Card.ImgOverlay> */}
      </Card>
    </div>
  )
}