import React from 'react'
import { HiLockClosed } from 'react-icons/hi'

export default function Locked(props) {
  return (
    
    <div className="lock-box">
      
      <HiLockClosed />
    
    </div>

  )
}