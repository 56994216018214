import React, { useState, useRef } from 'react'
import moneyCoin from '../../images/tipcoin.png'
import { Image } from 'react-bootstrap'
import QuickTip from './QuickTip'

export default function MoneyCoin(props) {
  
  const [ sendTip, setSendTip ] = useState(false)
  const coinElement = useRef()

  const handleAnimation = (e) => {
    console.log("animating")
    Promise.all(
      e.target.getAnimations({ subtree: true })
        .map(animation => animation.finished)
    ).then(() => {
      console.log("animation complete")
      setSendTip(true)
      coinElement.current.classList.add('paused')
    }).catch(err => console.log(err))
  }

  const handleTip = () => {
    coinElement.current.classList.remove('paused')
    setSendTip(false)
  }


  return (
    <>
      <Image 
        id="money-coin" 
        ref={coinElement}
        roundedCircle 
        src={moneyCoin} 
        alt="tip icon"
        onMouseEnter={(e) => handleAnimation(e)}
        onClick={(e) => handleAnimation(e)}
      />
      <QuickTip show={sendTip} handleComplete={handleTip} userID={props.userID} username={props.username} />
    </>
  )
}