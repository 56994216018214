import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'

export default function SubscribeDetails(props) {
  const [ details, setDetails ] = useState({price: "9.99"})
  const [ isSubscribed, setIsSubscribed ] = useState(true)
  //NOTE: price, frequency
  //NOTE: should also not render if current User is subscribed
  //NOTE: maybe allow creators to write their own subscribe message?
  //NOTE: need subscribe button and maybe discount packages for buying more than a month at a time?

  useEffect(() => {
    if (props.details && props.details !== details) {
      setDetails(props.details)
    }
  }, [props, details, setDetails])

  //NOTE: I think the || needs to change to && when theres actual data to test
  // if (Object.keys(details).length !== 0 && !isSubscribed) {

    return (
      <div className="sub-deets">
        
        <Button 
          id="outline-primary"
          variant="outline-primary"
        > 
          Subscribe for {details.price} a month!!! 
        </Button>
      </div>
    )
  // } else { return null }
}