import React from 'react';

import SectionLabel from './SectionLabel';
import CreatorPreview from './CreatorPreview';

export default function CreatorResults(props) {
	const count = props.results.length
	return (
		<div className="w-100 d-flex flex-column p-4">
			<SectionLabel title={props.title === "filterResults" ? `${count} match${count === 1 ? "" : "es" } for your selection...` : props.title} />
			<div className="d-flex flex-wrap p-1">
				{props.results.map(creator => <CreatorPreview creator={creator} key={creator.id} />)}
			</div>
		</div>
	);
}