import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'

export default function SignUpUsernameForm(props) {

  const [username, setUsername] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    console.log(username)
    props.handleSubmit({ username: username })
  }

  return (
    <div id="register-form" className="d-flex flex-column top-bumper">
      <Form onSubmit={e => handleSubmit(e)}>

        <Form.Group controlId="form-username">
          <Form.Label>Choose a Username</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Example: ChokingHazard" 
            value={username}
            minLength="3"
            maxLength="15"
            required
            onChange={e => setUsername(e.target.value)}
          />
          <Form.Text className="text-muted">
            Username must be unique & cannot be changed.
          </Form.Text>

          { props.errors && (
            <Form.Text className="text-danger">
              Username is taken.
            </Form.Text>
          )}
          
        </Form.Group>

        <Button type="submit" id=" button">
          Submit
        </Button>

      </Form>

    </div>
  )
}