import React, { useState, useEffect } from 'react'
import UserViewLite from './UserViewLite'

export default function CollectionContainer(props) {
  //NOTE: props needs to take in items, nextToken, fetchUser function, type (favorite, following, followers)
  const containerType = {
    FAVORITES: "Favorites",
    FOLLOWING: "Following",
    FOLLOWERS: "Followers"
  }

  const queryField = {
    FAVORITES: "favorite_user_id",
    FOLLOWING: "following_userID",
    FOLLOWERS: "follower_userID"
  }

  const [ loaded, setLoaded ] = useState(false)
  const [ isFetching, setIsFetching ] = useState(false)
  const [ collection, setCollection ] = useState([])
  const [ nextToken, setNextToken ] = useState(null)

  useEffect(() => {
    if (!loaded && !isFetching && props && props.items.items && props.type) {
      setCollection(props.items.items)
      setNextToken(props.items.nextToken)
      setLoaded(true)
    }
  }, [loaded, isFetching, props])

  //NOTE: needs a uid for key for each child component
//   const fetchAndParse = async () => {
//     const collectionType = queryField[props.type]
//       return collection.map(u => {
//         console.log(u[collectionType])
//         let res
//         props.fetchUser(u[collectionType]).then(res => {
//           res = res
//           console.log(res)
//           // return ( <UserViewLite data={res} />)
//         })
//     })
  
// }


  return loaded && (
    <div>
      <div>
        { containerType[props.type] }
      </div>

    </div>
  )
}