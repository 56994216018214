import React, { useState, useEffect } from 'react'
import { AiOutlineFire,  AiFillFire } from 'react-icons/ai'

export default function FireLikeButton(props) {
  
  // const liked = AiFillFire
  // const notLiked = AiOutlineFire
  
  // const [ isClicked, setIsClicked ] = useState(props.userLiked)
  // const [ icon, setIcon ] = useState(notLiked)

  // useEffect(() => {
  //   if (props.userLiked !== isClicked) {
  //     setIsClicked(props.userLiked)
  //   }
  // }, [props, isClicked, setIsClicked])

  // const toggleIcon = () => {
  //   if ( isClicked ) {
  //     setIsClicked(false)
  //     props.setUserLiked(false)
  //     // setIcon(notLiked)
  //   } else {
  //     setIsClicked(true)
  //     props.setUserLiked(true)
  //     // setIcon(liked)
  //   }
  // }

  if ( props.userLiked ) {
    return (
      <AiFillFire />
    )
  } else {
    return (
      <AiOutlineFire />
    ) 
  }
  
}