import React from 'react'
import { Breakpoint } from 'react-socks'
import Icon from '../images/ts-logo.png'

export default function IconImage() {
    return (
        <div className="d-flex flex-row justify-content-evenly flex-nowrap">
            {/* <>  */}
                <Breakpoint l up >
                <div className="d-flex flex-row justify-content-evenly flex-nowrap align-items-center">

                    {/* <div className="ts-brand">
                        Trading
                    </div> */}
                    <img className='icon-image' src={Icon} alt="sub-me"/>     
                    <div className="ts-brand">
                        Trading Session
                    </div>
                </div>
                    
                </Breakpoint>
                <Breakpoint m down >
                    <img className='icon-image' src={Icon} alt="sub-me"/>        
                    
                </Breakpoint>
            {/* </> */}
        </div>
    )
}
