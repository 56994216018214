import React, { useState, useEffect } from 'react'

export default function ProfileBio({userBio}) {
  const [ bio, setBio ] = useState('')

  useEffect(() => {
    if (userBio && userBio !== bio) {
      setBio(userBio)
    }
  }, [userBio, bio, setBio])

  return (
    <div className="user-bio">
      { bio }
    </div>
  )
}