import { Storage } from 'aws-amplify'
import { blobToBase64 } from './dataFormatters'

export const fetchUserAvatar = async (key, callback) => {

  let avatar
  const s3Media = await Storage.get(key, {
    download: true,
    contentType: 'image/png'
  })

  blobToBase64(s3Media.Body, url => {
    avatar = url
    callback(url)
  })
  
  return avatar
}