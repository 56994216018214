import React, { useState, useEffect, useRef } from 'react'
import Carousel from '../components/user-profile/Carousel'
// import PinnedContent from '../components/user-profile/PinnedContent'
import SubscribeDetails from '../components/user-profile/SubscribeDetails'
import FeedContainer from '../components/user-profile/FeedContainer'
// import ProfileStats from '../components/user-profile/ProfileStats'
import Locked from '../components/user-profile/Locked'
import ProfileBio from '../components/user-profile/ProfileBio'
import UsernameDisplay from '../components/user-profile/UsernameDisplay'
// import TipButton from '../components/user-profile/TipButton'
import MessageButton from '../components/user-profile/MessageButton'
import FollowUserButton from '../components/user-profile/FollowUserButton'
import NewPostForm from '../components/user-profile/post/NewPostForm'
// import StartLiveStream from '../components/user-profile/StartLiveStream'
import DisplayNameHeader from '../components/user-profile/DisplayNameHeader'
import useCurrentUser from '../helpers/store/useCurrentUser'
// import { useParams } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import useProfileUser from '../helpers/useProfileUser'
import { fetchPostsByUser } from '../graphql/custom-queries'
import { createFollowingConnection, deleteFollowingConnection, createFavoriteConnection, deleteFavoriteConnection } from '../graphql/mutations'
import { gql } from '@apollo/client'
import useApolloClient from '../helpers/useApolloClient'
import BeatingHeartIcon from '../components/utils/BeatingHeartIcon'
import FollowIcon from '../components/utils/FollowIcon'
import InboxIcon from '../components/utils/InboxIcon'
import useNotificationDispatchers from '../helpers/notificationActions'
import StoreIcon from '../components/icons/StoreIcon'
import InventoryContainer from '../components/user-profile/inventory/InventoryContainer'
import AddPostBubble from '../components/user-profile/post/AddPostBubble'
// import { Auth from 'aws-amplify'

function CreatorPage(props) {

  const currentUser = useCurrentUser()
  const profileUser = useProfileUser()
  const { createNewNotification } = useNotificationDispatchers()

  const [ locked, setLocked ] = useState(false)
  const [ isProfileOwner, setIsProfileOwner ] = useState(false)
  const [ isLoaded, setIsLoaded ] = useState(false)
  const [ posts, setPosts ] = useState(null)
  const [ postToken, setPostToken ] = useState(null)
  const [ fetchPosts, setFetchPosts ] = useState(false)
  const [ nextToken, setNextToken ] = useState(false)
  const [ isFollowing, setIsFollowing ] = useState(false)
  const [ isFavorite, setIsFavorite ] = useState(false)

  //NOTE: currentView should be either 'POST' or 'INVENTORY'
  const [ currentView, setCurrentView ] = useState('POST')

  // currentUser.following.items.includes(f => f.following_userID === profileUser.id)

  const client = useApolloClient()
  const queryRef = useRef()

  //NOTE: should not get profile profileUser if currentUser is profileUser
  //TODO: following and favorited isn't working properly now for whatever reason
  useEffect(() => {
    // console.log(profileUser)
    if ( profileUser !== null && 
        !isLoaded && 
        currentUser.following && 
        currentUser.favorites && 
        currentUser.following.items && 
        currentUser.favorites.items ) {
      // debugger
      console.log(currentUser.following.items.includes(f => f.following_userID == profileUser.id))
      setIsLoaded(true)
      setIsProfileOwner( currentUser.id === profileUser.id ? true : false )
      setIsFollowing(currentUser.following.items.find(f => f.following_userID == profileUser.id))
      setIsFavorite(currentUser.favorites.items.find(f => f.favorite_user_id == profileUser.id))
    }
  }, [profileUser, isLoaded, currentUser])

  //NOTE: needs to get a refresh token/signal from create new post form to refresh the posts when a new post is created
  //NOTE: or a subscription to get new posts automagically if the profile does not belong to current profileUser?

  useEffect(() => {
    // const [example, setExample] = useState(false)
    if ( isLoaded && (fetchPosts || !postToken) ) {
      queryRef.current = client.watchQuery({
        query: gql(fetchPostsByUser),
        variables: { userID: profileUser.id, sortDirection: 'DESC', limit: 5, offset: 0 },
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
      })
    
      queryRef.current.subscribe({
        next: ({data}) => {
          setPosts(data.fetchPostsByUser.items)
          setNextToken(data.fetchPostsByUser.nextToken)
        },
        error: (e) => console.error(e)
      })

      return () => {
        setPostToken(true)
        setFetchPosts(false)
      }

    }
  }, [postToken, profileUser, fetchPosts, setFetchPosts, isLoaded, client])

  const handleFollowClick = async () => {
    console.log(currentUser, profileUser)
    if ( isFollowing ) {
      const res = await client.mutate({
        mutation: gql(deleteFollowingConnection),
        variables: {
          input: {
            id: isFollowing.id
          }
        }
      })
      setIsFollowing(false)
      console.log(res)
      return
    } else {
      const res = await client.mutate({
        mutation: gql(createFollowingConnection),
        variables: {
          input: { follower_userID: currentUser.id, following_userID: profileUser.id }
        }
      })
      setIsFollowing(res.data.createFollowingConnection)
      createNewNotification(res)
      console.log(res)
      return
    }
  }

  const handleFavoriteClick = async () => {
    if ( isFavorite ) {
      const res = await client.mutate({
        mutation: gql(deleteFavoriteConnection),
        variables: {
          input: {
            id: isFavorite.id
          }
        }
      })
      setIsFavorite(false)
      console.log(res)
      return
    } else {
      const res = await client.mutate({
        mutation: gql(createFavoriteConnection),
        variables: {
          input: {
            user_id: currentUser.id,
            favorite_user_id: profileUser.id
          }
        }
      })
      setIsFavorite(res.data.createFavoriteConnection)
      console.log(res)
      return 
    }
  }

  const loadMorePosts = (nextToken) => {
      queryRef.current.fetchMore({
        variables: { limit: 5, offset: posts.length, nextToken: nextToken },
        updateQuery: ( prevResult, newResult) => {
          if (!newResult) { return prevResult }
          return Object.assign({}, prevResult, {
            fetchPostsByUser: {
              items: [
                ...prevResult.fetchPostsByUser.items, 
                ...newResult.fetchMoreResult.fetchPostsByUser.items
              ],
            }
          })
        }
      })
      .then(res => {
        setPosts([...posts, ...res.data.fetchPostsByUser.items])
        setNextToken(res.data.fetchPostsByUser.nextToken)
      })
  }

  const addNewPost = data => {
    setPosts(prevPosts => [data.data.createPosts, ...prevPosts])
  }

  // NOTE: should know if current profileUser is accessing their own profile and allow editing options
  // useEffect(() => {
  //   if ( currentUserID === stageKey) {
  //     setIsProfileOwner(true)
  //     console.log("current profileUser is profile owner")
  //   }
  // }, [currentUserID, stageKey, setIsProfileOwner])

  //TODO: needs to have message button and tip button
  //NOTE: needs to fetch info of creator, posts, subs, etc. Will pass in props to children
  //NOTE: Carousel renders -> ProfileStats, ProfileHeader
  //NOTE: UsernameDisplay, ProfileBio, SubscribeDetails, Locked only renders itself
  //NOTE: PinnedContent renders -> ProfilePost
  //NOTE: FeedContainer renders -> ProfilePost

  return isLoaded ? (
    <div className="position-relative">
      
      {/* { isProfileOwner && ( 
        <AddPostBubble addNewPost={addNewPost} currentUser={profileUser.username}/>
      )}   */}

      <Carousel user={profileUser}/>

        <Container fluid>
          <DisplayNameHeader user={profileUser} />
          <UsernameDisplay username={profileUser.username}/>
          <ProfileBio userBio={profileUser.bio}/>
        </Container>

        <div className=" profile-icons d-flex justify-content-around mx-auto mt-3 m-2 align-items-center">

        { isProfileOwner ? 
            (
              <div>

              "Click here to edit profile"
              
              </div>
            )
          : 
            (
              <>
                <BeatingHeartIcon 
                  isFavorite={isFavorite}
                  handleClick={handleFavoriteClick}
                />

                <FollowIcon size="2.35em" 
                  isFollowing={isFollowing} 
                  handleClick={handleFollowClick}
                />

                <InboxIcon 
                  size="2.35em" 
                  user={profileUser} 
                  currentUser={currentUser} 
                />

                <div 
                  id={ currentView === 'INVENTORY' && 'inventory-tab' } 
                  className="rounded-top p-2"
                >
                  <StoreIcon 
                    size="2.35em"
                    user={profileUser}
                    handleClick={setCurrentView}
                  />
                </div>
              </>
            )
          }
        </div>

      { locked ? 
      
        ( 
          <>
            <SubscribeDetails profileUser={profileUser} currentUser={currentUser}/> 

            <div className="w-75 mx-auto d-flex justify-content-around">
              <InboxIcon 
                size="2.35em" 
                user={profileUser} 
              />
              {/* <FollowUserButton user={profileUser} currentUser={currentUser}/> */}
              <FollowIcon size="2.35em" 
                  isFollowing={isFollowing} 
                  handleClick={handleFollowClick}
                />
            </div>

            <Locked />
          </>
        )
        :
        (
          <> 

          {/* <PinnedContent/> */}
          { currentView === 'POST' ? (
            <FeedContainer 
              posts={posts} 
              profileUser={profileUser} 
              currentUser={currentUser} 
              loadMorePosts={loadMorePosts}
              nextToken={nextToken}
            />
          ) : (
            <InventoryContainer 
              profileUser={profileUser}
              currentUser={currentUser}
              handleBackClick={setCurrentView}
            />
          )}

          </>
        )
      }
        
    </div>
  ) : ( <div> ...Loading </div> )
}

export default CreatorPage

  //  {/* <Container>
  //         <Row>
  //           <NewPostForm 
  //             setFetchPosts={setFetchPosts}
  //             addNewPost={addNewPost}
  //           />

  //           {/* <StartLiveStream /> */}
  //           </Row>
  //         </Container> */}
