import React, { useState } from 'react'
import { Form, Button, Container, Collapse, Row, Image, Modal } from 'react-bootstrap'
import EditIcon from '../../icons/EditIcon'
import ImageIcon from '../../icons/ImageIcon'
import VideoIcon from '../../icons/VideoIcon'
import CalendarIcon from '../../icons/CalendarIcon'
import SchedulePicker from '../../calendar/SchedulePicker'

import useCurrentUser from '../../../helpers/store/useCurrentUser'
import { createPost, updateUser } from '../../../graphql/mutations'
import { v4 as uuid } from 'uuid'
import config from '../../../aws-exports'
import { Storage, API } from 'aws-amplify'
// import { blobToDataURL } from '../../helpers/dataFormatters'

import useApolloClient from '../../../helpers/useApolloClient'
import { gql } from '@apollo/client'

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket
} = config


// import 'tui-image-editor/dist/tui-image-editor.css'
// import ImageEditor from '@toast-ui/react-image-editor'

export default function NewPostForm(props) {

  const [ open, setOpen ] = useState(false)
  const [ addImage, setAddImage ] = useState(false)
  const [ addVideo, setAddVideo ] = useState(false)
  const [ schedulePost, setSchedulePost ] = useState(false)
  const [ file, setFile ] = useState(null)
  const [ fileType, setFileType ] = useState(null)
  const [ caption, setCaption ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ scheduledDateTime, setScheduledDateTime ] = useState(null)
  const { username, id } = useCurrentUser()
  const client = useApolloClient()

  //ERROR: needs to check if there is media attached or not and handle post upload from there

  // const writeToCache = data => {
  //   return client.writeQuery({
  //     query: gql(createPost),
  //     data
  //   })
  // }

  const handleSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)

    const key = `${uuid()}${username}`

    if ( addImage || addVideo ) {
      const fileForUpload = { bucket, key, region, type: fileType, userID: id }
      const buf = addImage ? 
        Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""),'base64')
          :
        Buffer.from(file.replace(/^data:video\/\w+;base64,/, ""),'base64')
      //NOTE: sends media to S3
      const postUpload = await Storage.put(key, buf, {
        ContentEncoding: 'base64',
        ContentType: fileType,
        // level: 'protected'
      })
      console.log(postUpload)
        //NOTE: creates new post object
        // const res = await API.graphql({ query: createPost,
        //   variables: {
        //     input: {userID: id, media: fileForUpload, caption: caption }
        //   }
        // })
        const res = await client.mutate({
          mutation: gql(createPost),
          variables: {
            input: { 
              userID: id, 
              media: fileForUpload, 
              caption: caption 
            }
          }
        })
      console.log(res)
      props.addNewPost(res)
      //NOTE: adds created post to users posts and adds media Item to users content
      // const setPost = await API.graphql({ query: updateUser,
      //   variables: {
      //     input: {id: id, content: fileForUpload}
      //   }
      // })
      // const setPost = await client.mutate({
      //   mutation: gql(updateUser),
      //   variables: {
      //     input: { id: id, content: fileForUpload }
      //   }
      // })
      // console.log(setPost)
    } else {
      // const mediaLessPost = await API.graphql({ query: createPost,
      //   variables: {
      //     input: { userID: id, caption: caption }
      //   }
      // })
      const mediaLessPost = await client.mutate({
        mutation: gql(createPost),
        variables: {
          input: { userID: id, caption: caption }
        }
      })
      console.log(mediaLessPost)
    }

    setIsLoading(false)
    setOpen(false)
    setCaption('')
    setFile(null)
    setFileType(null)
    props.setFetchPosts(true)
  }

  // const handleScheduledPost = () => {
  //   setSchedulePost(true)
  // }

  const handleImageUpload = e => {
    const upload = e.target.files[0]
    setFileType(upload.type)

    var reader = new FileReader();
    reader.readAsDataURL(upload); 
    reader.onloadend = function() {
      
      setFile(reader.result)     
    }
  }

  const handleVideoUpload = e => {
    const upload = e.target.files[0]
    setFileType(upload.type)
    // setFile(URL.createObjectURL(upload))
    var reader = new FileReader();
    reader.readAsDataURL(upload); 
    reader.onloadend = function() {
      
      setFile(reader.result)     
    }
  }

  const handleCancel = e => {
    e.preventDefault()
    setOpen(false)
    setCaption('')
    setFile(null)
    setFileType(null)
    setScheduledDateTime(null)
    props.handleComplete(e)
  }
  //NOTE: needs option to add price for if post is PPV content or not. Maybe with whats this, help icon for more info

  return (
    <Modal
      show={props.show}
      onHide={props.handleComplete}
      style={{opacity: '1'}}
    >

    
    <div className="fullsize">
      {/* <Container>
        <Row>
          Create New Post
        </Row>
      </Container> */}
      {/* <Button
        onClick={() => setOpen(!open)}
        aria-controls="register-form"
        aria-expanded={open}
        size="sm"
        block
        variant="link"
        >
        - Create Post -
      </Button>
      
      <Collapse in={open}> */}
        <div id="register-form" className="fullsize">
          
          <Form onSubmit={e => handleSubmit(e)}>

            <Form.Group>
              <Form.Control 
                type="textarea"
                id="custom-post-input"
                maxLength="1000"
                value={caption}
                placeholder="Write a caption..."
                autoComplete="off"
                // size="sm"
                onChange={e => setCaption(e.target.value)}
              />
              {/* <input type="textarea"/> */}
            </Form.Group>

            { addImage && (
              <Form.Group>
              <Form.Label>Select Image</Form.Label>
                <Form.Control 
                  type="file"
                  accept="image/*"
                  onChange={e => handleImageUpload(e)}
                />
              </Form.Group>
            )}

            { addVideo && (
              <Form.Group>
              <Form.Label>Select Video</Form.Label>
                <Form.Control 
                  type="file"
                  accept="video/*"
                  onChange={e => handleVideoUpload(e)}
                />
              </Form.Group>
            )}

            { schedulePost && (
                <Container >
                  <SchedulePicker 
                    setScheduledDateTime={setScheduledDateTime} 
                    />
                  <Row className="mb-3" id="schedule-buttons">
                  <Button
                      size="sm"
                      variant="secondary"
                      className="rounded-pill"
                      onClick={() => {
                        setSchedulePost(!schedulePost); setScheduledDateTime(null)}
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      variant="success"
                      className="rounded-pill"
                    >
                      Confirm
                    </Button>
                  </Row>
                </Container>
            )}

            { (file && addVideo) && (
              <Container id="upload-preview">
                <center>
                  <div> Click preview to open editing tools <EditIcon /> </div>
                  <video controls id="video-preview">
                    <source src={file} type="video/mp4"/>
                  </video>
                </center>
              </Container>
            )}

            { (file && addImage) && (
              <Container id="upload-preview">
                <center>
                  <div> Click preview to open editing tools <EditIcon /> </div>
                  <Image src={file} rounded thumbnail id="image-preview"/>
                </center>
              </Container>
            )}

            <Row className="icon-options">
              <div onClick={() => {setAddImage(!addImage); setAddVideo(false)}}>
                <ImageIcon/>
              </div>
              <div onClick={() => {setAddVideo(!addVideo); setAddImage(false)}}>
                <VideoIcon />
              </div>
              <div onClick={() => setSchedulePost(!schedulePost)}>
                <CalendarIcon />
               
              </div>
            </Row>


            <Button 
              type="submit" 
              size="sm" 
              block
              disabled={isLoading}
            >
              { isLoading ? 'Saving Post...' : 'Submit' }
            </Button>

            <Button 
              variant="link" 
              size="sm" 
              className="text-muted"
              onClick={e => handleCancel(e)}
            >
              Cancel
            </Button>

          </Form>
        </div>
      {/* </Collapse> */}

    </div>
    </Modal>
  )
}

  /* <Form.Group>
              <Form.Label>Schedule Post</Form.Label>
                <Form.Control
                  type="datetime-local"
                  id="schedule-post"
                  value={scheduledDateTime}
                  onChange={e => setScheduledDateTime(e)}
                />
              </Form.Group> */