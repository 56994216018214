// import { useState } from 'react'
import { Storage } from 'aws-amplify'
import { blobToBase64 } from './dataFormatters'
// import { Auth } from 'aws-amplify'


//NOTE: will need to set access level to protected and configure the Storage in index.js
export default async function fetchMediaFromStorage({key, type}, userID, callback) {
  // console.log("from fetch storage", key, type, callback)

  try {
    // const { identityId } = await Auth.currentCredentials()
    let data = await Storage.get(key, {
      download: true,
      contentType: type,
      // level: 'protected',
      // identityId: identityId
    })
    // console.log(data)
    
    blobToBase64(data.Body, url => {
      
      callback(url)
      return () => null
    })
    return () => null

  } catch (err) { console.log(err) }

}