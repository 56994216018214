import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { GoMail } from 'react-icons/go'

export default function InboxIcon(props) {
  const [ color, setColor ] = useState('#999999')
  const [ size, setSize ] = useState('1.5em')

  let history = useHistory()
  const location = {
    pathname: `/dashboard/messages/view`,
    state: { profileUser: props.user }
  }

  useEffect(() => {
    if (props.color && props.color !== color) {
      setColor(props.color)
    }
    if (props.size && props.size !== size) {
      setSize(props.size)
    }
    if (props.active && props.activeIconColor) {
      setColor(props.activeIconColor)
    }
    if (!props.active && props.color) {
      setColor(props.color)
    }
  }, [props, color, setColor, size, setSize])

  return (
    <span onClick={() => history.push(location)}>
      <GoMail 
        color={color} 
        size={size} 
        />
    </span>
  )
}