import React, { createContext, useReducer } from 'react';

import { DiscoverReducer } from './DiscoverReducer';

export const currentSearch = {
	filter: {
		add : [],
		rem: [],
		filterType: false,
	},
	results: {
		creators: [],
		filteredCreators: [],
	}
}

export const DiscoverContext = createContext(currentSearch);

export const DiscoverProvider = ({ children }) => {
	const [state, dispatch] = useReducer(DiscoverReducer, currentSearch)
	const addFilter = (tag) => dispatch({ type: "ADD_FILTER", payload: tag });
	const remFilter = (tag) => dispatch({ type: "REM_FILTER", payload: tag });
	const chooseType = () => dispatch({ type: "CHOOSE_TYPE" });
	const filterResults = (results) => dispatch({ type: "FILTER_RESULTS", payload: results });
	const reset = () => dispatch({ type: "RESET" });
	return (
		<DiscoverContext.Provider
			value={{
				state,
				dispatch,
				addFilter,
				remFilter,
				chooseType,
				filterResults,
				reset
			}}
		>
			{ children }
		</DiscoverContext.Provider>
	)
}