import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getPost } from '../graphql/queries'

import useApolloClient from '../helpers/useApolloClient'
import useCurrentUser from '../helpers/store/useCurrentUser'
import { gql } from '@apollo/client'
import ProfilePost from '../components/user-profile/post/Post'


//NOTE: Back button to navigate back?
//NOTE: container for post needs spacing 

export default function PostPage() {

  let { creatorID, postID } = useParams()
  const client = useApolloClient()
  const user = useCurrentUser()

  const  [ postData, setPostData ] = useState({})
  const [ loaded, setLoaded ] = useState(false)
  
  const validateUser = () => creatorID === user.username
  console.log(validateUser())

  useEffect(() => {
    //NOTE: fetches post and user
    if (!loaded && postID && user && validateUser()) {
      console.log("line 27")
      const fetchPost = async () => {
        const res = await client.query({
          query: gql(getPost),
          variables: {
            id: postID
          }
        })
        
        console.log(res)
        setPostData(res.data.getPost)
        setLoaded(true)
        return res.data.getPost
      }
      fetchPost()
      return
    }
  }, [loaded, postData, postID, user])

  //TODO: needs to style div to bring under nabber on desktop. 
  //TODO: Should we display the moneycoin if is currentUser?

  return loaded ? 
    (
      <div className="mt-5 pt-5">
        <ProfilePost post={postData} currentUser={user} postUser={user} />
      </div>
    )

    :

    (
      <div>
        Loading Post
      </div>
    )
}