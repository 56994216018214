import React, { useContext } from 'react';

import Tag from './Tag';
import ResetButton from './ResetButton';
import FilterSwitch from './FilterSwitch';
import { DiscoverContext } from '../../helpers/store/DiscoverCreatorContext';

export default function FilterCreators({tags, chooseType}) {
  const state = useContext(DiscoverContext);
  return (
    <div className="d-flex flex-column p-2 ml-1">
      <FilterSwitch />
      <div className="d-flex flex-column ml-2">
        {
          tags.map( tag => {
            return (
              <Tag
                tag={tag}
                key={tag}
              />
            )
          })
        }
      </div>
      <ResetButton />
    </div>
  )
}