import React from 'react'

export default function NewPostPage(props) {

  return (
    <div>
      new post page
    </div>
  )
  
}