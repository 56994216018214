import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'

//LINK: https://docs.amplify.aws/lib/auth/manageusers/q/platform/js

export default function ResetPasswordForm(props) {

  const [ verificationCode, setVerificationCode ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ passwordConfirmation, setPasswordConfirmation ] = useState('')
  const [ passwordError, setPasswordError ] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setPasswordError( password !== passwordConfirmation )
    //NOTE: sends email to auth to send user verification code
    //NOTE: sends user to ResetPasswordForm
  }

  return (
    <div id="register-form" className="d-flex flex-column top-bumper">
      <Form onSubmit={e => handleSubmit(e)}>

        <Form.Group controlId="form-email-input">
          <Form.Label>
            Reset Password
          </Form.Label>
          <Form.Control 
            type="text"
            placeholder="######"
            value={verificationCode}
            minLength="6"
            maxLength="6"
            required
            onChange={e => setVerificationCode(e.target.value)}
          />
          <Form.Text className="text-muted">
            Please enter the email address associated with your account.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="form-password">
          <Form.Label>Choose a new password</Form.Label>
          <Form.Control 
            type="password" 
            value={password} 
            required
            onChange={e => setPassword(e.target.value)}
          />
          <Form.Text className="text-muted">
            For security purposes, passwords must contain uppercase & lowercase characters, and a number. 
          </Form.Text>
          <Form.Text className="text-muted">
            Passwords must be a minimum of 8 characters long.
          </Form.Text>
        </Form.Group>

          <Form.Group controlId="form-password-confirm">
            <Form.Label>Confirm new password</Form.Label>
            <Form.Control 
              type="password" 
              value={passwordConfirmation} 
              required
              onChange={e => setPasswordConfirmation(e.target.value)}
            />
            { passwordError && (
              <Form.Text className="text-danger">
                Passwords do not match!
              </Form.Text>
            )}
          </Form.Group>

        <Button type="submit" id="button" onClick={e => handleSubmit(e)}>
          Submit
        </Button>

      </Form>
    </div>
  )
}