import React, { useEffect, useState } from 'react'
import useApolloClient from '../helpers/useApolloClient'
import useCurrentUser from '../helpers/store/useCurrentUser'
import { gql } from '@apollo/client'
import { searchPosts } from '../graphql/queries'

export default function ShowcasePage() {
  const [ posts, setPosts ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isLoaded, setIsLoaded ] = useState(false)
  const client = useApolloClient()
  const { following } = useCurrentUser()

  //NOTE: needs to map over followingconnections from currentUser for IDs. 
  // Format ids to pass to query to return posts

  const formatFollowing = () => {
    return following.items.map(f => {
      return { userID: { match: f.id }}
    })
  }

  // { 
  //   userID: { match: "30e0beca-1de1-405e-bc90-babe63e21075" }
  // },{ 
  //   userID: { match: "30e0beca-1de1-405e-bc90-babe63e21075" }
  // }

  useEffect(() => {
    if ( !isLoaded && following && following.items ) {
      
      const followersDirectory = formatFollowing()
      setIsLoading(true)
      // console.log("fetching posts")
      const fetchPosts = async () => {
        try {
          const data = await client.query({
            query: gql(searchPosts),
            variables: {
              filter: {
                or: followersDirectory
              },
              sort: { direction: 'desc', field: "createdAt" },
              limit: 20,
              offset: 0
            }
          })
          console.log(data)
          return data
        } catch (err) { console.log(err) }
      }
      const res = fetchPosts()
      setPosts(res)
      setIsLoaded(true)
      setIsLoading(false)
    }
  }, [posts, isLoaded, isLoading, following])

  return (
    <div className="d-flex justify-content-center m-4">
      Nothing to see here- join a Session or follow a Guru to get started!
    </div>
  )
}