import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'

export default function QuickTipConfirmationCall(props) {
  //NOTE: should get user username and user id from QuickTip

  return (
    
    <div className="p-3">

      <div className="p-2">
        <div className="d-flex justify-content-center">
          You are about to send ${ props.tipAmount } to { props.username }. 
        </div>
        <div className="d-flex justify-content-center">
          Please Confirm or Cancel below.
        </div>
      </div>

      <div className="d-flex justify-content-center mt-4">

        <div className="m-2">
          <Button variant="link" className="text-muted">
            Cancel
          </Button>
        </div>

        <div className="m-2">
          <Button variant="success">
            Confirm & Send
          </Button>
        </div>

      </div>

    </div>

  )
}