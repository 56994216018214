import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import UserIcon from '../icons/UserIcon'
import UserAvatar from '../utils/UserAvatar'
import useConversationActions from '../../helpers/conversationActions'
import { formatDateTime, timeSince } from '../../helpers/dataFormatters'
// import DisplayName from '../user-profile/DisplayName'

export default function ConversationPreview(props) {
  let history = useHistory()
  const location = {
    pathname: `/dashboard/messages/view`,
    state: { currentConversation: props.convo }
  }
  const [ recipientData, setRecipientData ] = useState({})
  const [ loaded, setLoaded ] = useState(false)
  const { fetchRecipientData } = useConversationActions()

  useEffect(() => {
    if ( props.convo && props.convo.conversation.members && props.currentUserID && !loaded) {
      // console.log("fetching recipient data")
      const recID = props.convo.conversation.members.find(m => m.userID !== props.currentUserID )
      console.log(recID)
      if (recID) {
        const res = fetchRecipientData(recID.userID)
        res.then(data => {
          setRecipientData(data.data.getUser)
          setLoaded(true)
        })
      }
    }

  }, [props, loaded, recipientData])

  return (loaded && recipientData) ? (
    <div className="convo-preview" onClick={() => history.push(location)}>
      <div>
        {/* <UserIcon size="3em"/> */}
        <UserAvatar 
          avatarKey={recipientData.avatar.key}
          lastOnline={recipientData.last_online}
          post
        />
      </div>
        
        <div className="convo-preview-bulk">
          <p className="convo-preview-user">
            { recipientData.username }
          </p>
          <p className="convo-preview-snippet">
            Preview
          </p>
        </div>

        <div className="convo-preview-time">
          <p>{ timeSince(props.convo.updatedAt) }</p>
        </div>
      </div>
  ) : (
    <div className="convo-preview">
      Loading
    </div>
  )
}