import React, { useState, useRef } from 'react'
import { Collapse, Container, Button } from 'react-bootstrap'
import Comment from './Comment'
import { v4 as uuid } from 'uuid'

export default function CommentContainer(props) {
  const [ open, setOpen ] = useState(false)

  // const wrapper = useRef()

  //NOTE: think about comment pagination. How many should be displayed at once?
  
  return (
    <>
      <div className="mh-100">
        <Button 
          block 
          variant="link" 
          size="sm"
          id="muted-text"  
          onClick={() => setOpen(!open)}
          aria-controls="collapse-comments"
          aria-expanded={open}
        >
          { open ? 'Hide Comments ▲' : 'View Comments ▼' }
        </Button>

        <Collapse in={open} >
          <Container className="p-1 mb-1" fluid>
            
            { props.comments.map(comment => (

              <Comment comment={comment} key={uuid()} open={open}/>
            
            ))}

          </Container>
        </Collapse>
      </div>
    </>
  )
}