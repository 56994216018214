export const DiscoverReducer = ({ filter, results }, { type, payload = null }) => {
	const { add, rem, filterType } = filter;
	switch(type) {
		case "ADD_FILTER":
			return {
				filter: {
					add: add.includes(payload) ? add.filter(a => a!==payload) : [...add, payload],
					rem,
					filterType
				},
				results
			}
		case "REM_FILTER":
			return {
				filter: {
					add,
					rem: rem.includes(payload) ? rem.filter(r => r!==payload) : [...rem, payload],
					filterType
				},
				results
			}
		case "CHOOSE_TYPE":
			return {
				filter: {
					add,
					rem,
					filterType: !filterType
				},
				results
			}
		case "FILTER_RESULTS":
			return {
				filter,
				results: {
					creators: payload
				}
			}
		case "RESET":
			return {
				filter: {
					add: [],
					rem: [],
					filterType: false
				},
				results
			}
		default: return { filter, results };
	}
}