import React, {useState, useEffect, useContext} from 'react';

import { DiscoverContext } from '../../helpers/store/DiscoverCreatorContext';

export default function FilterSwitch() {
	const { state, chooseType } = useContext(DiscoverContext)
	const { filterType } = state.filter;
	return (
		<div className="filter-switch p-1">
			<label htmlFor="switch-checkbox" className="switch">
				<input
					type="checkbox"
					id="switch-checkbox"
					className="switch-checkbox"
					checked={ filterType }
					onChange={ () => chooseType() }
				/>
				<span className="toggle-switch round hastt">
					<span className="tooltiptext">{ filterType ? "All filters" : "Any filters" }</span>
				</span>
			</label>
		</div>
	)
}