import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import MobileMenu from '../dashboard/MobileMenu'
import { Navbar } from "react-bootstrap"
import { GoMail } from 'react-icons/go'
import { GoHome } from 'react-icons/go'
import { FaCommentDollar } from 'react-icons/fa'
import { RiStarSmileLine } from 'react-icons/ri'
import { RiMenuFoldLine } from 'react-icons/ri'
import { RiNotification2Line } from 'react-icons/ri'

// import OverviewIcon from './icons/OverviewIcon'
import NewNotifications from '../icons/NewNotifications'
import useCurrentUser from '../../helpers/store/useCurrentUser'
import { AiOutlineCalendar } from "react-icons/ai";


export default function UserFooterNabber(props) {
  let history = useHistory()
  const { username } = useCurrentUser()

  const [ toggleDrawer, setToggleDrawer ] = useState(false)
  const [ activeIcon, setActiveIcon ] = useState(props.currentKey)
  // const [currentUser, setCurrentUser] = useState('@BrickCodeBanger')

  //NOTE: so we can show active tab if active tab appears on footer tabs
  const keyToStage = {
    "showcase": '',
    "overview": '/dashboard',
    "my-page": `/@${username}`,
    "messages": '/messages',
    "content": '/content',
    "payments": '/payments',
    "settings": '/settings',
    "help": '/help',
    "notifications": '/notifications',
    "calendar": '/calendar'
  }
  
  const setKeyAndIcon = (key) => {
    props.setKey(key)
    setActiveIcon(key)
    history.push(`/dashboard${keyToStage[key]}`)
  }

  useEffect(() => {
    if (props.currentKey !== activeIcon) {
      setActiveIcon(props.currentKey)
    }
  }, [props, activeIcon, setActiveIcon])

  return (
    
      <>
      <Navbar 
        fixed="bottom" 
        id="footer-tabs" 
      >
      
          <div onClick={() => setKeyAndIcon("showcase")}>
            <FaCommentDollar color={activeIcon === 'showcase' ? '#007F34' : '#999999'}/>  
          </div>

          <div onClick={() => setKeyAndIcon("calendar")}>
            <AiOutlineCalendar color={activeIcon === 'calendar' ? '#007F34' : '#999999'}/> 
          </div>

          <div onClick={() => setKeyAndIcon("messages")}>
            <GoMail color={activeIcon === 'messages' ? '#007F34' : '#999999'}/>
          </div>
              
          <div onClick={() => toggleDrawer ? setToggleDrawer(false) : setToggleDrawer(true)} >
            <RiMenuFoldLine color={toggleDrawer ? '#00FF00' : '#999999'}/>
          </div>

      </Navbar>
        <MobileMenu
          currentKey={props.currentKey} 
          setKey={props.setKey} 
          toggleDrawer={toggleDrawer} 
          setToggleDrawer={setToggleDrawer}
        />   
      </>
  
  );
}
