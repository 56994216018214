import React, { useState, useEffect, useRef, useMemo } from 'react'
import FireLikeIcon from '../../icons/FireLikeIcon'
import CommentIcon from '../../icons/CommentIcon'
// import TipIcon from '../../icons/TipIcon'
import CommentForm from './CommentForm'
import ImageModal from '../ImageModal'
import { Image, Container, Row, Col } from 'react-bootstrap'
// import UiAvatarIcon from '../../archived/UiAvatarIcon'
import fetchMediaFromStorage from '../../../helpers/fetchMediaFromStorage'
// import { fetchPostUser, useFetchPostUser } from '../../../helpers/fetchPostUser'
// import moneyCoin from '../../../images/tipcoin.png'
import MoneyCoin from '../../utils/MoneyCoin'
import { useHistory } from 'react-router-dom'
import CommentContainer from './CommentContainer'
import { createLike, deleteLike } from '../../../graphql/mutations'
import { getPost } from '../../../graphql/queries'
import useApolloClient from '../../../helpers/useApolloClient'
import { gql } from '@apollo/client'
import UserAvatar from '../../utils/UserAvatar'

//TODO: Add subscription to posts to update on createComment, createLike, deleteLike, deleteComment

//NOTE: maybe truncate post text with optional read more? With max character limit?
//NOTE: skeleton loader would look amazing

function ProfilePost(props) {

  let history = useHistory()
  const postRef = useRef(null)
  const client = useApolloClient()

  const [ post, setPost ] = useState({})
  const [ likeCount, setLikeCount ] = useState([])
  const [ userLiked, setUserLiked ] = useState(false)
  const [ postMedia, setPostMedia ] = useState(null)
  const [ username, setUsername ] = useState(props.postUser.username)
  const [ postLoaded, setPostLoaded ] = useState(false)
  const [ mediaLoaded, setMediaLoaded ] = useState(false)
  const [ avatarKey, setAvatarKey ] = useState(props.postUser.avatar.key)
  const [ userID, setUserID ] = useState(props.post.userID)
  const currentUser = props.currentUser
  // const [ visible, setVisible ] = useState(false)

  const [ show, setShow ] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    if (!postLoaded) {
      // debugger
      if (props.post && props.post !== post) {
        // const { postUser } = initLoadPost
        setPost(props.post)
        setPostLoaded(true)

        // if (props.post.media === null) {
        //   setMediaLoaded(true)
        // }
        setUserLiked(props.post.likes.items.find(like => like.userID === currentUser.id) ? true : false)
        setLikeCount(props.post.likes.items.length || '0')
      }
      
      return () => null
    }

  }, [props, post, currentUser, postLoaded])

  useEffect(() => {
    if ( !mediaLoaded && props.post && props.post.media ) {
      // debugger
      // console.log("fetching media", props.post.caption, props.post.media)
      fetchMediaFromStorage(props.post.media, props.post.userID, url => {
        // console.log(url)
        setPostMedia({url: url, type: props.post.media.type})
      })
      .then(() => setMediaLoaded(true))
      .catch(err => console.log(err))

    }
    return () => setMediaLoaded(true)
  }, [mediaLoaded, props, postMedia])

  const formatLikeCount = () => {
      return likeCount === 1 ? `${likeCount} like` : `${likeCount} likes`
  }

  const formatCommentCount = () => {
    let commentCount = post.comments.items.length
    return commentCount === 1 ? `${commentCount} comment` : `${commentCount} comments`
  }

  const userLikeObj = () => {
    return props.post.likes.items.find(like => like.userID === currentUser.id)
  }

  //NOTE: needs to add user to likes and if already liked this post, needs to unlike and remove from likes
  const handleLike = async () => {
    try {
      if (!userLiked) {
        const newLike = { postID: post.id, userID: currentUser.id }
        await client.mutate({
          mutation: gql(createLike),
          variables: {
            input: newLike
          }
        })
        setUserLiked(true)
        refreshPost()
      } else {
        const likeID = userLikeObj()
        const removeLike = await client.mutate({
          mutation: gql(deleteLike),
          variables: {
            input: {
              id: likeID.id
            }
          }
        })

        console.log(removeLike)
        setUserLiked(false)
        refreshPost()
      }
      // refreshPost()
    } catch (err) { console.log(err) }
  }

  const refreshPost = async () => {
    const freshPost = await client.query({
      query: gql(getPost),
      fetchPolicy: 'network-only',
      variables: {
        id: post.id
      }
    })
    console.log("refreshed post", freshPost)
    setPost(freshPost.data.getPost)
    setLikeCount(freshPost.data.getPost.likes.items.length)
  }

  const formatDateTime = datetime => {
    let awsDatetime = new Date(datetime)
    return awsDatetime.toLocaleString()
  }

  return (
    <>
          {/* <Fade bottom mountOnEnter="true" duration="2000" fraction="0.5"> */}
    <Container 
      fluid 
      className="post rounded"
      id="post-container" 
      ref={postRef}
      // onScroll={() => setScrollStyle()}
    >
      { postLoaded ? (
        <>
          <Container className="post-header" >

            <Row>
              <div className="post-link" onClick={() => history.push(`/@${username}`)}>
                {/* <UiAvatarIcon size="3.65em" imgKey={avatarKey} post="true"/> */}
                {/* <UserIcon size="3.65em" avatarCredentials={avatarKey} /> */}
                <UserAvatar 
                  avatarKey={avatarKey} 
                  lastOnline={props.postUser.last_online} 
                  post 
                />
              </div>

              <Col>
                <div className="post-user">
                  { '@' + username }
                </div>
                <p className="post-timestamp post-margin">
                  { formatDateTime(post.createdAt) }
                </p>
              </Col>

              <div className="p-2">
                <MoneyCoin username={username} userID={userID}/>
              </div>

            </Row>
          </Container>
        
            <div className="post-text post-margin">
              { post.caption }
            </div>
            {/* <h1 className="profile-stats">TEST</h1> */}
              { mediaLoaded && (
                <div className="post-img-container">
                      
                    { (postMedia && postMedia.type.split('/')[0] === 'image') && (

                      <Image id="post-img" fluid src={postMedia.url} alt="banana" onClick={() => handleShow()}/>
                    
                    ) }

                    { (postMedia && postMedia.type.split('/')[0] === 'video') && (

                      <video controls controlsList="nodownload" id="post-img" >
                        <source src={postMedia.url} type={postMedia.type} />
                      </video>

                    ) }
                </div>
              )}
         
            <Container 
              className="post-icon-box p-2"
            >
       
              <Row>
                
                <div onClick={() => handleLike()}>
                  <FireLikeIcon 
                    userLiked={userLiked}
                  />
                </div>

                <div className="mt-1">
                  <label htmlFor="post-comment-input" >
                    <CommentIcon />
                  </label>
                </div>

              </Row>
          
            </Container>

                <Container className="p-2 muted-text">
                    
                    <Row className="justify-content-start">
                      <div className="shrink-to-fit mx-1">
                        <p > { formatLikeCount() } </p>
                      </div>
                      <div className="shrink-to-fit mx-1">
                        <p> { formatCommentCount() } </p>
                      </div>
                    </Row>
                
                </Container>
            

            <CommentForm currentUserID={currentUser} postID={post.id} handlePostRefresh={refreshPost} />
            
            { post.comments.items.length > 0 && ( <CommentContainer comments={post.comments.items} /> ) }
          </>
        ) 
      : 
        'Loading' 
      }

    </Container>
            {/* </Fade> */}

      <ImageModal show={show} handleClose={handleClose} content={postMedia && postMedia.url} />
      </>
  )
  
}

export default React.memo(ProfilePost)

//NOTE: memoization to prevent unnecessary rerendering when parent components are rerendering