import React, { useState, useEffect } from 'react'

import CreatorPage from '../../pages/CreatorPage'
import OverviewPage from '../../pages/OverviewPage'
import InboxPage from '../../pages/InboxPage'
import ContentPage from '../../pages/ContentPage'
import PaymentsPage from '../../pages/PaymentsPage'
import SettingsPage from '../../pages/SettingsPage'
import HelpPage from '../../pages/HelpPage'
import NotificationsContainer from '../notifications/NotificationsContainer'
import ShowcasePage from '../../pages/ShowcasePage'
import FollowersPage from '../../pages/FollowersPage'
import CalendarMain from '../calendar/CalendarMain'
// import NewPostPage from '../../pages/NewPostPage'
import DashOverview from './DashOverview'


export default function DashStage(props) {
  const [stage, setStage] = useState("overview")

  //NOTE: wtf is props.state you cunt
  useEffect(() => {
    if ( props.stage !== stage ) {
      setStage(props.stage)
    }
  }, [setStage, props, stage]);

  const stageEvent = () => {
    switch (stage) {
      case "showcase":
        return ( <ShowcasePage /> )
      case "overview":
        return ( <DashOverview /> )
      case "my-page":
        return ( <CreatorPage/> )
      case "messages":
        return ( <InboxPage/> )
      case "content":
        return ( <ContentPage/> )
      case "payments":
        return ( <PaymentsPage/> )
      case "settings":
        return ( <SettingsPage/> )
      case "help":
        return ( <HelpPage/> )
      case "notifications":
        return ( <NotificationsContainer/> )
      case "subscriptions":
        return null
      case "followers":
        return ( <FollowersPage/> )
      case "discover":
        return null
      case "calendar":
        return ( <CalendarMain/> )
      case "store":
        return null
      default:
        break;
    }
  }

  return (
    <div className="dash-stage mr-0">

        { stageEvent() }
    
    </div>
  )

}