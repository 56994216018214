import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'

export default function LogInForm(props) {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [ rememberDevice, setRememberDevice ] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    const formData = {email: email, password: password}
    props.rememberDevice(rememberDevice)
    props.handleLogin(formData)
  }

  return (
    <>
    <div id="register-form" className="d-flex flex-column top-bumper">
      <div className="h3">
        <strong>
          Log In
        </strong>
      </div>
      <Form onSubmit={e => handleSubmit(e)} id="register-form">
        <Form.Group controlId="form-email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="form-password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <Form.Text id="form-link" onClick={() => props.forgotPassword(true)}>
            Forgot Password?
          </Form.Text>
        </Form.Group>


        { props.errors && (
          <div className="text-danger error p-2 mb-2 fs-5">
            { props.errors }
          </div>
        )}

        <Form.Group>
          <Form.Check 
            id="ignore-styles"
            label="Remember this device"
            checked={rememberDevice}
            onClick={() => setRememberDevice(!rememberDevice)}
          />
        </Form.Group>

        <Button 
          variant="secondary" 
          type="submit" 
          id="button"
        >
          Log In
        </Button>
      

      </Form>
    </div>
    </>
  )
}