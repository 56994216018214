
import React, { useState, useEffect } from 'react'
import { BiStoreAlt } from 'react-icons/bi'

export default function StoreIcon(props) {
  
  const [ color, setColor ] = useState('#999999')
  const [ size, setSize ] = useState('1.5em')

  useEffect(() => {
    if (props.color && props.color !== color) {
      setColor(props.color)
    }
    if (props.size && props.size !== size) {
      setSize(props.size)
    }
  }, [props, color, setColor, size, setSize])

  return (
    <BiStoreAlt
      color={color} 
      size={size} 
      onClick={() => props.handleClick('INVENTORY')}
      />
  )
}