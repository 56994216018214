import React, { useRef } from 'react'
import useApolloClient from './useApolloClient'
import useCurrentUser from './store/useCurrentUser'
import { createNotification, deleteNotification, updateNotification } from '../graphql/mutations'
import { onNotificationByUserId } from '../graphql/subscriptions'
import { gql } from '@apollo/client'

export default function useNotificationActions() {

  const client = useApolloClient()
  const currentUser = useCurrentUser()
  // const queryRef = useRef()
  let subscription

  //NOTE: still needs fixing
  const subscribeToNotifications = () => {
    return null
      // subscription = client.subscribe({
      //   query: gql(onNotificationByUserId),
      //   variables: { user_id: "50c3a1ae-f4be-4fa5-940a-7d2a19aa2bdd" },
      //   // next: res => console.log(res),
      //   // error: err => console.log(err)
      // }).subscribe({
      //   next: (res) => console.log(res),
      //   error: err => console.log(err)
      // })
    
  }

  // useEffect(() => {
  //   if (currentUser && currentUser.isLoggedIn) {
  //     subscribeToNotifications()
  //   }
  // }, [currentUser])

  //NOTE: Needs to receive the api response as argument for createNewNotification dispatcher
  const notificationFormatter = (responseData) => {
    //NOTE: takes in response data from mutation to generate notification type and message string
    // const user = "user"
    const actionType = Object.keys(responseData.data)[0]
    const data = responseData.data[actionType]
    // debugger
 
    switch(actionType) {
      case "createFollowingConnection":
        return {
          notification_type: "FOLLOWER",
          user_id: data.following_userID,
          message: `${currentUser.displayName} is now following you.`,
          seen: false,
          // metadata: {
          //   trigger_user: currentUser,
          //   hyperlink: `/@${currentUser.username}`
          // }
        }

        //TODO: need to create page and route for individual post.

      // case "createLike":
      //   return {
      //     notification_type: "LIKE",
      //     user_id: user.id,
      //     message: `${currentUser.displayName} liked your post.`,
      //     seen: false,
      //     metadata: {
      //       trigger_user: currentUser,
      //       hyperlink: `/@${user.username}/${post.id}`
      //     }
      //   }
      // case "createComment":
      //   return {
      //     notification_type: "COMMENT",
      //     user_id: user.id,
      //     message: `${currentUser.displayName} commented on your post.`,
      //     seen: false,
      //     metadata: {
      //       trigger_user: currentUser,
      //       hyperlink: `/@${user.username}/${post.id}`
      //     }
      //   }
      // case "createMessage":
      //   return {
      //     notification_type: "MESSAGE",
      //     user_id: user.id,
      //     message: `${currentUser.displayName} sent you a message.`,
      //     seen: false,
      //     metadata: {
      //       trigger_user: currentUser,
      //       hyperlink: `/dashboard/messages/${conversation.id}`
      //     }
      //   }
      default:
        return 
    }
  }

  //NOTE: takes in user_id, notification_type, message, seen: false, 
  const createNewNotification = async (apiResponse) => {
    const data = notificationFormatter(apiResponse)
    try {
      let res = await client.mutate({
        mutation: gql(createNotification),
        variables: {
          input: data
        }
      })
      console.log(res.data)
      return res.data
    } catch (err) {
      console.log(err)
      return err
    }
  }

  const makeNotificationSeen = () => {

  }

  const deleteNotification = () => {

  }

  return { subscribeToNotifications, createNewNotification, makeNotificationSeen, deleteNotification }

}

export const NotificationWrapper = ({userID}) => {

  const client = useApolloClient()
  // const subscription = useRef()

  // if (userID !== undefined | null) {
  //   console.log(userID)
  //   subscription.current = client.subscribe({
  //     query: gql(onNotificationByUserId),
  //     variables: { user_id: userID },
  //     // next: res => console.log(res),
  //     // error: err => console.log(err)
  //   })
  //   subscription.current.subscribe({
  //     next: (res) => console.log(res),
  //     error: err => console.log(err)
  //   })
  //   console.log(subscription)
  // }

  let subscription;

  (async () => {
    subscription = client.subscribe({ 
      query: gql(onNotificationByUserId), 
      variables: { user_id: userID} 
    }).subscribe({
      next: data => {
        console.log(data);
      },
      error: error => {
        console.warn(error);
      }
    })
  })()

  // Unsubscribe after 10 secs
  setTimeout(() => {
    subscription.unsubscribe();
  }, 10000);

  return null
}