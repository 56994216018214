import React, { useState, useEffect, useMemo } from 'react'
import { Storage } from 'aws-amplify'
import { blobToBase64 } from '../../helpers/dataFormatters'
import ActiveNow from '../icons/ActiveNow'

//NOTE: takes in props of avatarKey with user's avatarKey as well as post if for post to adjust sizing
function UserAvatar(props) {
  const [avatar, setAvatar] = useState(null)
  // const [size, setSize] = useState('')
  const [ userIsActive, setUserIsActive ] = useState(false)
  
  // console.log(props)
  useEffect(() => {
    if ( props && props.avatarKey && !avatar ) {
    
        Storage.get(props.avatarKey, {
          download: true,
          contentType: 'image/png',
          level: 'public'
        })
        .then(res => {
          // console.log(res)
          blobToBase64(res.Body, url => {
            setAvatar(url)
          })
        })
        .catch(err => console.log(err))
      
    }
    return () => null

  }, [props, avatar])

  useEffect(() => {
    if (props && props.lastOnline) {
      const fiveMins = 1800000
      let datetime = new Date(props.lastOnline).getTime()
      setUserIsActive( datetime - fiveMins < Date.now() )
    }
  }, [props])

  
  // return avatar
  return (
    <div id="trans" className="parent-container rounded-circle">
      { userIsActive && ( <ActiveNow /> ) }
      <img 
        src={avatar} 
        alt="avatar" 
        id={props.post ? 'post-avatar' : 'user-avatar'}  
        // className={ userIsActive && 'online-now'}  
      />
    </div>
  )

}

export default UserAvatar