import React, { useState, useEffect } from 'react'
import { Button, Form, Row } from 'react-bootstrap'
import AddMedia from './AddMedia'

export default function NewMessageForm(props) {

  const [ input, setInput ] = useState('')
  const [ lastKeyPressTime, setLastKeyPressTime ] = useState(null)
  let lastSentTypeCount = 0
  const [ useTextForm, setUseTextForm ] = useState(true)
  const [ file, setFile ] = useState({})

  const handleSubmit = e => {
    e.preventDefault()
    console.log(input)
    if ( input.length > 0 ) {
      props.handleSubmit(input)
      setInput('')
    } else {
      console.log("cannot send empty message")
    }
  }

  const submitListener = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      handleSubmit(e)
    }
  }

  return (
    <Form 
      id="message-form"
      className="d-flex "
      // onSubmit={e => handleSubmit(e)}
      onKeyDown={e => submitListener(e)}
      onKeyPress={() => setLastKeyPressTime( new Date().toISOString() )}
    >
      <AddMedia/>
      <textarea 
        id="message-input"
        rows="3"
        autofocus
        required
        spellCheck="false"
        type="textarea" 
        className="flex-fill"
        autoComplete="off"
        placeholder="send message..."
        value={input}
        onChange={ e => setInput(e.target.value) }
      />
  
      <div 
        id="message-btn" 
        className={input.length > 0 ? "flex-shrink-i green-go" : "flex-shrink-1"}
        onClick={e => handleSubmit(e)}
        >
        ➜
      </div>
  
    </Form>
  )

}