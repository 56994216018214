import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { createComment } from '../../../graphql/mutations'
import { API } from 'aws-amplify'


import useApolloClient from '../../../helpers/useApolloClient'
import { gql } from '@apollo/client'


export default function CommentForm({ currentUserID, postID, handlePostRefresh }) {
  //NOTE: Needs to take in currentUserID and postID

  const [ comment, setComment ] = useState('')
  const client = useApolloClient()

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      // const newComment = await API.graphql({ query: createComment,
      //   variables: {
      //     input: { userID: currentUserID.id, postID: postID, text: comment }
      //   }
      // })
      const newComment = await client.mutate({
        mutation: gql(createComment),
        variables: {
          input: { userID: currentUserID.id, postID: postID, text: comment }
        }
      })
      console.log(newComment)
      setComment('')
      document.activeElement.blur()
      handlePostRefresh()

    } catch (err) { console.log(err) }
  }

  return (
    <Form id="post-comment-form" onSubmit={e => handleSubmit(e)}>
      <Form.Group>
        <Form.Control 
          type="text"
          size="sm"
          id="post-comment-input"
          placeholder="Write a comment..."
          value={comment}
          minLength="1"
          autoComplete="off"
          onChange={e => setComment(e.target.value)}
        />          
      </Form.Group>
    </Form>
)
}