import React, { useState, useEffect } from 'react'
import { Carousel as Slideshow, Container } from 'react-bootstrap'
import ProfileStats from './ProfileStats'

//NOTE: Needs to use banner options from user object and set default or chosen banner if not null

export default function Carousel(props) {
  const [ images, setImages ] = useState((props.user.banner && props.user.banner.default_theme) || "banner-navyred")
  // props.user.banner.default_theme || 
  // const [ scrollable, setScrollable ] = useState(false)
  

  // useEffect(() => {
  //     if (props.images && props.images !== images) {
  //       setImages(props.images)
  //       // props.images.length > 1 ? setScrollable(true) : setScrollable(false)
  //     }
  // }, [props, images, setImages])

  return (
    <div id="slideshow">
    <ProfileStats/>
   
    <Slideshow 
      // controls={false}
      // ref={carousel}
      indicators={false}
    >

      <Slideshow.Item>
      {/* <Container className="default-banner-bkgrd"> */}
      
      <div id={images} className="default-banner"/>

    {/* </Container> */}
      </Slideshow.Item>

    </Slideshow>
    
    </div>
  )
}