import React, { Fragment } from 'react';

import Flyout from './Flyout';

export default function CreatorPreview({creator}) {
	return (
		<div className="creator-preview flex flex-col rounded p-2 m-1" onClick={() => console.log("clicked cp") }>
			<img className="preview rounded" src={creator.creatorThumb} alt={creator.displayName} />
			<div className="preview-caption">{creator.displayName}</div>
			<Flyout creator={creator} />
		</div>
	)
}