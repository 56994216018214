import React, { useState, useEffect } from 'react'
import { FiEdit3 } from 'react-icons/fi'

export default function EditIcon(props) {
  const [ color, setColor ] = useState('#999999')
  const [ size, setSize ] = useState('1.5em')

  useEffect(() => {
    if (props.color && props.color !== color) {
      setColor(props.color)
    }
    if (props.size && props.size !== size) {
      setSize(props.size)
    }
  }, [props, color, setColor, size, setSize])

  return (
    <FiEdit3 
      color={color}
      size={size}
    />
  )
}