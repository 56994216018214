import { useState, useEffect } from 'react'
import { userByUsername } from '../graphql/custom-queries'
import { useParams } from 'react-router-dom'
import useCurrentUser from './store/useCurrentUser'
import { gql } from '@apollo/client'
import useApolloClient from './useApolloClient'

export default function useProfileUser() {
  const [ userData, setUserData ] = useState(null)
  const [ loaded, setLoaded ] = useState(false)
  const [ isProfileOwner, setIsProfileOwner ] = useState(false)
  const currentUser = useCurrentUser()
  const client = useApolloClient()

  const { stageKey, creatorID } = useParams()
  const username = stageKey || creatorID

  const filterUserData = items => {
    const { avatar, banner, bio, current_sub_price, description, display_name, id, posts, username, last_online } = items[0]
    setUserData({avatar, banner, bio, current_sub_price, description, display_name, id, posts, username, last_online})
  }

  //NOTE: to be added to below conditional but taken out to allow for testing
  // && (currentUserID !== stageKey)
  useEffect(() => {
    // debugger
    if ( `@${currentUser.username}` === username && !loaded ) {
      setIsProfileOwner(true)
      setLoaded(true)
    } else {

      if ( !loaded && `@${currentUser.username}` !== username ) {
        // debugger
        let profileUsername = username.startsWith("@") ? username.split("@")[1] : username
      
        client.query({
          query: gql(userByUsername),
          variables: {
            username: profileUsername
          }
        })
        // .then(res => {setUserData(res.data.userByUsername.items[0]); console.log(res)})
        .then(res => filterUserData(res.data.userByUsername.items))
        .then(() => setLoaded(true))
        .catch(err => console.log(err))
      }
    
    }
    return () => {
      setLoaded(true)
    }
  }, [loaded, isProfileOwner, currentUser, username, stageKey, creatorID, client])

  // return !loaded ? { loading: true } : 
    return isProfileOwner ? currentUser : userData

}
