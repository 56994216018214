
import React from 'react'
import { gql } from '@apollo/client'
import { getConversation, searchConversations } from '../graphql/queries'
import { createMessage, updateConversation, updateUser } from '../graphql/mutations'
import { onCreateMessage } from '../graphql/subscriptions'
import { getUserLite } from '../graphql/custom-queries'
import useApolloClient from './useApolloClient'

export default function useConversationActions() {

  const client = useApolloClient()

  //NOTE: needs to update conversation without overwriting members
  const updateUserIsTyping = async (userID, datetime, conversationID) => {
    try {
      const res = await client.mutate({
        mutation: gql(updateConversation),
        variables: {
          input: {
            id: conversationID,
            seen_typing: [...{ userID: userID, last_typed: datetime }]
          }
        }
      })
      return res
    } catch (err) { console.log(err) }
  }

  const findConversation = async (convoID) => {
    console.log(convoID)
    try {
      let res = await client.query({
        query: gql(getConversation),
        variables: {
          id: convoID
        }
      })
      console.log("fetched convo", res)
      return res.data.getConversation
    } catch (err) {
      console.log(err)
    }
  }

  const listenToConversation = async (convoID, cb) => {
    let subscription;

    console.log("inside listen to conversation", convoID)
    await (async () => {
      console.log("inside async call", convoID)
      subscription = client.subscribe({ 
        query: gql(onCreateMessage),
        variables: {
          conversationID: convoID
        },
      })
      .subscribe({
        next: data => {
          console.log(data, data.data.onCreateMessage);
          cb(prevMessages => {
            const items = (prevMessages.items && prevMessages.items.length > 0) ? [...prevMessages.items, data.data.onCreateMessage] : [data.data.onCreateMessage]
            return {...prevMessages, items}
          })
        },
        error: error => {
          console.warn(error);
        }
      });
    })()
    console.log(subscription)
    return subscription
    // setTimeout(() => {
    //   subscription.unsubscribe();
    // }, 30000);
    // return subscription
  }

  const sendNewMessage = async (data) => {
    try {
      let res = await client.mutate({
        mutation: gql(createMessage),
        variables: {
          input: data
        }
      })
      console.log(res)
      return res
    } catch (err) { console.log(err) }
  }

  const fetchRecipientData = async (userID) => {
    try {
      let res = await client.query({
        query: gql(getUserLite),
        variables: {
          id: userID
        }
      })
      console.log(res)
      return res
      // recipient = res.data
    } catch (err) { console.log(err) }
  }

  return { findConversation, fetchRecipientData, sendNewMessage, listenToConversation }
}