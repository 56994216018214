import React, { useState } from 'react'
import ContentCard from '../components/content/ContentCard'
import ThumbnailCard from '../components/content/ThumbnailCard'
import { CgLayoutGridSmall } from 'react-icons/cg'
import { IconContext } from 'react-icons'

export default function ContentPage() {
  const [ cardSize, setCardSize ] = useState('small')

  const fetchS3Content = async () => {

  }

  return (
    <IconContext.Provider
      value={
        {
        size: '3.25em',
        color: '#999999'
        }
      }
    >
    <div className="content-page">
      <div className="grid-icon">
        <CgLayoutGridSmall onClick={() => setCardSize( cardSize === 'large' ? 'small' : 'large' )}/>
      </div>
    
      <div className="card-deck">

        { cardSize === 'large' ? 
          (
            <>
            <ContentCard/>
            <ContentCard/>
            <ContentCard/>
            <ContentCard/>
            <ContentCard/>
            <ContentCard/>
            </>
          )
        :
          (
            <>
            <ThumbnailCard/>
            <ThumbnailCard/>
            <ThumbnailCard/>
            <ThumbnailCard/>
            <ThumbnailCard/>
            <ThumbnailCard/>
            <ThumbnailCard/>
            <ThumbnailCard/>
            <ThumbnailCard/>
            <ThumbnailCard/>
            <ThumbnailCard/>
            </>
          )
        }
      </div>
   
    </div>
    </IconContext.Provider>
  )
}