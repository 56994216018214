import React, { useState, useEffect } from 'react'
import { FiLogOut } from 'react-icons/fi'

export default function LogoutIcon(props) {
  const [ color, setColor ] = useState('#999999')
  const [ size, setSize ] = useState('1.5em')

  useEffect(() => {
    if (props.color && props.color !== color) {
      setColor(props.color)
    }
    if (props.size && props.size !== size) {
      setSize(props.size)
    }
  }, [props, color, setColor, size, setSize])

  return (
    <FiLogOut 
      color={color} 
      size={size} 
      />
  )
}