/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      full_name
      email
      description
      username
      display_name
      bday
      bio
      avatar {
        bucket
        region
        key
        type
        userID
      }
      current_sub_price {
        price
      }
      discoverable
      last_online
      posts {
        items {
          id
          createdAt
          caption
          userID
          updatedAt
        }
        nextToken
      }
      content {
        bucket
        region
        key
        type
        userID
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      banner {
        default_theme
        upload {
          bucket
          region
          key
          type
          userID
        }
      }
      followers {
        items {
          id
          follower_userID
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          follower_userID
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      favorites {
        items {
          id
          user_id
          favorite_user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          notification_type
          user_id
          message
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          creatorID
          description
          price
          purchaserID
          sold
          createdAt
          updatedAt
        }
        nextToken
      }
      inventory {
        items {
          id
          creatorID
          description
          price
          purchaserID
          sold
          createdAt
          updatedAt
        }
        nextToken
      }
      current_subscriptions {
        items {
          id
          creatorID
          subscriberID
          start_date
          createdAt
          updatedAt
        }
        nextToken
      }
      current_subscribers {
        items {
          id
          creatorID
          subscriberID
          start_date
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          userID
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPurchaseItem = /* GraphQL */ `
  query GetPurchaseItem($id: ID!) {
    getPurchaseItem(id: $id) {
      id
      creatorID
      media {
        bucket
        region
        key
        type
        userID
      }
      description
      price
      purchaserID
      sold
      tags {
        id
        tag {
          id
          tag
          createdAt
          updatedAt
        }
        user {
          id
          full_name
          email
          description
          username
          display_name
          bday
          bio
          discoverable
          last_online
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPurchaseItems = /* GraphQL */ `
  query ListPurchaseItems(
    $filter: ModelPurchaseItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        creatorID
        media {
          bucket
          region
          key
          type
          userID
        }
        description
        price
        purchaserID
        sold
        tags {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      notification_type
      user_id
      message
      seen
      metadata {
        trigger_user
        hyperlink
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        notification_type
        user_id
        message
        seen
        metadata {
          trigger_user
          hyperlink
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFavoriteConnection = /* GraphQL */ `
  query GetFavoriteConnection($id: ID!) {
    getFavoriteConnection(id: $id) {
      id
      user_id
      favorite_user_id
      favorite_user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFavoriteConnections = /* GraphQL */ `
  query ListFavoriteConnections(
    $filter: ModelFavoriteConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavoriteConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        favorite_user_id
        favorite_user {
          id
          full_name
          email
          description
          username
          display_name
          bday
          bio
          discoverable
          last_online
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFollowingConnection = /* GraphQL */ `
  query GetFollowingConnection($id: ID!) {
    getFollowingConnection(id: $id) {
      id
      follower_userID
      following_userID
      follower {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      following {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFollowingConnections = /* GraphQL */ `
  query ListFollowingConnections(
    $filter: ModelFollowingConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowingConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        follower_userID
        following_userID
        follower {
          id
          full_name
          email
          description
          username
          display_name
          bday
          bio
          discoverable
          last_online
          createdAt
          updatedAt
        }
        following {
          id
          full_name
          email
          description
          username
          display_name
          bday
          bio
          discoverable
          last_online
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      messages {
        items {
          id
          text
          author_id
          recipient_id
          conversationID
          read
          when
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        userID
      }
      seen_typing {
        userID
        last_typed
      }
      createdAt
      updatedAt
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messages {
          nextToken
        }
        members {
          userID
        }
        seen_typing {
          userID
          last_typed
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConversationLinkConnection = /* GraphQL */ `
  query GetConversationLinkConnection($id: ID!) {
    getConversationLinkConnection(id: $id) {
      id
      userID
      user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        members {
          userID
        }
        seen_typing {
          userID
          last_typed
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listConversationLinkConnections = /* GraphQL */ `
  query ListConversationLinkConnections(
    $filter: ModelConversationLinkConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversationLinkConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          id
          full_name
          email
          description
          username
          display_name
          bday
          bio
          discoverable
          last_online
          createdAt
          updatedAt
        }
        conversationID
        conversation {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      text
      media {
        bucket
        region
        key
        type
        userID
      }
      author_id
      recipient_id
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        members {
          userID
        }
        seen_typing {
          userID
          last_typed
        }
        createdAt
        updatedAt
      }
      read
      when
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        media {
          bucket
          region
          key
          type
          userID
        }
        author_id
        recipient_id
        conversationID
        conversation {
          id
          createdAt
          updatedAt
        }
        read
        when
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      tag
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tag
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserTag = /* GraphQL */ `
  query GetUserTag($id: ID!) {
    getUserTag(id: $id) {
      id
      tag {
        id
        tag
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserTags = /* GraphQL */ `
  query ListUserTags(
    $filter: ModelUserTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tag {
          id
          tag
          createdAt
          updatedAt
        }
        user {
          id
          full_name
          email
          description
          username
          display_name
          bday
          bio
          discoverable
          last_online
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubPlan = /* GraphQL */ `
  query GetSubPlan($id: ID!) {
    getSubPlan(id: $id) {
      id
      creatorID
      price {
        price
      }
      subscriberID
      start_date
      createdAt
      updatedAt
    }
  }
`;
export const listSubPlans = /* GraphQL */ `
  query ListSubPlans(
    $filter: ModelSubPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        creatorID
        price {
          price
        }
        subscriberID
        start_date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      createdAt
      media {
        bucket
        region
        key
        type
        userID
      }
      caption
      likes {
        items {
          id
          postID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      comments {
        items {
          id
          text
          userID
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        media {
          bucket
          region
          key
          type
          userID
        }
        caption
        likes {
          nextToken
        }
        userID
        comments {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      text
      userID
      postID
      post {
        id
        createdAt
        media {
          bucket
          region
          key
          type
          userID
        }
        caption
        likes {
          nextToken
        }
        userID
        comments {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        userID
        postID
        post {
          id
          createdAt
          caption
          userID
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike($id: ID!) {
    getLike(id: $id) {
      id
      postID
      post {
        id
        createdAt
        media {
          bucket
          region
          key
          type
          userID
        }
        caption
        likes {
          nextToken
        }
        userID
        comments {
          nextToken
        }
        updatedAt
      }
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postID
        post {
          id
          createdAt
          caption
          userID
          updatedAt
        }
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByUsername = /* GraphQL */ `
  query UserByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: AWSEmail
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const subPlanByCreator = /* GraphQL */ `
  query SubPlanByCreator(
    $creatorID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subPlanByCreator(
      creatorID: $creatorID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creatorID
        price {
          price
        }
        subscriberID
        start_date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const subPlanBySubscriber = /* GraphQL */ `
  query SubPlanBySubscriber(
    $subscriberID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subPlanBySubscriber(
      subscriberID: $subscriberID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creatorID
        price {
          price
        }
        subscriberID
        start_date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postsByUser = /* GraphQL */ `
  query PostsByUser(
    $userID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByUser(
      userID: $userID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        media {
          bucket
          region
          key
          type
          userID
        }
        caption
        likes {
          nextToken
        }
        userID
        comments {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const fetchPostsByUser = /* GraphQL */ `
  query FetchPostsByUser(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fetchPostsByUser(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        media {
          bucket
          region
          key
          type
          userID
        }
        caption
        likes {
          nextToken
        }
        userID
        comments {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchConversations = /* GraphQL */ `
  query SearchConversations(
    $filter: SearchableConversationFilterInput
    $sort: SearchableConversationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchConversations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        messages {
          nextToken
        }
        members {
          userID
        }
        seen_typing {
          userID
          last_typed
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchConversationLinkConnections = /* GraphQL */ `
  query SearchConversationLinkConnections(
    $filter: SearchableConversationLinkConnectionFilterInput
    $sort: SearchableConversationLinkConnectionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchConversationLinkConnections(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userID
        user {
          id
          full_name
          email
          description
          username
          display_name
          bday
          bio
          discoverable
          last_online
          createdAt
          updatedAt
        }
        conversationID
        conversation {
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchMessages = /* GraphQL */ `
  query SearchMessages(
    $filter: SearchableMessageFilterInput
    $sort: SearchableMessageSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchMessages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        text
        media {
          bucket
          region
          key
          type
          userID
        }
        author_id
        recipient_id
        conversationID
        conversation {
          id
          createdAt
          updatedAt
        }
        read
        when
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchTags = /* GraphQL */ `
  query SearchTags(
    $filter: SearchableTagFilterInput
    $sort: SearchableTagSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchTags(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tag
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchPosts = /* GraphQL */ `
  query SearchPosts(
    $filter: SearchablePostFilterInput
    $sort: SearchablePostSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        createdAt
        media {
          bucket
          region
          key
          type
          userID
        }
        caption
        likes {
          nextToken
        }
        userID
        comments {
          nextToken
        }
        updatedAt
      }
      nextToken
      total
    }
  }
`;
