import React, { useState, useEffect, useContext } from 'react';

import { Breakpoint } from 'react-socks';
import '../styles/discoverStyles.css'

import FilterCreators from '../components/discover/FilterCreators';
import ResultsContainer from '../components/discover/ResultsContainer';
import MobileFilterCreators from '../components/discover/MobileFilterCreators';
import { userTagsData } from '../helpers/userTags'

// import { DiscoverContext } from '../helpers/store/DiscoverCreatorContext';


//NOTE: switch to socks breakpoint
export default function DiscoverCreatorsPage(props) {
	const tags = Object.keys(userTagsData);
	// const mobile = 1025;
	// const [isMobile, setIsMobile] = useState(mobile >= window.innerWidth);
	// useEffect(() => { // checks for mobile sizes, should be replaced with method used by the rest of the app
	// 	const checkMobile = () => setIsMobile(mobile >= window.innerWidth)
	//     window.addEventListener("resize", checkMobile);
	//     return () => window.removeEventListener("resize", checkMobile);
	// }, []);

	return (
		<div className="discover-page">
			
			<Breakpoint m down>
				<MobileFilterCreators
					tags={ tags }
				/>
			</Breakpoint>

			<Breakpoint l up>
				<FilterCreators
					tags={ tags }
				/>
			</Breakpoint>
				
			<ResultsContainer />
		</div>
	)
}