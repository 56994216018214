import { useContext } from 'react'
import { UserContext } from './currentUserContext'
import { Auth } from 'aws-amplify'
// import { blobToBase64 } from '../dataFormatters'
import { createUser, updateUser } from '../../graphql/mutations'
import { getUser } from '../../graphql/queries'
import { useHistory } from 'react-router-dom'
import useApolloClient from '../useApolloClient'

import { gql } from '@apollo/client'


export default function useDispatchers() {
  const history = useHistory()
//NOTE: eventually will have fetch calls to backend for user auth
//      upon success, log in, in not, error

  const { dispatch } = useContext(UserContext)
  const client = useApolloClient()

//NOTE: helper functions to update global state and use dispatch functions
  const login = (data) => {
    // debugger
    let payload = data.data.userByEmail.items[0]
    dispatch({
      type: 'LOGIN',
      payload: payload
    })
  }

  const addNewUser = (data) => {
    let payload = data.data.createUser.items[0]
    dispatch({
      type: 'ADD_NEW_USER',
      payload: payload
    })
  }

  const updateUserAvatar = async (userID) => {
    try {
      let res = await client.query({
        query: gql(getUser),
        variables: {
          id: userID
        }
      })
      console.log(res)
      
      dispatch({
        type: 'UPDATE_USER_AVATAR',
        payload: { data: res }
      })
      
    } catch (err) {
      console.log(err)
    }
  }

  const updateUserInfo = async (userData) => {
    try {
      let res = await client.mutate({
        mutation: gql(updateUser),
        variables: {
          input: userData
        }
      })
      console.log(res.data.updateUser)
//NOTE: Needs to figure out how to only send along what was updated key value pair
      dispatch({
        type: 'UPDATE_USER_INFO',
        payload: res.data.updateUser
      })
      return res.data
    } catch (err) {
      console.log(err)
      return err
    }

  }

  const logout = async () => {
    try {
      await Auth.signOut()
      dispatch({
        type: 'LOGOUT'
      })
      client.resetStore()
      history.push("/")
    } catch (error){
      console.log(error)
    }
  }

  return { login, addNewUser, updateUserAvatar, logout, updateUserInfo }
}