import React from 'react'

export default function LoadingSpinner() {

  return (
    <div className="spinner-container">
      <div className="folding-heart mx-auto" ></div>
    </div>
  )
}

    //   <svg 
    //   xmlns="http://www.w3.org/2000/svg" 
    //   width="50%" 
    //   height="50%" 
    //   viewBox="0 0 375 374.999991" 
    //   version="1.2">
    //   <defs>
    //     <clipPath id="clip1">
    //       <path d="M 15.980469 42 L 358.730469 42 L 358.730469 351 L 15.980469 351 Z M 15.980469 42 "/>
    //     </clipPath>
    //   </defs>
    //   <g id="surface1">
    //     <g clip-path="url(#clip1)" clip-rule="nonzero">
    //     <path 
    //       // id="heart-path"
    //       className="spinner"
    //       stroke="none"
    //       fillRule="nonzero"
    //       fill="rgb(56.469727%,29.798889%,89.019775%)"
    //       fillOpacity="1"

          // d="M 113.910156 42.214844 C 64.273438 42.214844 23.441406 79.621094 17.320312 127.546875 C 16.457031 131.46875 15.980469 135.582031 15.980469 140.175781 C 15.980469 196.136719 58.726562 246.644531 99.5625 284.144531 C 140.304688 321.742188 181.042969 346.519531 181.042969 346.519531 L 187.355469 350.441406 L 193.667969 346.519531 C 193.667969 346.519531 234.40625 321.742188 275.242188 284.144531 C 315.980469 246.644531 358.730469 196.136719 358.730469 140.175781 C 358.730469 135.582031 358.253906 131.5625 357.488281 127.738281 C 351.269531 79.621094 310.53125 42.214844 260.800781 42.214844 C 230.390625 42.214844 205.238281 58.09375 187.355469 79.90625 C 169.472656 58.09375 144.320312 42.214844 113.910156 42.214844 Z M 113.910156 66.707031 C 140.878906 66.707031 164.019531 81.246094 176.835938 102.867188 L 187.355469 120.660156 L 197.875 102.867188 C 210.691406 81.246094 233.832031 66.707031 260.800781 66.707031 C 298.386719 66.707031 328.796875 94.734375 333.292969 131.183594 L 333.484375 132.136719 C 333.960938 134.433594 334.25 137.015625 334.25 140.175781 C 334.25 183.03125 297.429688 230.480469 258.601562 266.160156 C 223.027344 298.972656 192.996094 317.246094 187.355469 320.785156 C 181.710938 317.246094 151.683594 298.972656 116.011719 266.160156 C 77.28125 230.480469 40.460938 183.03125 40.460938 140.175781 C 40.460938 136.921875 40.75 134.339844 41.226562 132.136719 L 41.417969 131.5625 L 41.417969 130.992188 C 46.007812 94.734375 76.324219 66.707031 113.910156 66.707031 Z M 113.910156 66.707031 "/>
    //     </g>
    //   </g>
    // </svg>