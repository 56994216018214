import { useState, useContext, useEffect, useMemo } from "react";
import { UserContext } from "./currentUserContext";
import useApolloClient from '../useApolloClient'
import { Auth } from "aws-amplify"
import { gql } from '@apollo/client'
import useDispatchers from "./dispatchers";
import { userByEmail } from "../../graphql/custom-queries";

//NOTE: custom hook that will return current User

export default function useCurrentUser() {
  const { login } = useDispatchers();
  const [user, setUser] = useState(false);
  const { state } = useContext(UserContext);
  const [checkedAuthedUser, setCheckedAuthedUser] = useState(false);
  const client = useApolloClient()

  const fetchUserDataFlow = async () => {
    try {
      const authedUser = await Auth.currentAuthenticatedUser()
      const apolloData = await client.query({
        query: gql(userByEmail),
        variables: {
          email: authedUser.attributes.email
        }
      })
      // console.log(apolloData)
      login(apolloData)
    } catch (err) { console.log(err.message) }
    return
  }

  useEffect(() => {
    if (checkedAuthedUser === false ) {
      fetchUserDataFlow()
      // console.log("from useEffect")
    }
    return () => setCheckedAuthedUser(true)
  })

  useMemo(() => {
    if (state.user !== user && checkedAuthedUser === true) {
      setUser(state.user);
      // subscribeToNotifications(state.user.id)
    }
  }, [state, user, checkedAuthedUser]);


  return user
}
