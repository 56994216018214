import React from 'react'
import { useLocation } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import IconImage from '../../helpers/IconImage'
import useCurrentUser from '../../helpers/store/useCurrentUser'
// import useIsLoggedIn from '../helpers/store/useIsLoggedIn'

//NOTE: maybe when logged in burger can open drawer menu
//  if not logged in, nabber can have options for sign up or log in
export default function MobileNabber(props) {
 
  let location = useLocation()
  const { username } = useCurrentUser()
  // const isLoggedIn = useIsLoggedIn()

  //NOTE: will need to change to !isLoggedIn eventually
  //NOTE: checks route and show mobile nabber on '/'

    //  {/* <Nav.Link href={`/@${username}/dashboard`}>
    //           Dash
    //         </Nav.Link> */}
  if ( !location.pathname.includes("dashboard") ) {
    return (
      
      <Navbar variant="dark" expand="lg" style={{zIndex: '1'}}>
        <Navbar.Brand id="brand"> 
          <Nav.Link href="/">
            <IconImage />
          </Nav.Link>
        </Navbar.Brand>
        { username ? 
          (
            <>
              <Navbar.Toggle className="border-0" aria-controls="navbar-toggle" />
                <Navbar.Collapse id="navbar-toggle">
                  <Nav className="ml-auto">
                    <Link className="nav-link" to={`/dashboard`}>Dashboard</Link>
                    <Link className="nav-link" to='#'>Featured</Link>
                    <Link className="nav-link" to='/discover'>Discover</Link>
                    {/* <Link className="nav-link" to='#'>SubMe Cams</Link> */}
                    <Link className="nav-link" to='#'>Community</Link>
                    <Link className="nav-link" to='#'>Log Out</Link>
                    <Link className="nav-link" to='#'>Contact Us</Link>
                    
                  </Nav>
                </Navbar.Collapse>
              </>
          )
        :
          (
            <>
              <Navbar.Toggle className="border-0" aria-controls="navbar-toggle" />
                <Navbar.Collapse id="navbar-toggle">
                  <Nav className="ml-auto">
                    <Link className="nav-link" to='/register/new-account' >Sign Up</Link>
                    <Link className="nav-link" to='/register/log-in' >Log In</Link>
                    <Link className="nav-link" to='#' >Contact Us</Link>
                    
                  </Nav>
                </Navbar.Collapse>
              </>
          )
        }
      </Navbar>

    )
  } else { return null }
}
