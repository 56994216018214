import faker from 'faker';

export const defaultTagNames = {
  "african": 'AFRICAN',
  "alt": 'ALT',
  "anal": 'ANAL',
  "arab": 'ARAB',
  "asian": 'ASIAN',
  "BBW": 'BBW',
  "big ass": 'BIGASS',
  "big tits": 'BIGTITS',
  "bisexual": 'BISEXUAL',
  "british": 'BRITISH',
  "bondage": 'BONDAGE',
  "brunette": 'BRUNETTE',
  "blonde": 'BLONDE',
  "cosplay": 'COSPLAY',
  "feet": 'FEET',
  "female": 'FEMALE',
  "french": 'FRENCH',
  "gay": 'GAY',
  "german": 'GERMAN',
  "hardcore": 'HARDCORE',
  "indian": 'INDIAN',
  "lesbian": 'LESBIAN',
  "male": 'MALE',
  "mature": 'MATURE',
  "MILF": 'MILF',
  "nerdy": 'NERDY',
  "pierced": 'PIERCED',
  "plus size": 'PLUSSIZE',
  "redhead": 'REDHEAD',
  "russian": 'RUSSIAN',
  "small tits": 'SMALLTITS',
  "squirter": 'SQUIRTER',
  "strap on": 'STRAPON',
  "tattooed": 'TATTOOED',
  "they/them": 'THEYTHEM',
  "transgender": 'TRANSGENDER'
}

export const defaultCreators = (num = null) => {

  const chooseTags = () => {
    const tagKeys = Object.keys(defaultTagNames);
    const numTags = Math.floor(Math.random() * 5);
    const tags = [];
    for (let i = 0, j = 0, k = Object.entries(defaultTagNames).length, newTag; i <= numTags; i++) {
      j = Math.floor(Math.random() * k)
      newTag = defaultTagNames[tagKeys[j]]
      tags.includes(newTag) || tags.push(newTag)
    }

    return tags;
  }
  const makeCreator = () => {
      return ({
      "creatorName" : faker.name.findName(),
      "displayName" : faker.internet.userName(),
      "tags" : chooseTags(),
      "creatorThumb" : faker.image.people(),  //"https://via.placeholder.com/150", // C/O https://placeholder.com/
      "dateCreated" : faker.date.past(),
      "email" : faker.internet.email(),
      "id" : faker.random.uuid()
      });
  }
  const chooseNumCreators = num || ((Math.floor(Math.random() * 100)) + 1);
  const creators = [];
  for(let i = 0; i < chooseNumCreators; i++) {
    creators.push(makeCreator());
  }
  return creators;
}