import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import SignUpForm from '../components/user-info/SignUpForm'
import SignUpUsernameForm from '../components/user-info/SignUpUsernameForm'
import EmailSignUpVerification from '../components/user-info/EmailSignUpVerification'

import useDispatchers from '../helpers/store/dispatchers'
import useCurrentUser from '../helpers/store/useCurrentUser'

import useApolloClient from '../helpers/useApolloClient'
import { gql } from '@apollo/client'
import { searchUsers } from '../graphql/queries'

import { Auth } from 'aws-amplify'
import { createUser } from '../graphql/mutations'

export default function RegistrationPage() {

  const client = useApolloClient()
  const currentUser = useCurrentUser()
  const { updateUserInfo } = useDispatchers()
  let history = useHistory()

  const [ flowStep, setFlowStep ] = useState(1)
  // const [ cognitoUser, setCognitoUser ] = useState({})
  const [ userData, setUserData ] = useState({})
  const [ errors, setErrors ] = useState(null)
  const [ newUserID, setNewUserID ] = useState(null)

  useEffect(() => {
    if ( currentUser && currentUser.isLoggedIn && currentUser.username ) {
      history.push(`/settings`)
    }
    if ( currentUser && currentUser.isLoggedIn && currentUser.username === null ) {
      setFlowStep(3)
    }
  }, [currentUser])

  const doesUsernameExist = async (username) => {
    try {
      let existence = await client.query({
      query: gql(searchUsers),
      variables: {
        filter: 
          { username: { eq: username } }
      }
    })
    console.log(existence)
    return existence.data.searchUsers.total === 0 ? false : true
    } catch (err) {
      console.log(err)
      setErrors(err.message)
    }
  }

  const createAuthUser = async data => {
    try {
      let res = await Auth.signUp({
        username: data.email,
        password: data.password
      })
      console.log(res)
      setFlowStep(2)
      setErrors(null)
      return res
    } catch (err) {
      console.log(err)
      setErrors(err.message)
      return err
    }
  }

  const resendConfirmationCode = async () => {
    try {
      let res = await Auth.resendSignUp(userData.email)
      console.log(res)
    } catch (err) { console.log(err) }
  }

  const verifyAuthUser = async verificationCode => {
    try {
      let res = await Auth.confirmSignUp(userData.email, verificationCode)
      console.log(res)
      let loginRes = await Auth.signIn(userData.email, userData.password)
      console.log(loginRes)
      let apiRes = await client.mutate({
        mutation: gql(createUser),
        variables: {
          input: { email: userData.email }
        }
      })
      console.log(apiRes)
      setNewUserID(apiRes.data.createUser.id)
      setFlowStep(3)
      setErrors(null)
    } catch (err) { 
      console.log(err)
      setErrors(err.message)
    }
  }

  const handleFirstStep = data => {
    setUserData(data)
    const res = createAuthUser(data)
    console.log(res)
    // setFlowStep(2)
  }

  const handleSecondStep = data => {
    console.log(data)
    const res = verifyAuthUser(data)
    console.log(res)
  }
  
  const handleUsernameSelection = data => {
    console.log(data)
    let res = doesUsernameExist(data)
    if ( res === true ) {
      setErrors({message: "Username is already in use."})
    } else {
      console.log(currentUser.id, newUserID)
      let updateRes = updateUserInfo({ id: newUserID, username: data.username })
      console.log(updateRes)
    }
  }

  const currentFlowStep = step => {
    switch (step) {
      case 1:
        return ( <SignUpForm handleSubmit={handleFirstStep} errors={errors} /> )
      case 2:
        return ( <EmailSignUpVerification handleSubmit={handleSecondStep} resendConfirmationCode={resendConfirmationCode} errors={errors} /> )
      case 3:
        return ( <SignUpUsernameForm handleSubmit={handleUsernameSelection} errors={errors}/> )
      default:
        break;
    }
  }

  
  return (
    <div className="reg-div d-flex flex-row vh-100 justify-content-center">
      
      { 
        currentFlowStep(flowStep)
        }
      
    </div>
  )
  
} 