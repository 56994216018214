import React from 'react'
import { DatePicker } from 'rsuite'
import { Container } from 'react-bootstrap'

export default function SchedulePicker(props) {

  // const [ dateTime, setDateTime ] = useState('')

  return (
    <Container
      id="schedule-post"
    >
      <DatePicker 
        appearance="default" 
        // placeholder="Date:"
        format="YYYY-MM-DD hh:mm"
        size="sm"
        showMeridian
        inline
        // hideSeconds="true"
        ranges={[
          {
            label: 'Now',
            value: new Date()
          }
        ]}  
        // onSelect={date => props.setScheduledDateTime(date)}
        style={{ background: '#2B2D2F' }}
      />

    </Container>
  )
}
// hh:mm:ss A