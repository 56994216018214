import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'

//LINK: https://docs.amplify.aws/lib/auth/manageusers/q/platform/js

export default function ForgotPasswordForm(props) {

  const [ emailAddress, setEmailAddress ] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    props.handleSubmit(emailAddress)
    //NOTE: sends email to auth to send user verification code
    //NOTE: sends user to ResetPasswordForm
  }

  return (
    <>
    <div id="register-form" className="d-flex flex-column top-bumper m-3">
      <div className="text-muted p-3" id="form-link" onClick={() => props.forgotPassword(false)}>
        Back to Login
      </div>

      <Form onSubmit={e => handleSubmit(e)}>
        <Form.Group controlId="form-email-input">
        <Form.Label>
          Forgot my Password
        </Form.Label>
        <Form.Control 
          type="email"
          placeholder="example@someemail.com"
          value={emailAddress}
          required
          onChange={e => setEmailAddress(e.target.value)}
        />
        <Form.Text className="text-muted">
          Please enter the email address associated with your account to receive verification code.
        </Form.Text>

        { props.errors && (
          <div className="error">
            { props.errors }
          </div>
        )}

        </Form.Group>

        <Button type="submit" id="button" onMouseDown={e => handleSubmit(e)} >
          Submit
        </Button>

      </Form>
    </div>
    </>
  )
}