import React, { useState, useRef } from 'react'
import AvatarEditor from 'react-avatar-editor'
import UserIcon from '../icons/UserIcon'
import { Button } from 'react-bootstrap'
import useCurrentUser from '../../helpers/store/useCurrentUser'
import { v4 as uuid } from 'uuid'
import { updateUser } from '../../graphql/mutations'
import useDispatchers from '../../helpers/store/dispatchers'

import config from '../../aws-exports'
import { Storage, API } from 'aws-amplify'
import useApolloClient from '../../helpers/useApolloClient'
import { gql } from '@apollo/client'

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket
} = config

export default function ChangeAvatar() {
  const [ file, setFile ] = useState(null)
  const [upload, setUpload] = useState(null)
  const [ error, setError ] = useState(null)
  const [scale, setScale] = useState('1.0')
  const [rotation, setRotation] = useState('0')
  const [isLoading, setIsLoading] = useState(false)
  const { updateUserAvatar } = useDispatchers()
  const { username, id } = useCurrentUser()
  const editor = useRef()
  const client = useApolloClient()

  const handleUpload = e => {
    const upload = e.target.files[0]
    setFile(URL.createObjectURL(upload))
  }

  //NOTE: Also needs to update user avatar in backend

  const handleChange = () => {
    const canvas = editor.current.getImage().toDataURL()
    setUpload(canvas)
  }

  const handleSave = e => {
    // NOTE: took '${uuid()}' out of key for now
    setIsLoading(true)
    const key = `${username}/avatar`
    const fileForUpload = { bucket, key, region }
    const buf = Buffer.from(upload.replace(/^data:image\/\w+;base64,/, ""),'base64')
    
    //NOTE: Image persists to storage first then takes the key and updates the user profile in API
    Storage.put(key, buf, {
      ContentEncoding: 'base64',
      ContentType: 'image/png',
      level: 'public'
    })
      .then(data => console.log(data))
      
      .then(() => {
        // API.graphql({ query: updateUser, 
        //   variables: { 
        //     input: {id: id, avatar: fileForUpload}}})
        client.mutate({
          mutation: gql(updateUser),
          variables: {
            input: {id: id, avatar: fileForUpload}
          }
        })
      })
      .then(() => updateUserAvatar(id))
      .then(() => setIsLoading(false))
      .catch(err => setError(err))
    }

    return file === null ? 
        (
          <div>
            <form>
              <input type="file" onChange={e => handleUpload(e)} accept="image/*" />
            </form>
          </div>
        )
      : 
        (
          <div className="edit-avatar-container">

            <div>
              <AvatarEditor
                ref={editor} 
                image={file}
                width={300}
                height={300}
                border={20}
                borderRadius={150}
                rotate={rotation}
                scale={scale}
                onImageReady={() => handleChange()}
                onImageChange={() => handleChange()}
              />
            </div>

            <div>
              <label for="zoom">Zoom</label>
              <input 
                type="range" 
                id="zoom" 
                name="zoom" 
                min="1.0" 
                max="5.0" 
                value={scale} 
                step="0.1" 
                onChange={e => setScale(e.target.value)}
                />
            </div>

            <div>
              <label for="rotate">Rotate</label>
              <input 
                type="range" 
                id="rotate" 
                name="rotate" 
                min="-180" 
                max="180" 
                value={rotation} 
                step="1" 
                onChange={e => setRotation(e.target.value)}
                />
            </div>

            <div className="avatar-preview">
              <p>
                Preview:
              </p>
              <UserIcon avatar={upload} />
            </div>

            <div>
              <Button 
                onClick={isLoading ? null : e => handleSave(e)}
                disabled={isLoading}
                >
                { isLoading ? 'Saving...' : 'Save' }
              </Button>
            </div>

          </div>
        )
}