import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import IconImage from '../../helpers/IconImage'
import useCurrentUser from '../../helpers/store/useCurrentUser'
// import useDispatchers from '../../helpers/store/dispatchers'
import InboxIcon from '../utils/InboxIcon'
import NotificationsIcon from '../icons/NotificationsIcon'
import StoreIcon from '../icons/StoreIcon'
import VideoIcon from '../icons/VideoIcon'
import DiscoverIcon from '../icons/DiscoverIcon'
import HelpIcon from '../icons/HelpIcon'
// import { NotificationWrapper } from '../../helpers/notificationActions'

export default function Nabber() {

  const { username, id } = useCurrentUser()
  // const { logout } = useDispatchers()

  //NOTE: if signed in, show links to dashboard
  return (
    // <Breakpoint l up>
    <>
    {/* <NotificationWrapper userID={id}/> */}
      <Navbar variant="dark" fixed="top" >
        <Navbar.Brand className="mr-3 ml-2"> 
          <Nav.Link href={ username ? `/dashboard` : '/' } id="brand">
            <IconImage />
          </Nav.Link>
        </Navbar.Brand>

        <Container fluid>
        <Nav className="d-flex flex-fill justify-content-evenly">

            {/* <div 
              className="mr-1 ml-1" 
              id="nabber-icons" 
              data-CustomTooltip="Discover"
            >
              <Nav.Link href='/discover' >
                <DiscoverIcon size="2em" />
              </Nav.Link>
            </div> */}

            {/* <div 
              className="mr-1 ml-1" 
              id="nabber-icons"
              data-CustomTooltip="Videos"
              >
              <Nav.Link >
                <VideoIcon size="2.05em" />
              </Nav.Link>
            </div> */}

            {/* <div 
              className="mr-1 ml-1" 
              id="nabber-icons"
              data-CustomTooltip="BuyMe"
              >
              <Nav.Link>
                <StoreIcon size="2em"/>
              </Nav.Link>
            </div> */}

            {/* <Nav.Link>
              Creator Community
            </Nav.Link> */}

            {/* <div 
              className="mr-1 ml-1" 
              id="nabber-icons"
              data-CustomTooltip="HelpMe"
              >
              <Nav.Link>
                <HelpIcon size="2em"/>
              </Nav.Link>
            </div> */}

          <Nav className="me-auto w-100 d-flex flex-row-reverse">
            { username ? 
              (
                <>

                  <div id="nabber-icons">
                    <Nav.Link className="p-2 mt-1" id="nabber-icons">
                      <NotificationsIcon size="1.5em"/>
                    </Nav.Link>
                  </div>

                  <div id="nabber-icons">
                    <Nav.Link 
                      className="p-2" 
                      href={`/dashboard/messages`} 
                      id="nabber-icons"
                    >
                      <InboxIcon size="2.15em"/>
                    </Nav.Link>
                  </div>

                </>
              )

              :
              
              (
                <>
                  <Nav.Link href="/register/new-account">
                    Sign Up
                  </Nav.Link>

                  <Nav.Link href="/register/log-in">
                    Log In
                  </Nav.Link>
                </>
              )
            }
          </Nav>

        </Nav>
        </Container>

      </Navbar>
      </>
      // </NotificationWrapper>
    // </Breakpoint>
  )
}