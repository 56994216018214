import React, { useState, useEffect } from 'react'
import { Breakpoint } from 'react-socks'
import { useHistory, useParams } from 'react-router-dom'
import useCurrentUser from '../../helpers/store/useCurrentUser'
import MessageBlurb from './MessageBlurb'
import NewMessageForm from './NewMessageForm'
import useConversationActions from '../../helpers/conversationActions'

import useFindOrCreateConversation from '../../helpers/useFindOrCreateConversation'
import { createConversationLinkConnection } from '../../graphql/mutations'

//NOTE: needs to get recipient data in props
// Conversation component can be accessed from message icon on user profile or from inbox
export default function Conversation(props) {

  //NOTE: when routed from Inbox Page convoLink gets passed through. 
  // When routed from creator Page, profileUser gets passed through
  // Needs to check and either fetch conversation or create a new conversation when first message sends

  const user = useCurrentUser()
  let history = useHistory()
  const { createConversationConnections } = useFindOrCreateConversation()
  const convoData = history.location.state
  // convoData.convoLink.conversationID
  const { findConversation, sendNewMessage, listenToConversation } = useConversationActions()

  const [ messages, setMessages ] = useState({})
  const [ loaded, setIsLoaded ] = useState(false)
  // const [ accessDenied, setAccessDenied ] = useState(true)
  const [ isNewConversation, setIsNewConversation ] = useState(true)
  const [ convoCheck, setConvoCheck ] = useState(false)
  const [ currentConversation, setCurrentConversation ] = useState({})
  const [ recipientData, setRecipientData ] = useState({})
  const [ isListening, setIsListening ] = useState(false)

  const scrollIntoView = () => {
    const endDiv = document.getElementById("message-end")
    endDiv.scrollIntoView()
  }

  useEffect(() => {
    if (messages.items && messages.items.length > 0 ) {
      scrollIntoView()
    }
  }, [messages])
  
  useEffect(() => {
    if ( !loaded && !convoCheck && user && user.conversations && user.conversations.items) {
      
      let recipient
      let recipientConversation
      if ( convoData.currentConversation ) {
        recipient = convoData.currentConversation.conversation.members.find(m => m.userID !== user.id )
        setRecipientData(recipient.userID)
        setCurrentConversation(convoData.currentConversation)
        setIsNewConversation(false)
      } else if ( convoData.profileUser ) {
        recipientConversation = user.conversations.items.find(c => {
          return c.conversation.members.find(m => m.userID === convoData.profileUser.id)
        })
        recipientConversation && setCurrentConversation(recipientConversation)
        recipientConversation ? setIsNewConversation(false) : setIsNewConversation(true)
        setRecipientData(convoData.profileUser.id)
      }

      setConvoCheck(true)
      // setAccessDenied(false)
    }
  }, [loaded, convoCheck, user])

  useEffect(() => {
    if ( !isNewConversation && convoCheck && !loaded && currentConversation.conversationID) {
      const res = findConversation(currentConversation.conversationID)
      res.then(data => {
        console.log("sets messages", data)
        setMessages(data.messages)
        !isListening && listenToConversation(currentConversation.conversationID, setMessages)
        setIsListening(true)
      })
    }
  }, [isNewConversation, convoCheck, loaded, currentConversation, isListening])

  const handleNewMessage = async (input) => {
    let messageData
    //NOTE: needs to set messages when first message sends for new conversation
    if ( isNewConversation ) {
      console.log("new conversation")
      // const convoRes = await createConversationConnections(user.id, recipientData)
      createConversationConnections(user.id, recipientData).then(convoRes => {
        console.log(convoRes)
        listenToConversation(convoRes.id, setMessages).then(() => {
          messageData = {
            author_id: user.id,
            recipient_id: recipientData,
            conversationID: convoRes.id,
            read: 'UNREAD',
            text: input,
            when: new Date().toISOString()
          }
          console.log(messageData)
          setIsListening(true)
          sendNewMessage(messageData)
          setCurrentConversation({conversationID: convoRes.id})
          setIsNewConversation(false)
        })
      }).catch(err => console.log(err))
      // if (convoRes) {
      // }
      
    } else {
      //NOTE: if new message is first in conversation, needs to create new conversation
      // const recipient = currentConversation.conversation.members[0].userID === user.id ? currentConversation.conversation.members[0].userID : currentConversation.conversation.members[1].userID || convoData.userID
      messageData = {
        author_id: user.id,
        recipient_id: recipientData,
        conversationID: currentConversation.conversationID,
        read: 'UNREAD',
        text: input,
        when: new Date().toISOString()
      }
      console.log(messageData)
      sendNewMessage(messageData)
    }
  }

  //NOTE: I wonder if we can go back now that routing is configured better?
  const backAndClear = () => {
    props.setConvo(false)
    history.push(`/dashboard/messages`)
  }
  return (
    <>
      <Breakpoint m down >
        <div className="header left">
            <div onClick={() => backAndClear()}>
              Back to Inbox 
            </div>
        </div>
      </Breakpoint>
{/* 
      <div>
        User Goes Here
      </div> */}
      <div className="convo-container d-flex flex-column" id="convo-container">

        { messages.items && messages.items.length > 0 ? 
          messages.items.map(message => (
            <MessageBlurb data={message} key={message.id} isAuthor={message.author_id === user.id}/>
          ))
          :
        ( "Send a message to start talking!" )  
        }
        <div id="message-end"></div>
      </div>
      <div className="convo-message-form">

        <NewMessageForm handleSubmit={handleNewMessage}/>
      </div>

    </>
  )
}