import React, { useState, useEffect } from 'react'
import UserIcon from '../icons/UserIcon'
import { Collapse, Row, Col, Container } from 'react-bootstrap'

export default function MessageBlurb(props) {
  // if true flex-direction: row-reverse and start at right side, if false row
  // we're looking for hot iOS iMessage vibes

    const [open, setOpen] = useState(false)
    
  //NOTE: needs to add class 'from-current-user' to top div is owner of message is currentUser
  
  return (
    <Container>
    <Row className={ props.isAuthor ? "message-blurb flex-row-reverse" : "message-blurb flex-row"}>
      
      {/* <div id="user-avatar-box" className="d-flex justify-content-center">
        <UserIcon size="1.75em"/>
      </div> */}

      <Col className={ props.isAuthor ? "message-blurb-bulk justify-content-end" : "message-blurb-bulk"} >
        <div 
          id="message-bubble" 
          onClick={() => setOpen(!open)}
          aria-controls="collapse-time"
          aria-expanded={open}
          className="rounded"
        >
          <p>{ props.data.text }</p>
        </div>

      <Collapse in={open}>
        <div className="message-blurb-time" id="collapse-time">
          <p>{ props.data.when }</p>
        </div>
      </Collapse>

      </Col>
      
    </Row>
    </Container>
  )

}