import React, { useState, useEffect } from 'react'
// import { GoHome } from 'react-icons/go'
import { BsColumnsGap } from 'react-icons/bs'

export default function OverviewIcon(props) {
  const [ color, setColor ] = useState('#999999')
  const [ size, setSize ] = useState('1.2em')

  useEffect(() => {
    if (props.color && props.color !== color) {
      setColor(props.color)
    }
    if (props.size && props.size !== size) {
      setSize(props.size)
    }
    if (props.active && props.activeIconColor) {
      setColor(props.activeIconColor)
    }
    if (!props.active && props.color) {
      setColor(props.color)
    }
  }, [props, color, setColor, size, setSize])

  return (
    <BsColumnsGap 
      color={color} 
      size={size} 
      />
  )
}