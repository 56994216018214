/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($conversationID: ID!) {
    onCreateMessage(conversationID: $conversationID) {
      id
      text
      media {
        bucket
        region
        key
        type
        userID
      }
      author_id
      recipient_id
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        members {
          userID
        }
        seen_typing {
          userID
          last_typed
        }
        createdAt
        updatedAt
      }
      read
      when
      createdAt
      updatedAt
    }
  }
`;
export const onNotificationByUserId = /* GraphQL */ `
  subscription OnNotificationByUserId($user_id: ID!) {
    onNotificationByUserId(user_id: $user_id) {
      id
      notification_type
      user_id
      message
      seen
      metadata {
        trigger_user
        hyperlink
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      full_name
      email
      description
      username
      display_name
      bday
      bio
      avatar {
        bucket
        region
        key
        type
        userID
      }
      current_sub_price {
        price
      }
      discoverable
      last_online
      posts {
        items {
          id
          createdAt
          caption
          userID
          updatedAt
        }
        nextToken
      }
      content {
        bucket
        region
        key
        type
        userID
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      banner {
        default_theme
        upload {
          bucket
          region
          key
          type
          userID
        }
      }
      followers {
        items {
          id
          follower_userID
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          follower_userID
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      favorites {
        items {
          id
          user_id
          favorite_user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          notification_type
          user_id
          message
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          creatorID
          description
          price
          purchaserID
          sold
          createdAt
          updatedAt
        }
        nextToken
      }
      inventory {
        items {
          id
          creatorID
          description
          price
          purchaserID
          sold
          createdAt
          updatedAt
        }
        nextToken
      }
      current_subscriptions {
        items {
          id
          creatorID
          subscriberID
          start_date
          createdAt
          updatedAt
        }
        nextToken
      }
      current_subscribers {
        items {
          id
          creatorID
          subscriberID
          start_date
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          userID
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      full_name
      email
      description
      username
      display_name
      bday
      bio
      avatar {
        bucket
        region
        key
        type
        userID
      }
      current_sub_price {
        price
      }
      discoverable
      last_online
      posts {
        items {
          id
          createdAt
          caption
          userID
          updatedAt
        }
        nextToken
      }
      content {
        bucket
        region
        key
        type
        userID
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      banner {
        default_theme
        upload {
          bucket
          region
          key
          type
          userID
        }
      }
      followers {
        items {
          id
          follower_userID
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          follower_userID
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      favorites {
        items {
          id
          user_id
          favorite_user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          notification_type
          user_id
          message
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          creatorID
          description
          price
          purchaserID
          sold
          createdAt
          updatedAt
        }
        nextToken
      }
      inventory {
        items {
          id
          creatorID
          description
          price
          purchaserID
          sold
          createdAt
          updatedAt
        }
        nextToken
      }
      current_subscriptions {
        items {
          id
          creatorID
          subscriberID
          start_date
          createdAt
          updatedAt
        }
        nextToken
      }
      current_subscribers {
        items {
          id
          creatorID
          subscriberID
          start_date
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          userID
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      full_name
      email
      description
      username
      display_name
      bday
      bio
      avatar {
        bucket
        region
        key
        type
        userID
      }
      current_sub_price {
        price
      }
      discoverable
      last_online
      posts {
        items {
          id
          createdAt
          caption
          userID
          updatedAt
        }
        nextToken
      }
      content {
        bucket
        region
        key
        type
        userID
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      banner {
        default_theme
        upload {
          bucket
          region
          key
          type
          userID
        }
      }
      followers {
        items {
          id
          follower_userID
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          follower_userID
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      favorites {
        items {
          id
          user_id
          favorite_user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          notification_type
          user_id
          message
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
      purchases {
        items {
          id
          creatorID
          description
          price
          purchaserID
          sold
          createdAt
          updatedAt
        }
        nextToken
      }
      inventory {
        items {
          id
          creatorID
          description
          price
          purchaserID
          sold
          createdAt
          updatedAt
        }
        nextToken
      }
      current_subscriptions {
        items {
          id
          creatorID
          subscriberID
          start_date
          createdAt
          updatedAt
        }
        nextToken
      }
      current_subscribers {
        items {
          id
          creatorID
          subscriberID
          start_date
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          userID
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePurchaseItem = /* GraphQL */ `
  subscription OnCreatePurchaseItem {
    onCreatePurchaseItem {
      id
      creatorID
      media {
        bucket
        region
        key
        type
        userID
      }
      description
      price
      purchaserID
      sold
      tags {
        id
        tag {
          id
          tag
          createdAt
          updatedAt
        }
        user {
          id
          full_name
          email
          description
          username
          display_name
          bday
          bio
          discoverable
          last_online
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePurchaseItem = /* GraphQL */ `
  subscription OnUpdatePurchaseItem {
    onUpdatePurchaseItem {
      id
      creatorID
      media {
        bucket
        region
        key
        type
        userID
      }
      description
      price
      purchaserID
      sold
      tags {
        id
        tag {
          id
          tag
          createdAt
          updatedAt
        }
        user {
          id
          full_name
          email
          description
          username
          display_name
          bday
          bio
          discoverable
          last_online
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePurchaseItem = /* GraphQL */ `
  subscription OnDeletePurchaseItem {
    onDeletePurchaseItem {
      id
      creatorID
      media {
        bucket
        region
        key
        type
        userID
      }
      description
      price
      purchaserID
      sold
      tags {
        id
        tag {
          id
          tag
          createdAt
          updatedAt
        }
        user {
          id
          full_name
          email
          description
          username
          display_name
          bday
          bio
          discoverable
          last_online
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      notification_type
      user_id
      message
      seen
      metadata {
        trigger_user
        hyperlink
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      notification_type
      user_id
      message
      seen
      metadata {
        trigger_user
        hyperlink
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      notification_type
      user_id
      message
      seen
      metadata {
        trigger_user
        hyperlink
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFavoriteConnection = /* GraphQL */ `
  subscription OnCreateFavoriteConnection {
    onCreateFavoriteConnection {
      id
      user_id
      favorite_user_id
      favorite_user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFavoriteConnection = /* GraphQL */ `
  subscription OnUpdateFavoriteConnection {
    onUpdateFavoriteConnection {
      id
      user_id
      favorite_user_id
      favorite_user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFavoriteConnection = /* GraphQL */ `
  subscription OnDeleteFavoriteConnection {
    onDeleteFavoriteConnection {
      id
      user_id
      favorite_user_id
      favorite_user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFollowingConnection = /* GraphQL */ `
  subscription OnCreateFollowingConnection {
    onCreateFollowingConnection {
      id
      follower_userID
      following_userID
      follower {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      following {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFollowingConnection = /* GraphQL */ `
  subscription OnUpdateFollowingConnection {
    onUpdateFollowingConnection {
      id
      follower_userID
      following_userID
      follower {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      following {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFollowingConnection = /* GraphQL */ `
  subscription OnDeleteFollowingConnection {
    onDeleteFollowingConnection {
      id
      follower_userID
      following_userID
      follower {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      following {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConversation = /* GraphQL */ `
  subscription OnCreateConversation {
    onCreateConversation {
      id
      messages {
        items {
          id
          text
          author_id
          recipient_id
          conversationID
          read
          when
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        userID
      }
      seen_typing {
        userID
        last_typed
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConversation = /* GraphQL */ `
  subscription OnUpdateConversation {
    onUpdateConversation {
      id
      messages {
        items {
          id
          text
          author_id
          recipient_id
          conversationID
          read
          when
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        userID
      }
      seen_typing {
        userID
        last_typed
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConversation = /* GraphQL */ `
  subscription OnDeleteConversation {
    onDeleteConversation {
      id
      messages {
        items {
          id
          text
          author_id
          recipient_id
          conversationID
          read
          when
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        userID
      }
      seen_typing {
        userID
        last_typed
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConversationLinkConnection = /* GraphQL */ `
  subscription OnCreateConversationLinkConnection {
    onCreateConversationLinkConnection {
      id
      userID
      user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        members {
          userID
        }
        seen_typing {
          userID
          last_typed
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConversationLinkConnection = /* GraphQL */ `
  subscription OnUpdateConversationLinkConnection {
    onUpdateConversationLinkConnection {
      id
      userID
      user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        members {
          userID
        }
        seen_typing {
          userID
          last_typed
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConversationLinkConnection = /* GraphQL */ `
  subscription OnDeleteConversationLinkConnection {
    onDeleteConversationLinkConnection {
      id
      userID
      user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        members {
          userID
        }
        seen_typing {
          userID
          last_typed
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTag = /* GraphQL */ `
  subscription OnCreateTag {
    onCreateTag {
      id
      tag
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTag = /* GraphQL */ `
  subscription OnUpdateTag {
    onUpdateTag {
      id
      tag
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTag = /* GraphQL */ `
  subscription OnDeleteTag {
    onDeleteTag {
      id
      tag
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserTag = /* GraphQL */ `
  subscription OnCreateUserTag {
    onCreateUserTag {
      id
      tag {
        id
        tag
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserTag = /* GraphQL */ `
  subscription OnUpdateUserTag {
    onUpdateUserTag {
      id
      tag {
        id
        tag
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserTag = /* GraphQL */ `
  subscription OnDeleteUserTag {
    onDeleteUserTag {
      id
      tag {
        id
        tag
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        favorites {
          nextToken
        }
        notifications {
          nextToken
        }
        purchases {
          nextToken
        }
        inventory {
          nextToken
        }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSubPlan = /* GraphQL */ `
  subscription OnCreateSubPlan {
    onCreateSubPlan {
      id
      creatorID
      price {
        price
      }
      subscriberID
      start_date
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSubPlan = /* GraphQL */ `
  subscription OnUpdateSubPlan {
    onUpdateSubPlan {
      id
      creatorID
      price {
        price
      }
      subscriberID
      start_date
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSubPlan = /* GraphQL */ `
  subscription OnDeleteSubPlan {
    onDeleteSubPlan {
      id
      creatorID
      price {
        price
      }
      subscriberID
      start_date
      createdAt
      updatedAt
    }
  }
`;
