import React, { useState, useEffect, useMemo } from 'react'
import ProfileIcon from './ProfileIcon'
import ActiveNow from './ActiveNow'
// import useUserAvatar from '../../helpers/useUserAvatar'
import { fetchUserAvatar } from '../../helpers/fetchUserAvatar'
// import { S3Image } from 'aws-amplify-react'

function UserIcon(props) {
  const [ avatar, setAvatar ] = useState(null)
  const [ active, setActive ] = useState(true)
  // const [ avatarKey, setAvatarKey ] = useState({})
  // const [ avatarCredentials, setAvatarCredentials ] = useState({})

  const [ iconSize, setIconSize ] = useState("6em")

  const fetchAvatarImgAndFormat = async (avatarData) => {
    let avatarReq = await fetchUserAvatar(avatarData.key, setAvatar)
    // console.log(avatarReq)
    // setAvatar(avatarReq)
    return avatarReq
  }

  // useEffect(() => {
  //   if (props.avatarCredentials && avatarKey === {}) {
  //     setAvatarKey(props.avatarCredentials.key)
  //   }
  // }, [props, avatarKey])

  useEffect(() => {
    if (props.avatar && props.avatar !== avatar) {
      props.avatar.data ? setAvatar(props.avatar.data) : setAvatar(props.avatar)
    }
    if ( props.avatarCredentials && !avatar ) {
      // setAvatarCredentials(props.avatarCredentials)
      fetchAvatarImgAndFormat(props.avatarCredentials)
    }
    if (props.size && props.size !== iconSize) {
      setIconSize(props.size)
    }
  }, [props, avatar, iconSize])
  
  //TODO: needs to check if props.avatar is an object and needs to be fetched
  // useEffect(() => {
  //   if (props.avatar && props.avatar !== avatar) {
  //     props.avatar.data ? setAvatar(props.avatar.data) : setAvatar(props.avatar)
  //   }
  // }, [props, avatar, setAvatar])
  
  // useEffect(() => {
  //   if ( props.avatarCredentials && !avatar ) {
  //     // setAvatarCredentials(props.avatarCredentials)
  //     fetchAvatarImgAndFormat(props.avatarCredentials)
  //   }
  // }, [props, avatar])

  // useEffect(() => {
  //   if (props.size && props.size !== iconSize) {
  //     setIconSize(props.size)
  //   }
  // }, [props, iconSize, setIconSize])
  
  // const userAvatar = useUserAvatar(avatarCredentials)
  return (
    <div id="trans" className="parent-container d-flex flex-column justify-content-center" >

      { active && (  
        <ActiveNow />
      )}

      { avatar ? ( <img src={avatar} alt="avatar" id={props.post ? 'post-avatar' : 'user-avatar'}  /> ) : ( <ProfileIcon size={iconSize}/> ) }

    </div>
    
  )
}

export default React.memo(UserIcon)