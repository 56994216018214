import React, { useEffect, useState } from 'react'
import useCurrentUser from '../helpers/store/useCurrentUser'
import { getUserLite } from '../graphql/custom-queries'
import { gql } from '@apollo/client'
import useApolloClient from '../helpers/useApolloClient'
// import FavoritesContainer from '../components/follow/FavoritesContainer'
import CollectionContainer from '../components/follow/CollectionContainer'
// import FollowingContainer from '../components/follow/FollowingContainer'

export default function FollowersPage() {

  const [ loaded, setLoaded ] = useState(false)
  const { following, followers, favorites } = useCurrentUser()
  const client = useApolloClient()
  // console.log(following, followers)

  useEffect(() => {
    if (following && followers && favorites && !loaded) {
      setLoaded(true)
    }
  }, [following, followers, loaded])

  const fetchUser = async (id) => {
    try {
      const res = await client.query({
        query: gql(getUserLite),
        variables: {
          id: id
        }
      })
      return res.data.getUser
    } catch (err) { console.log(err) }
  }

  return loaded ? (
    <div className="p-2">
      <div>
        Following: {following.items.length}

        <CollectionContainer items={following} fetchUser={fetchUser} type="FOLLOWING"/>
        
      </div>
      <div>
        Followers: {followers.items.length}

        {/* <FollowersContainer items={followers} fetchUser={fetchUser}/> */}

      </div>
      <div>
        Favorites: {favorites.items.length}

        {/* <FavoritesContainer items={favorites} fetchUser={fetchUser}/> */}

      </div>
    </div>
  ) : ( 
    <div>
      Loading
    </div>
  )
}