import React, { useState, useEffect } from 'react'
import LoadingSpinner from '../../utils/LoadingSpinner'

export default function InventoryContainer(props) {

  return (
    <div>
      <div onClick={() => props.handleBackClick('POST')} className="left m-2">
          Back to Profile
        </div>
      <div id="inventory-container" className="rounded p-2 h-100">
        <div className="spinner-container m-4">
          
          <LoadingSpinner />

        </div>

        Loading please wait
      </div>
    </div>
  )
}