import React, { useState, useEffect } from "react";
// import UserIcon from '../icons/UserIcon'
// import { S3Image } from 'aws-amplify-react'
// import UiAvatarIcon from '../archived/UiAvatarIcon'
import UserAvatar from '../utils/UserAvatar'

export default function DisplayNameHeader(props) {

  const [ user, setUser ] = useState({})
  //NOTE: name, bio, activeNow boolean for icon, number of subs

  useEffect(() => {
    if (props.user && props.user !== user) {
      setUser(props.user)
    }
  }, [props, user, setUser])

  return (
    <div className="profile-header-container" id="trans">
      
      <div className="profile-avatar" id="trans" >
        { user.avatar && ( 
          
          <UserAvatar avatarKey={user.avatar.key} lastOnline={user.last_online}/> 
          
          ) }
      </div>
      
      <div className="profile-header w-100 text-truncate py-auto my-auto" id="trans">

        { user.display_name || user.displayName }

      </div>

    </div>
  );
}
