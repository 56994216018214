import React from 'react';

export default function Flyout({creator}) {
	return (
		<div className="flyout">
			<p>{ creator.tags.join(", ") }</p>
			<p>Sub fee</p>
			<p>Stuff I do</p>
			<p>About me</p>
		</div>
	)
}