import React, { useState, useEffect } from 'react'
import { RiStarSmileLine } from 'react-icons/ri'
import { RiNotification2Line } from 'react-icons/ri'

export default function NotificationsIcon(props) {
  const [ color, setColor ] = useState('#999999')
  const [ size, setSize ] = useState('1.5em')

  useEffect(() => {
    if (props.color && props.color !== color) {
      setColor(props.color)
    }
    if (props.size && props.size !== size) {
      setSize(props.size)
    }
  }, [props, color, setColor, size, setSize])

  return (
    <RiNotification2Line 
      color={color} 
      size={size} 
      />
  )
}