import React, { useState, useEffect } from 'react'
import { HiOutlineUserGroup } from 'react-icons/hi'
import { BsFillPeopleFill } from 'react-icons/bs'

export default function FollowersIcon(props) {
  const [color, setColor] = useState('#999999')
  const [size, setSize] = useState('1.4em')

  useEffect(() => {
    if (props.color && props.color !== color) {
      setColor(props.color)
    }
    if (props.size && props.size !== size) {
      setSize(props.size)
    }
    if (props.active && props.activeIconColor) {
      setColor(props.activeIconColor)
    }
    if (!props.active && props.color) {
      setColor(props.color)
    }
  }, [props, color, setColor, size, setSize])

  return (
    <BsFillPeopleFill
      color={color}
      size={size}
    />
  )
}