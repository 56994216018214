import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Nav, Row, Container } from 'react-bootstrap'
import HelpIcon from '../icons/HelpIcon'
import OverviewIcon from '../icons/OverviewIcon'
import InboxIcon from '../utils/InboxIcon'
import ProfileIcon from '../icons/ProfileIcon'
import PaymentsIcon from '../icons/PaymentsIcon'
import SettingsIcon from '../icons/SettingsIcon'
import ContentIcon from '../icons/ContentIcon'
import FollowersIcon from '../icons/FollowersIcon'
import GemIcon from '../icons/GemIcon'
// import UiAvatarIcon from '../archived/UiAvatarIcon'

import UserAvatar from '../utils/UserAvatar'

import useCurrentUser from '../../helpers/store/useCurrentUser'
import CalendarIcon from '../icons/CalendarIcon'

import flags from '../../data/featureFlags'

export default function Menu(props) {
  let history = useHistory()
  let activeIconColor = '#007F34'
  let inactiveIconColor = '#999999'

  // const [currentUser, setCurrentUser] = useState('@BrickCodeBanger')
  const { username, avatar, displayName } = useCurrentUser()
  const [stage, setStage] = useState('overview')

  useEffect(() => {
    if (props.currentKey && props.currentKey !== stage) {
      setStage(props.currentKey)
    }
  }, [props, stage, setStage])

  return (
    <div className="dash-menu">
      {/* <Tab.Container id="left-tabs-example" defaultActiveKey="overview" onSelect={(k) => setKey(k)}> */}
        {/* <Row> */}
          <Col sm={3}>
            <Nav variant="pills" className="flex-column" activeKey={stage}>


            <Container className="py-auto border-bottom pb-3 w-50 ml-0 mt-1" >
                
                <Row className="p-1">
                  {/* <Col className="m-auto"> */}
                    { avatar 
                      ? 
                        ( <UserAvatar avatarKey={avatar.key} setActiveOff post /> ) 
                      : 
                        ( <ProfileIcon size="2.75em" /> ) 
                      }

                  {/* </Col> */}
                  <Col className="p-1 ml-1" id="menu-info">
                    <h5>
                      { displayName }
                    </h5>
                    <div>
                      @{ username }
                    </div>
                  </Col>
                </Row>

              </Container>

              <Nav.Item 
                className="dash-tab" 
                onClick={() => props.setKey("showcase")}
              >
                <Nav.Link 
                  disabled
                  eventKey="showcase" 
                  onClick={() => history.push(`/dashboard`)}
                >
                  <div>
                    <GemIcon 
                      active={props.currentKey == "showcase"} 
                      activeIconColor={activeIconColor} 
                      color={inactiveIconColor}
                    />
                    <span className="menu-span">
                      Feed
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item 
                className="dash-tab" 
                onClick={() => props.setKey("overview")}
              >
                <Nav.Link
                  eventKey="overview" 
                  onClick={() => history.push(`/dashboard/overview`)}
                >
                  <div>
                    <OverviewIcon 
                      active={props.currentKey == "overview"} 
                      activeIconColor={activeIconColor}
                      color={inactiveIconColor}
                    />
                    <span className="menu-span">
                      Dashboard
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item 
                className="dash-tab" 
                onClick={() => props.setKey("calendar")}
              >
                <Nav.Link 
                  disabled
                  eventKey="calendar" 
                  onClick={() => history.push(`/dashboard/calendar`)}
                >
                  <div>
                    <CalendarIcon 
                      active={props.currentKey == "calendar"} 
                      activeIconColor={activeIconColor}
                      color={inactiveIconColor}
                    />
                    <span className="menu-span">
                      Calendar
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item 
                className="dash-tab" 
                onClick={() => props.setKey("my-page")}
              >
                <Nav.Link
                  disabled
                  eventKey="my-page" 
                  onClick={() => history.push(`/dashboard/@${username}`)}
                >
                  <div>
                    <ProfileIcon 
                      active={props.currentKey == "my-page"} 
                      activeIconColor={activeIconColor}
                      color={inactiveIconColor}
                      />
                    <span className="menu-span">
                      Profile
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

              {/* <Nav.Item 
                className="dash-tab" 
                onClick={() => props.setKey("subscriptions")}
              >
                <Nav.Link eventKey="subscriptions" >
                  <div>
                  <ContentIcon/>
                    <span className="menu-span">
                      Subscriptions
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item> */}

              <Nav.Item 
                className="dash-tab" 
                onClick={() => props.setKey("followers")}
              >
                <Nav.Link 
                  eventKey="followers" 
                  onClick={() => history.push(`/dashboard/followers`)}
                  >
                  <div>
                    <FollowersIcon 
                      active={props.currentKey == "followers"} 
                      activeIconColor={activeIconColor}
                      color={inactiveIconColor}
                    />
                    <span className="menu-span">
                      Followers
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item 
                className="dash-tab" 
                onClick={() => props.setKey("inbox")}
              >
                <Nav.Link 
                  eventKey="messages" 
                  onClick={() => history.push(`/dashboard/messages`)}
                >
                  <div>
                    <InboxIcon 
                      active={props.currentKey == "messages"} 
                      activeIconColor={activeIconColor}
                      color={inactiveIconColor}
                    />
                    <span className="menu-span">
                      Messages
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

              {/* <Nav.Item 
                className="dash-tab" 
                onClick={() => props.setKey("content")}
              >
                <Nav.Link eventKey="content" onClick={() => history.push(`/dashboard/content`)}>
                  <div>
                    <ContentIcon/>
                    <span className="menu-span">
                      Library
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item> */}

              {/* <Nav.Item 
                className="dash-tab" 
                onClick={() => props.setKey("payments")}
              >
                <Nav.Link eventKey="payments" onClick={() => history.push(`/dashboard/payments`)}>
                  <div>
                    <PaymentsIcon 
                      active={props.currentKey == "payments"} 
                      activeIconColor={activeIconColor}
                      color={inactiveIconColor} 
                    />
                    <span className="menu-span">
                      Wallet
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item> */}

              <Nav.Item 
                className="dash-tab" 
                onClick={() => props.setKey("settings")}
              >
                <Nav.Link
                  eventKey="settings" 
                  onClick={() => history.push(`/dashboard/settings`)}
                >
                  <div>
                    <SettingsIcon 
                      active={props.currentKey == "settings"} 
                      activeIconColor={activeIconColor}
                      color={inactiveIconColor}
                    />
                    <span className="menu-span">
                      Settings
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>
{/* 
              <Nav.Item 
                className="dash-tab" 
                onClick={() => props.setKey("help")}
              >
                <Nav.Link eventKey="help" onClick={() => history.push(`/@${username}/dashboard/help`)}>
                  <div>
                    <HelpIcon />
                    <span className="menu-span">
                      Get Help
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item> */}

            </Nav>
          </Col>
          {/* <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                
              </Tab.Pane>
            </Tab.Content>
          </Col> */}
        {/* </Row> */}
      {/* </Tab.Container> */}
    </div>
  )
}