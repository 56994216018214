import React, { useState, useEffect } from 'react'


//NOTE: to show number of posts and likes for each creator. OF has it on top of the main image.

export default function ProfileStats(props) {
  const [ stats, setStats ] = useState({posts: 179, likes: "1.5K"})

  useEffect(() => {
    if (props.stats && props.stats !== stats) {
      setStats(props.stats)
    }
  }, [props, stats, setStats])
  
  return (
    <div className="profile-stats" id="trans">
      <span>Posts: {stats.posts}</span>
      <span>Likes: {stats.likes}</span>
    </div>
  )
}