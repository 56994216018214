import React, { useState, useEffect } from 'react'
import { S3Image } from 'aws-amplify-react'
import ActiveNow from '../icons/ActiveNow'
import ProfileIcon from '../icons/ProfileIcon'

function UiAvatarIcon(props) {
  const [ key, setKey ] = useState(null)
  const [ active, setActive ] = useState(true)
  const [ size, setSize ] = useState('6em')
  

  useEffect(() => {
    if (props && props.imgKey !== key) {
      setKey(props.imgKey)
    }
    if (props.size && props.size !== size) {
      setSize(props.size)
    }
    if (props.setActiveOff) {
      setActive(false)
    }
  }, [props, key, size])

  const photoImgTheme = {photoImg: {
    width: size,
    borderRadius: '50%',
    backgroundColor: '#cecbcb'
  }}

  
  return key !== null ? (
    <div id="trans" className="parent-container">
      { active && ( <ActiveNow/> ) }
      { key ? ( <S3Image imgKey={key} theme={photoImgTheme} /> ) : ( <ProfileIcon size={size}/> )  } 
    </div>
  ) : ( null )
}

export default React.memo(UiAvatarIcon)