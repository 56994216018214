import React, { useState, useContext } from 'react';

import ResetButton from './ResetButton';
import FilterSwitch from './FilterSwitch';
import DropdownMenu from './DropdownMenu';
import { DiscoverContext } from '../../helpers/store/DiscoverCreatorContext';

export default function MobileFilterCreators({tags}) {
	const [openFilter, setOpenFilter] = useState(false);
	return (
		<div className="mobile-filter">
			<div className="mobile-filter-hamburger" onClick={() => setOpenFilter(!openFilter)}>{`${openFilter ? "Close" : "Open"} Menu`}</div>
			<div className={`mobile-filter-container ${openFilter ? "is-opened" : "is-closed"}`}>
				<div className="mobile-filter-options">
					<ResetButton />
					<FilterSwitch />
				</div>
				<DropdownMenu
					tags={tags}
				/>
			</div>
			
		</div>
	)
}