import React from 'react'
import LoadingSpinner from '../components/utils/LoadingSpinner'

export default function HelpPage() {
  return (
    // <div>
    //   Help Page
    // </div>
    <LoadingSpinner />
  )
}