import React from 'react'
import Draggable from 'react-draggable'
import { HiDocumentAdd } from 'react-icons/hi'

export default function AddMedia(props) {


  //NOTE: on click, should change new message form to upload content and add description and price form for PPV/free content
  
  return (
    <Draggable>
      <div className="position-absolute d-flex flex-column justify-content-center" id="media-message-form" >
        <div className="text-center">
          <HiDocumentAdd />
        </div>
      </div>
    </Draggable>
  )
}