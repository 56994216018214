import React, { useState, useEffect } from 'react'

export default function DisplayName({username}) {
  const [ name, setName ] = useState(null)

  useEffect(() => {
    if (username && name !== username) {
      setName('@' + username)
    }
  }, [username, name, setName])

  return (
    <div className="display-name">
      { name }
    </div>
  )
}