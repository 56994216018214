import { useState, useEffect } from 'react'
import { gql } from '@apollo/client'
import { updateUser } from '../graphql/mutations'
import { userByEmail } from '../graphql/queries'
import useApolloClient from './useApolloClient'
import { Auth } from 'aws-amplify'

//LINK: https://hasura.io/learn/graphql/react/subscriptions/
//LINK: https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilityState

export default function UserActivityWrapper() {

  const [onlineIndicator, setOnlineIndicator] = useState(0)
  const client = useApolloClient()

  const updateLastOnline = async () => {
    if ( document.visibilityState === "visible" ) {
      try {

        const authedUser = await Auth.currentAuthenticatedUser()
        const apolloData = await client.query({
          query: gql(userByEmail),
          fetchPolicy: 'cache-only',
          variables: {
            email: authedUser.attributes.email
          }
        })
        
        const currentTime = new Date().toISOString()
        console.log(currentTime)

        const res = await client.mutate({
          mutation: gql(updateUser),
          variables: {
            input: {
              id: apolloData.data.userByEmail.items[0].id,
              last_online: currentTime
            }
          }
        })
        console.log(res)
      } catch (err) { console.log(err) }
    } else { console.log("doc not in focus") }
  }

  //NOTE: Will update last_online every 60 seconds
  useEffect(() => {
    updateLastOnline()
    setOnlineIndicator(setInterval(() => updateLastOnline(), 60000))

    return () => {
      clearInterval(onlineIndicator)
    }
  }, [])

  return null
}