import React, { useState, useEffect, useContext, Fragment } from 'react';

import CreatorResults from './CreatorResults';
import {defaultCreators} from './placeholders';
import { DiscoverContext } from '../../helpers/store/DiscoverCreatorContext';

export default function ResultsContainer() {

	const { state: { filter } } = useContext(DiscoverContext);
	
	const { add, rem, filterType } = filter;
	const featuredList= defaultCreators(12);
	const newList = defaultCreators(6);
	const suggestedList = defaultCreators(6);
	const [results, setResults] = useState(defaultCreators(200));
	const [filteredResults, setFilteredResults] = useState(null);
	const [isFiltered, setIsFiltered] = useState(add.length + rem.length > 0);

	useEffect(() => {
		filterType ? allFilter() : anyFilter();
	}, [add, rem, isFiltered, filterType])

	const defaultContainers = () => {
		return (
			<Fragment>
				<CreatorResults title="Featured Creators" width="p100" key="featured" results={featuredList} />
				<CreatorResults title="New Creators" width="p50" key="new" results={newList} />
				<CreatorResults title="Suggested Creators" width="p50" key="suggested" results={suggestedList} />
			</Fragment>
		)
	}

	const filterResults = () => {
		return (
			<CreatorResults
				title="filterResults"
				width="p100"
				key="results"
				results={filteredResults}
			/>
		)
	}

	const anyFilter = () => {
		if(add.length + rem.length > 0) {
			let arResults = (add.length > 0) ? results.filter(r => add.some(t => r.tags.includes(t.toUpperCase()))) : results;
			if(rem.length > 0) arResults = arResults.filter(r => !r.tags.some(t => rem.includes(t.toLowerCase())));
			setFilteredResults(arResults);
			setIsFiltered(add.length + rem.length > 0);
		} else {
			setIsFiltered(false);
		}
	}

	const allFilter = () => {
		if(add.length + rem.length > 0) {
			let arResults = (add.length > 0) ? results.filter(r => add.every(t => r.tags.includes(t.toUpperCase()))) : results;
			if(rem.length > 0) arResults = arResults.filter(r => !r.tags.some(t => rem.includes(t.toLowerCase())));
			setFilteredResults(arResults);
			setIsFiltered(add.length + rem.length > 0);
		} else {
			setIsFiltered(false);
		}	
	}

	const returnContainers = () => (isFiltered) ? filterResults() : defaultContainers();// : defaultContainers() // change FilterResults to CreatorResults accordingly
	
	return (
		<div className="results-container">
			{ returnContainers() }
		</div>
	);
}
