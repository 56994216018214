import React from 'react'
import UpcomingSessionsTile from './dashboard-tiles/UpcomingSessionsTile'
import CalendarTile from './dashboard-tiles/CalendarTile'
import MarketMoversTile from './dashboard-tiles/MarketMoversTile'
import LearnVidsTile from './dashboard-tiles/LearnVidsTile'

export default function DashOverview() {

  return (
    <div className="p-4">

      <div className="d-flex p-2 flex-wrap">
        <LearnVidsTile />
        {/* <UpcomingSessionsTile /> */}
        {/* <CalendarTile /> */}
        {/* <MarketMoversTile /> */}
      </div>

    </div>
  )
}