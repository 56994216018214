import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'

export default function EmailSignUpVerification(props) {

  const [ verificationCode, setVerificationCode ] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    console.log(verificationCode)
    props.handleSubmit(verificationCode)
  }

  return (
    <div id="register-form" className="d-flex flex-column top-bumper">
      <Form onSubmit={e => handleSubmit(e)}>

        <Form.Group controlId="form-verification-code">
          <Form.Label>Email Verification Code</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="######" 
            value={verificationCode}
            minLength="6"
            maxLength="6"
            required
            onChange={e => setVerificationCode(e.target.value)}
          />
          {/* <Form.Text className="text-muted">
            Email must be verified before your account can be created.
          </Form.Text> */}
        </Form.Group>

        <Form.Text className="text-muted" id="form-link" onClick={() => props.resendConfirmationCode()}>
          Didn't receive a verification code? Click to resend.
        </Form.Text>

        <Button type="submit" id=" button">
          Submit
        </Button>

      </Form>

    </div>
  )
}