import React, { createRef } from 'react'
import ChangeAvatar from '../components/user-settings/ChangeAvatar'
import EditBio from '../components/user-settings/EditBio'
import EditSubPrice from '../components/user-settings/EditSubPrice'
import EditDisplayName from '../components/user-settings/EditDisplayName'
import TagSaleTrekker from '../components/user-settings/TagSaleTrekker'
import ChangeBannerPic from '../components/user-settings/ChangeBannerPic'
import SetDiscoverable from '../components/user-settings/SetDiscoverable'

import LogoutIcon from '../components/icons/LogoutIcon'
import useDispatchers from '../helpers/store/dispatchers'

import { Accordion, Card, Button } from 'react-bootstrap'
import { Breakpoint } from 'react-socks'

export default function SettingsPage() {

  const { logout } = useDispatchers()
  const wrapper = createRef()

  return (
    <div className="settings-page">
      
      <Breakpoint m down>
        <div className="header center">
          User Settings
        </div>
      </Breakpoint>

      <Breakpoint l up>
        <div className="settings-header">
          User Settings
        </div>
      </Breakpoint>

      <Accordion id="accordion" className="accordion-flush" ref={wrapper}>

        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            Edit Avatar
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <ChangeAvatar />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            Edit Display Name
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <EditDisplayName/>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        {/* <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            Edit Bio
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <EditBio/>
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}
{/* 
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            Edit Subscription Price
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <EditSubPrice/>
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}

        {/* <Card>
          <Accordion.Toggle as={Card.Header} eventKey="4">
            Set User Tags
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              <TagSaleTrekker/>
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}

        {/* <Card>
          <Accordion.Toggle as={Card.Header} eventKey="5">
              Set Discoverable
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              <SetDiscoverable />
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}

        {/* <Card>
          <Accordion.Toggle as={Card.Header} eventKey="6">
            Change Banner Pic
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <Card.Body className="p-0 m-0 overflow-visible h-auto">
              <ChangeBannerPic />
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}
      </Accordion>

      
      <div className="p-4">
        <div onClick={() => logout()}>
          <LogoutIcon />
          Log Out
        </div>
        <div className="p-4">
          <Button>Save Changes</Button>
        </div>
      </div>

    </div>
  )
}