import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Drawer } from 'rsuite'
import { Nav, Container, Col, Row } from 'react-bootstrap'
import HelpIcon from '../icons/HelpIcon'
import OverviewIcon from '../icons/OverviewIcon'
// import InboxIcon from '../icons/InboxIcon'
import ProfileIcon from '../icons/ProfileIcon'
// import PaymentsIcon from '../icons/PaymentsIcon'
import SettingsIcon from '../icons/SettingsIcon'
// import ContentIcon from '../icons/ContentIcon'
// import LogoutIcon from '../icons/LogoutIcon'
import FollowersIcon from '../icons/FollowersIcon'
import GemIcon from '../icons/GemIcon'
// import FeatureIcon from '../icons/FeatureIcon'
import DiscoverIcon from '../icons/DiscoverIcon'
// import UiAvatarIcon from '../utils/UiAvatarIcon'
import VideoIcon from '../icons/VideoIcon'
// import StoreIcon from '../icons/StoreIcon'

import UserAvatar from '../utils/UserAvatar'

import useCurrentUser from '../../helpers/store/useCurrentUser'
// import { Auth } from 'aws-amplify'
import useDispatchers from '../../helpers/store/dispatchers'
import CalendarIcon from '../icons/CalendarIcon'

export default function MobileMenu(props) {
  let history = useHistory()

  const { displayName, username, avatar, last_online } = useCurrentUser()
  // console.log(avatar)
  // const { logout } = useDispatchers()

  const [ show, setShow ] = useState(false)
  // const [currentUser, setCurrentUser] = useState('@BrickCodeBanger')
  const [ activeIcon, setActiveIcon ] = useState(props.currentKey)
  
  //NOTE: need to change color prop on icons if icon is activeicon. 

  useEffect(() => {
    if (props.toggleDrawer !== show) {
      setShow(props.toggleDrawer)
    } 
  }, [props, show, setShow])

  useEffect(() => {
    if (props.currentKey !== activeIcon) {
      setActiveIcon(props.currentKey)
    }
  }, [props, activeIcon, setActiveIcon])

  const iconColor = (stage) => activeIcon === stage ? '#007F34' : '#999999'

  const activeLinkColor = stage => activeIcon === stage ? 'menu-span-active' : 'menu-span'
    
  const closeDrawer = drawer => {
    props.setKey(drawer)
    setShow(false)
    props.setToggleDrawer(false)
  }

  return (
    <div>
      <Drawer
          size="xs"
          placement="right"
          show={show}
          style={{width: '230px', background: '#2B2D2F'}}
          backdropClassName="mobile-menu-backdrop"
          // className="drawer-menu"
          
          onHide={() => { setShow(false); props.setToggleDrawer(false) }}
        >
          {/* <Drawer.Header className="drawer-menu">
            <Drawer.Title>Creator Name</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body className="drawer-menu"> */}
            <div className="drawer-menu">
            <Nav defaultActiveKey="/" activeKey="showcase" className="flex-column">

              <Container className="mx-auto border-bottom pb-3" >
                
                <Row className="p-1 pl-2">
                  {/* <Col className="m-auto"> */}
                    { avatar && <UserAvatar avatarKey={avatar.key} size={'3.7em'} setActiveOff post/> }
                  {/* </Col> */}
                  <Col className="p-1 ml-2">
                    <h5>
                      { displayName }
                    </h5>
                    <div>
                      @{ username }
                    </div>
                  </Col>
                </Row>

              </Container>
            {/* <hr/> */}

            <Nav.Item 
                className="dash-tab pt-3" 
                onClick={() => closeDrawer("showcase")}
              >
                <Nav.Link eventKey="showcase" onClick={() => history.push(`/dashboard`)}>
                  <div>
                    <GemIcon
                      color={iconColor("showcase")}
                    />
                    <span className={activeLinkColor("showcase")}>
                      Feed
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

            <Nav.Item 
                className="dash-tab" 
                onClick={() => closeDrawer("overview")}
              >
                <Nav.Link eventKey="overview" onClick={() => history.push(`/dashboard/overview`)}>
                  <div>
                    <OverviewIcon
                      color={iconColor("overview")}
                    />
                    <span className={activeLinkColor("overview")}>
                      Dashboard
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item 
                className="dash-tab" 
                onClick={() => closeDrawer("calendar")}
              >
                <Nav.Link eventKey="calendar" onClick={() => history.push(`/dashboard/calendar`)}>
                  <div>
                    <CalendarIcon
                      color={iconColor("calendar")}
                    />
                    <span className={activeLinkColor("Calendar")}>
                      Calendar
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item 
                className="dash-tab" 
                onClick={() => closeDrawer("my-page")}
              >
                <Nav.Link eventKey="my-page" onClick={() => history.push(`/dashboard/@${username}`)}>
                  <div>
                    <ProfileIcon
                      color={iconColor("my-page")}
                    />
                    <span className={activeLinkColor("my-page")}>
                      Profile
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item 
                className="dash-tab" 
                onClick={() => closeDrawer("followers")}
              >
                <Nav.Link eventKey="followers" onClick={() => history.push(`/dashboard/followers`)}>
                  <div>
                    <FollowersIcon
                      color={iconColor("followers")}
                    />
                    <span className={activeLinkColor("followers")}>
                      Followers
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item 
                className="dash-tab" 
                onClick={() => closeDrawer("content")}
              >
                <Nav.Link eventKey="content" onClick={() => history.push(`/dashboard/content`)}>
                  <div>
                    <VideoIcon
                      color={iconColor("content")}
                    />
                    <span className={activeLinkColor("content")}>
                      Videos
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item 
                className="dash-tab" 
                onClick={() => closeDrawer("discover")}
              >
                <Nav.Link eventKey="discover" onClick={() => history.push('/discover')}>
                  <div>
                    <DiscoverIcon
                      color={iconColor("discover")}
                    />
                    <span className={activeLinkColor("discover")}>
                      Search
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

              {/* <Nav.Item 
                className="dash-tab" 
                onClick={() => closeDrawer("cam")}
              >
                <Nav.Link eventKey="cam" >
                  <div>
                    <VideoIcon
                      color={iconColor("cam")}
                    />
                    <span className={activeLinkColor("cam")}>
                      CamMe
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item 
                className="dash-tab" 
                onClick={() => closeDrawer("store")}
              >
                <Nav.Link eventKey="store" >
                  <div>
                    <StoreIcon
                      color={iconColor("store")}
                    />
                    <span className={activeLinkColor("store")}>
                      BuyMe
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item> */}

              {/* <Nav.Item 
                className="dash-tab" 
                onClick={() => closeDrawer("community")}
              >
                <Nav.Link eventKey="community" >
                  <div>
                    <DiscoverIcon
                      color={iconColor("community")}
                    />
                    <span className={activeLinkColor("community")}>
                      Creator Community
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item> */}

              {/* <Nav.Item 
                className="dash-tab" 
                onClick={() => closeDrawer("payments")}
              >
                <Nav.Link eventKey="payments" onClick={() => history.push(`/dashboard/payments`)}>
                  <div>
                    <PaymentsIcon
                      color={iconColor("payments")}
                    />
                    <span className={activeLinkColor("payments")}>
                      Wallet
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item> */}

              <Nav.Item 
                className="dash-tab" 
                onClick={() => closeDrawer("settings")}
              >
                <Nav.Link eventKey="settings" onClick={() => history.push(`/dashboard/settings`)}>
                  <div>
                    <SettingsIcon
                      color={iconColor("settings")}
                    />
                    <span className={activeLinkColor("settings")}>
                      Settings
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item 
                className="dash-tab mt-5" 
                id="mobile-drawer-last-link"
                onClick={() => closeDrawer("help")}
              >
                <Nav.Link eventKey="help" onClick={() => history.push(`/dashboard/help`)}>
                  <div>
                    <HelpIcon 
                      color={iconColor("help")}
                    />
                    <span className={activeLinkColor("help")}>
                      Get Help
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item>

              {/* <Nav.Item 
                className="dash-tab mt-5" 
                id="mobile-drawer-last-link"
              >
                <Nav.Link eventKey="logout" onClick={() => logout()} >
                  <div>
                    <LogoutIcon 
                      color={iconColor("logout")}
                    />
                    <span className={activeLinkColor("logout")}>
                      Log Out
                    </span>
                  </div>
                </Nav.Link>
              </Nav.Item> */}
              
            </Nav>
            </div>
          {/* </Drawer.Body>
          <Drawer.Footer>
            
          </Drawer.Footer> */}
        </Drawer>
    </div>
  )
}