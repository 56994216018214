import React, { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import ProfilePost from './post/Post'
import { Container } from 'react-bootstrap'

//NOTE: feed-container for profile should fetch user. Post component should not fetch.


export default function FeedContainer(props) {
  const [ posts, setPosts ] = useState([])
  const [ loaded, setLoaded ] = useState(false)
  // const [ nextToken, setNextToken ] = useState(props.nextToken)

  // const [ isProfileUser, setIsProfileUser ] = useState(false)
  //NOTE: post = {type: "", content: "", text: "", datetime: "", user: {}}
  
  useEffect(() => {
    if (props.posts && props.posts !== posts) {
      // console.log(props.posts.items)
      setPosts(props.posts)
      // if ( props.nextToken && props.nextToken !== nextToken ) {
      //   setNextToken(props.nextToken)
      // } 
      
    }
    return () => { setLoaded(true) }
  }, [props, posts, loaded])

  
  return (
    <div className="feed-container">
      { loaded && 

        posts.length > 0 ? posts.map(p => 
              <ProfilePost 
                key={uuid()} 
                post={p} 
                postUser={props.profileUser} 
                currentUser={props.currentUser}

                />
            ) 
          : 
            !loaded ? 
              ( <Container> Loading... </Container>)
                :
              ( <Container> No posts yet </Container> )
        
      }
      
      { props.nextToken && (
        <div 
          className="paginate mx-auto"
          onClick={() => props.loadMorePosts(props.nextToken)}  
        >
          Load Older Posts
        </div>
      )}

    </div>
  ) 

}