import { useState } from 'react'
import { gql } from '@apollo/client'
import { createConversation, createConversationLinkConnection } from '../graphql/mutations'
import { searchConversations } from '../graphql/queries'
import { getUserByUsernameLite } from '../graphql/custom-queries'
import useApolloClient from './useApolloClient'

//NOTE: Needs to check if conversation exists in currentUser conversations and return if exists
// If no conversation exists, needs to create new conversation and return
// Should create new conversation only when first message is sent

//LINK: https://dev.to/dabit3/building-chatt---a-real-time-multi-user-graphql-chat-app-3jik

export default function useFindOrCreateConversation() {

  const client = useApolloClient()
  // let membersString
  let convo
  let convoLink
  let recipient

  // const membersStringGenerator = () => {
  //   const members = [currentUser.username, recipientUsername]
  //   const membersArray = members.sort((a, b) => a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'}))
  //   return `${membersArray[0]}${membersArray[1]}`
  // }

  const fetchRecipientData = async (username) => {
    try {
      let res = client.query({
        query: gql(getUserByUsernameLite),
        variables: {
          username: username
        }
      })
      console.log(res)
      recipient = res.data
    } catch (err) { console.log(err) }
  }

  const findConversation = async (convoID) => {
    try {
      let res = client.query({
        query: gql(searchConversations),
        variables: {
          ID: convoID
        }
      })
      console.log(res)
      convo = res.data.searchConversations
    } catch (err) {
      console.log(err)
    }
  }

  const newConversation = async (currentUserID, recipientID) => {
    try {
      let res = await client.mutate({
        mutation: gql(createConversation),
        variables: {
          input: {
            members: [
              { userID: currentUserID }, 
              { userID: recipientID }
            ]
          }
        }
      })
      // console.log(res)
      return res.data.createConversation
    } catch (err) {
      // console.log(err)
      return err
    }
  }

  const createUserConversationLinkConnection = async (currentUserID, recipientID, conversationID) => {
    
    // (async () => {
      try {
        const result = await client.mutate({
          mutation: gql(createConversationLinkConnection),
          variables: {
            input: {
              userID: currentUserID,
              conversationID: conversationID
            }
          }
        })
        const otherResult = await client.mutate({
          mutation: gql(createConversationLinkConnection),
          variables: {
            input: {
              userID: recipientID,
              conversationID: conversationID
            }
          }
        })
        console.log(result, otherResult)
        return { result, otherResult }
      } catch (err) { console.log(err) }
    // }
  // )()
  }

  //NOTE: function creates the new conversation and the link connections to attach to each User
  const createConversationConnections = async (currentUserID, recipientID) => {
    try {
      let convoRes = await newConversation(currentUserID, recipientID)
      let connectRes = await createUserConversationLinkConnection(currentUserID, recipientID, convoRes.id)
      console.log(convoRes, connectRes)
      return convoRes
    } catch (err) { console.log(err) }
  }

  return { createConversationConnections }
}