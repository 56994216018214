import React, { useContext } from 'react';

import { DiscoverContext } from '../../helpers/store/DiscoverCreatorContext';

export default function Tag({ tag }) {
	const { state: { filter }, addFilter, remFilter } = useContext(DiscoverContext);
	const { add, rem } = filter;
	const [added, removed] = [add.includes(tag), rem.includes(tag)];
	const tagText = tag.toUpperCase();
	const clickAdd = () => {
		removed && remFilter(tag);
		addFilter(tag);
	}
	const clickRem = () => {
		added && addFilter(tag);
		remFilter(tag);
	}

	//NOTE: remove tooltip and dash
	//NOTE: Why do we need the dash? 
	// If we arent including it in our searches specifically, why do we need to exclude it?
	return (
		<div className="filter-group">
			{/* <div
				className="remove-tag hastt mr-1"
				onClick={clickRem}
			>
				-
				<span className="tooltiptext">{removed ? "Unhide results" : "Hide results"}</span>
			</div> */}
			<div
				className={`${added ? "added" : ""} ${removed ? "removed" : ""} tag-name hastt`}
				onClick={clickAdd}
			>
				{tagText}
				<span className="tooltiptext">{added ? "Remove filter" : "Add filter"}</span>
			</div>
		
		</div>
	)
}
