import React, { useContext } from 'react';
import { Button } from 'react-bootstrap'

import { DiscoverContext } from '../../helpers/store/DiscoverCreatorContext';

export default function ResetButton({resetFilter}) {
	const { reset } = useContext(DiscoverContext);
	return (
		<div className="p-2">
			<Button 
				size="sm" 
				variant="secondary"
				className="rounded-pill reset-button" 
				onClick={ () => reset() }
				>
					Clear
				</Button>
		</div>
	)
}