import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import LogInForm from '../components/user-info/LogInForm'
import ForgotPasswordForm from '../components/user-info/ForgotPasswordForm'
import ResetPasswordForm from '../components/user-info/ResetPasswordForm'

import useDispatchers from '../helpers/store/dispatchers'
import useCurrentUser from '../helpers/store/useCurrentUser'

import { API, Auth } from 'aws-amplify'
import { userByEmail } from '../graphql/queries'

export default function LogInPage(props) {
  const currentUser = useCurrentUser()
  const { login } = useDispatchers()
  let history = useHistory()

  const [ errors, setErrors ] = useState(null)
  const [ forgotPassword, setForgotPassword ] = useState(props.forgotPassword ? true : false)
  const [ resetPassword, setResetPassword ] = useState(false)
  const [ emailInput, setEmailInput ] = useState(null)
  const [ rememberDevice, setRememberDevice ] = useState(false)

  const errorCodes = err => {
    switch (err.code) {
      case "UserNotConfirmedException":
        console.log("user not confirmed")
        setErrors("Account not confirmed. Please enter verification code to confirm your account.")
        return
      case "UserNotFoundException":
        console.log("user not found")
        setErrors("User not found.")
        return
      case "NotAuthorizedException":
        console.log("incorrect email or password")
        setErrors("Incorrect email or password.")
        return
      case "LimitExceededException":
        console.log("LimitExceededException")
        setErrors("Attempt limit exceeded, try again later.")
        return
      default:
        console.log(err)
        setErrors(err.message)
        return
    }
  }

  const handleForgotPassword = async email => {
    setEmailInput(email)
    try {
      let res = await Auth.forgotPassword(email)
      console.log(res)
      setErrors(null)
      setResetPassword(true)
    } catch (err) {
      console.log(err)
      errorCodes(err)
    }
  }

  const handleResetPassword = async (code, pw) => {
    try {
      let res = await Auth.forgotPasswordSubmit(emailInput, code, pw)
      console.log(res)
    } catch (err) {
      console.log(err)
    }
  }

  const handleLogin = async data => {
      try {
        const authedUser = await Auth.signIn(data.email, data.password)
        console.log(authedUser)
        // const rememberMe = await Auth.rememberDevice()
        // console.log(rememberMe)
        const returnedUser = await API.graphql({ query: userByEmail, variables: {email: data.email} })
        // console.log(returnedUser)
        login(returnedUser)
      } catch(error) {
        console.log(error)
        errorCodes(error)
      }
  }

  const currentAuthForm = () => {
    if ( !forgotPassword && !resetPassword ) {
      return (
        <LogInForm
          handleLogin={handleLogin}
          errors={errors}
          forgotPassword={setForgotPassword}
          rememberDevice={setRememberDevice}
        />
      )
    } else if ( forgotPassword && !resetPassword ) {
      return (
        <ForgotPasswordForm 
          handleSubmit={handleForgotPassword}
          errors={errors}
          forgotPassword={setForgotPassword}
        />
      )
    } else if ( forgotPassword && resetPassword ) {
      return (
        <ResetPasswordForm 
          handleSubmit={handleResetPassword}
        />
      )
    }
  }

  useEffect(() => {
    if (currentUser && currentUser.isLoggedIn) {
      history.push(`/dashboard`)
    }
  })

  return (
    <div className="reg-div d-flex flex-row vh-100 justify-content-center">
      { currentAuthForm() }
    </div>
  )
}