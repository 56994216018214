

  export const fetchPostsByUser = /* GraphQL */ `
    query fetchPostsByUser(
      $userID: ID
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelPostFilterInput
      $limit: Int
      $nextToken: String
    ) {
      fetchPostsByUser(
        userID: $userID
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          media {
            bucket
            region
            key
            type
            userID
          }
          caption
          likes {
            items {
            id
            postID
            userID
            createdAt
            updatedAt
          }
            nextToken
          }
          userID
          comments {
            items {
            id
            text
            userID
            postID
            createdAt
            updatedAt
          }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
`;

export const getUserAndConversations = /* GraphQL */`
  query getUserAndConversations(
    $id: ID!
    ) {
    getUser(id: $id) {
      id
      username
      conversations(limit: 100) {
        items {
          id
          conversation {
            id
            members {
              userID
            }
          }
        }
      }
    }
  }
`

export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: AWSEmail
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
        items {
          id
          follower_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      favorites {
        items {
          id
          user_id
          favorite_user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          notification_type
          user_id
          message
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          items {
            id
            userID
            conversationID
            createdAt
            updatedAt
            conversation {
            id
              members {
                userID
              }
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const userByUsername = /* GraphQL */ `
  query UserByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        full_name
        email
        description
        username
        display_name
        bday
        bio
        avatar {
          bucket
          region
          key
          type
          userID
        }
        current_sub_price {
          price
        }
        discoverable
        last_online
        posts {
          nextToken
        }
        content {
          bucket
          region
          key
          type
          userID
        }
        tags {
          nextToken
        }
        banner {
          default_theme
        }
        followers {
        items {
          id
          follower_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          following_userID
          createdAt
          updatedAt
        }
        nextToken
      }
      favorites {
        items {
          id
          user_id
          favorite_user_id
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          notification_type
          user_id
          message
          seen
          createdAt
          updatedAt
        }
        nextToken
      }
        current_subscriptions {
          nextToken
        }
        current_subscribers {
          nextToken
        }
        conversations {
          items {
            id
            userID
            conversationID
            createdAt
            updatedAt
            conversation {
              id
              members {
                userID
              }
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getUserByUsernameLite = /* GraphQL */ `
  query UserByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        display_name
        avatar {
          bucket
          region
          key
          type
          userID
        }
        last_online
      }
      nextToken
    }
  }
`;

export const getUserLite = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      display_name
      bday
      avatar {
        bucket
        region
        key
        type
        userID
      }
      last_online
    }
  }
`;
