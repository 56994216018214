
import { createContext } from 'react'

//NOTE: need to create user object in initial state object to hold more things in global state store
export const userState = { 
  user: {
    username: null,
    email: null,
    password: false,
    isLoggedIn: false,
    displayName: null,
    bio: null,
    sub_price: null,
    banner: null,
    last_online: null,
    discoverable: false,
    conversations: [],
    followers: [],
    following: [],
    favorites: [],
    notifications: []
  }
}

//NOTE: use 'store' as identifier rather than 'state'?
export const UserContext = createContext({ state: userState, dispatch: null })


//NOTE: might create functions to handle each case for reducer? 
export const userReducer = (userState, action) => {

  const { username, 
          email, 
          id, 
          avatar = null, 
          display_name = null, 
          bio = null, 
          sub_price = null,
          banner = null,
          last_online = null,
          discoverable = false,
          conversations = [],
          followers = [],
          following = [],
          favorites = [],
          notifications = []
        } = action.payload || userState
  // console.log(action.payload)
  // debugger
  
  switch(action.type) {
    case 'LOGIN':
      // const { username, email, id, avatar } = action.payload.data.userByEmail.items[0]
      // debugger
      // console.log(newUser)
      return {
        ...userState,
        user: {
          ...userState.user,
          username: username,
          email: email,
          id: id,
          avatar: avatar, 
          displayName: display_name,
          discoverable: discoverable,
          bio: bio,
          banner: banner,
          sub_price: sub_price,
          conversations: conversations,
          followers: followers,
          following: following,
          favorites: favorites,
          notifications: notifications,
          last_online: last_online,
          isLoggedIn: true
        }
      }
    case 'ADD_NEW_USER':
      // const { username, email, id, avatar } = action.payload.data.userByEmail.items[0]
      // debugger
      return {
        ...userState,
        user: {
          username: username,
          email: email,
          id: id,
          avatar: avatar,
          displayName: display_name,
          discoverable: discoverable,
          bio: bio,
          banner: banner,
          conversations: conversations,
          followers: followers,
          following: following,
          favorites: favorites,
          notifications: notifications,
          isLoggedIn: true,
          last_online: last_online,
          sub_price: null
        }
      }

    // case 'UPDATE_USER_AVATAR':
    //   // debugger
    //   return {
    //     ...userState,
    //     user: {
    //       ...userState.user,
    //       avatarURL: action.payload
    //     }
    //   }

      //NOTE: Needs to figure out which key value pair to update
    case 'UPDATE_USER_INFO':
      return {
        ...userState,
        user: {
          ...userState.user,
          username: username,
          email: email,
          id: id,
          avatar: avatar, 
          displayName: display_name,
          discoverable: discoverable,
          bio: bio,
          banner: banner,
          sub_price: sub_price,
          conversations: conversations,
          followers: followers,
          following: following,
          favorites: favorites,
          notifications: notifications,
          last_online: last_online,
          isLoggedIn: true
        }
      }

    case 'LOGOUT':
      return {
        user: {
          username: null,
          email: null,
          isLoggedIn: false
        }
      }

    default:
      return userState
  }
}




