
export function dataURLtoBlob(dataUrl) {
  // var dataUrl = canvas.toDataURL("image/jpeg");
    var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});

}

export function blobToDataURL(blob) {
    // return URL.creaconst reader = new FileReader();
    // debugger
    let reader = new FileReader()
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        //   debugger
        resolve(reader.result)
      };
    })
}

export function blobToBase64(blob, callback) {
    var reader = new FileReader();
    reader.onload = function() {
        var dataUrl = reader.result
        var base64 = dataUrl
        callback(base64)
        return
    };
    reader.readAsDataURL(blob);
};

export function imgToBase64(img) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
 
    img.crossOrigin = 'anonymous';
  
    ctx.drawImage(img, 0, 0);
  
    return canvas.toDataURL();
  }

  export const formatDateTime = datetime => {
    let awsDatetime = new Date(datetime)
    return awsDatetime.toLocaleString()
  }

  export const timeSince = date => {
    const intervals = [
      { label: 'year', seconds: 31536000 },
      { label: 'month', seconds: 2592000 },
      { label: 'day', seconds: 86400 },
      { label: 'hour', seconds: 3600 },
      { label: 'minute', seconds: 60 },
      { label: 'second', seconds: 1 }
    ]
    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long',  
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit' 
    }
    const newDate = new Date(date)
    const seconds = Math.floor((Date.now() - newDate.getTime()) / 1000)

    if (seconds >= 31536000) {
      return newDate.toLocaleTimeString([], options)
    } else if (seconds >= 86400) {
      delete options.year
      return newDate.toLocaleTimeString([], options)
    } else {
      const interval = intervals.find(i => i.seconds < seconds);
      const count = Math.floor(seconds / interval.seconds);
      return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
    }
  }