import React, { useState } from 'react'
import SchedulePicker from './SchedulePicker'

export default function CalendarMain() {

  //NOTE: If you look at NewPostForm you can see how I was passing props into SchedulePicker to capture the selected datetime
  // I was using rsuite for this component, I know they have a larger calendar with colored dots and whatnot also.

  const [ scheduledDateTime, setScheduledDateTime ] = useState(null)

  return (
    <div>

      <SchedulePicker/>

    </div>
  )
}