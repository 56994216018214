
export const userTagsData = {
  "african": false,
  "alt": false,
  "anal": false,
  "arab": false,
  "asian": false,
  "BBW": false,
  "big ass": false,
  "big tits": false,
  "bisexual": false,
  "british": false,
  "bondage": false,
  "brunette": false,
  "blonde": false,
  "cake-sitting": false,
  "cosplay": false,
  "feet": false,
  "female": false,
  "french": false,
  "gay": false,
  "german": false,
  "hardcore": false,
  "indian": false,
  "lesbian": false,
  "male": false,
  "mature": false,
  "MILF": false,
  "nerdy": false,
  "pierced": false,
  "plus size": false,
  "redhead": false,
  "russian": false,
  "small tits": false,
  "squirter": false,
  "strap on": false,
  "tattooed": false,
  "they/them": false,
  "transgender": false
}

export const userTagToEnum = {
  "african": 'AFRICAN',
  "alt": 'ALT',
  "anal": 'ANAL',
  "arab": 'ARAB',
  "asian": 'ASIAN',
  "BBW": 'BBW',
  "big ass": 'BIGASS',
  "big tits": 'BIGTITS',
  "bisexual": 'BISEXUAL',
  "british": 'BRITISH',
  "bondage": 'BONDAGE',
  "brunette": 'BRUNETTE',
  "blonde": 'BLONDE',
  "cosplay": 'COSPLAY',
  "feet": 'FEET',
  "female": 'FEMALE',
  "french": 'FRENCH',
  "gay": 'GAY',
  "german": 'GERMAN',
  "hardcore": 'HARDCORE',
  "indian": 'INDIAN',
  "lesbian": 'LESBIAN',
  "male": 'MALE',
  "mature": 'MATURE',
  "MILF": 'MILF',
  "nerdy": 'NERDY',
  "pierced": 'PIERCED',
  "plus size": 'PLUSSIZE',
  "redhead": 'REDHEAD',
  "russian": 'RUSSIAN',
  "small tits": 'SMALLTITS',
  "squirter": 'SQUIRTER',
  "strap on": 'STRAPON',
  "tattooed": 'TATTOOED',
  "they/them": 'THEYTHEM',
  "transgender": 'TRANSGENDER'
}
