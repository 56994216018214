import React from 'react';

import Tag from './Tag';

export default function DropdownMenu({tags}) {
	return (
		<div className="mobile-dropdown-menu">
			{
				tags.map( tag => {
				  return (
				    <Tag
				      tag={tag}
				      key={tag}
				    />
				  )
				})
			}			
		</div>		
	)
}