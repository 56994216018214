import React, { useState, useEffect } from 'react'
import { Form, Button, Container, Row } from 'react-bootstrap'
import useCurrentUser from '../../helpers/store/useCurrentUser'
import useDispatchers from '../../helpers/store/dispatchers'

//TODO: Needs to set validations for what a display name can or cannot be

export default function EditDisplayName() {
  const [ displayName, setDisplayName ] = useState('')
  const { id } = useCurrentUser()
  const { updateUserInfo } = useDispatchers()

  const handleSubmit = e => {
    e.preventDefault()
    console.log(displayName)
    let nameData = {id: id, display_name: displayName}
    updateUserInfo(nameData)
  }

  return (
    <Container>

      <Form onSubmit={e => handleSubmit(e)} className="p-0" id="register-form">
      
        <div className="d-inline-flex">

          <Form.Group controlId="form-bio">
            <Form.Control
              type="text"
              minLength="1"
              maxLength="15"
              value={displayName}
              size="sm"
              onChange={e => setDisplayName(e.target.value)}
            />
          </Form.Group>

          <div>
            <Button type="submit" id="button" size="sm">
              Update
            </Button>
          </div>
          
        </div>
      
      </Form>
      
    </Container>
  )
}