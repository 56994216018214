import React, { useState, useEffect } from 'react'
import Menu from '../components/dashboard/Menu'
import DashStage from '../components/dashboard/DashStage'
import UserFooterNabber from '../components/nav/UserFooterNabber'
import { Breakpoint } from 'react-socks'
// import useCurrentUser from '../helpers/store/useCurrentUser'
// import {useCompareUserToRoute} from '../helpers/useCompareUserToRoute'
import { withAuthenticator } from '@aws-amplify/ui-react'
import NotAuthorizedPage from './NotAuthorizedPage'
import { useParams } from 'react-router-dom'

function CreatorDashPage(props) {
  
  //NOTE: checks if user in route matches current authenticated user
  // const userToRoute = useCompareUserToRoute()
  // console.log(userToRoute)
 
  const [key, setKey] = useState("overview")
  const [permissions, setPermissions] = useState(true)
  const { stageKey, currentUserID } = useParams()

  // useEffect(() => {
  //   if (!userToRoute) {
  //     setPermissions(false)
  //     console.log("does not match")
  //   } else {
  //     setPermissions(true)
  //   }
  // }, [userToRoute, setPermissions])

  useEffect(() => {
    if (props.stage && props.stage !== key) {
      setKey(props.stage)
    }
  }, [props, key, setKey])

  useEffect(() => {
    if (stageKey && stageKey !== key) {
      // if ( stageKey.startsWith('@') ) {
      //   currentUserID === stageKey ? setKey("my-page") : setKey("")
      // } else {
      //   setKey(stageKey)
      // }
      stageKey.startsWith('@') ? setKey("my-page") : setKey(stageKey)
    }
  }, [stageKey, key, currentUserID])

  if (permissions) {
    return (
      <div className="dashboard">
          <Breakpoint l down>
            <UserFooterNabber currentKey={key} setKey={setKey} />
          </Breakpoint>
  
          <Breakpoint xl up>
            <Menu currentKey={key} setKey={setKey}/>
          </Breakpoint>
          
          <DashStage stage={key}/>
  
      </div>
    )
  } else return <NotAuthorizedPage/>
}

export default withAuthenticator(CreatorDashPage)