import React, { useState, useEffect } from 'react'
import UiAvatarIcon from '../../archived/UiAvatarIcon'
import { Media } from 'react-bootstrap'
import { fetchPostUser } from '../../../helpers/fetchPostUser'
import { formatDateTime } from '../../../helpers/dataFormatters'

function Comment(props) {
  const [ comment, setComment ] = useState(props.comment)
  const [ loaded, setLoaded ] = useState(false)
  const [ username, setUsername]  = useState('')
  const [ avatarKey, setAvatarKey ] = useState({})

  useEffect(() => {
    if (props.comment !== comment) {
      setComment(props.comment)
    }
    if (!loaded && props.open) {
      fetchPostUser(props.comment.userID, userData => {
        setUsername(userData.display_name)
        setAvatarKey(userData.avatar.key)
      }) 
      return () => { setLoaded(true) }
    }
    
  }, [props, comment, loaded, username, avatarKey])

  return loaded ? (
    <Media className="p-1">

    <div className="align-self-center mr-2">
      <UiAvatarIcon setActiveOff size="2em" imgKey={avatarKey}/>
    </div>

    <Media.Body>
      <div className="mx-auto p-1">

        { comment.text }
      </div>

      <p className="post-timestamp">
        { formatDateTime(comment.createdAt) }
      </p>
      
    </Media.Body>

  </Media>
  ) : <div> Loading Comment </div>
}

export default React.memo(Comment)