import React from 'react'
import { Breakpoint } from 'react-socks'
// import Logo from '../images/SubMe3.gif'
// import mobileLogo from '../images/mobile-title.gif'
import Logo from '../images/logophrase.png'
// import spinner from '../images/4heartspinner.gif'

export default function MainImage() {
    return (
        <div>
            <> 
                <Breakpoint l up >
                    <img className='main-image' src={Logo} alt="sub-me"/>     
                    
                </Breakpoint>
                <Breakpoint m down >
                    <img className='main-image' src={Logo} alt="sub-me"/>        
                    
                </Breakpoint>
            </>
        </div>
    )
}
