import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './styles/theme.css'
import './scss/custom.scss'
import 'rsuite/dist/styles/rsuite-default.css';
import Amplify, { Auth, Storage } from 'aws-amplify'
// import AWSAppSyncClient from 'aws-appsync';
// import { Rehydrated } from 'aws-appsync-react'
import awsmobile from './aws-exports';
import { ApolloProvider, InMemoryCache, split } from '@apollo/client'
import ApolloClient from 'apollo-client';
import { offsetLimitPagination } from "@apollo/client/utilities"
// import { ApolloProvider } from 'react-apollo'
import { ApolloLink } from 'apollo-link';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createHttpLink } from 'apollo-link-http';
// import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { WebSocketLink } from '@apollo/client/link/ws'
// import { getMainDefinition } from '@apollo/client/utilities';
// import { SubscriptionClient, addGraphQLSubscriptions } from 'subscriptions-transport-ws';
import { createAppSyncLink } from 'aws-appsync'

Amplify.configure(awsmobile)

// Storage.configure({ level: 'protected' })

const url = awsmobile.aws_appsync_graphqlEndpoint
const realtimeUrl =  createHttpLink({uri: 'wss://uh47knejprby7ofhezsnfm4jde.appsync-realtime-api.us-east-1.amazonaws.com/graphql'})
const httpURL = createHttpLink(url)

const region = awsmobile.aws_appsync_region
const auth = {
  type: awsmobile.aws_appsync_authenticationType,
  jwtToken: async () => (await Auth.currentSession()).idToken.jwtToken
}
console.log(Auth.currentCredentials())

const wsLink = new WebSocketLink({
  uri: 'wss://uh47knejprby7ofhezsnfm4jde.appsync-realtime-api.us-east-1.amazonaws.com/graphql',
  options: {
    reconnect: true,
    // connectionParams: {
    //   auth
    // }
  },
  // type: "connection-init",
  onError: err => console.log(err),
  onOpen: e => console.log(e),
  onMessage: m => console.log(m)
})

const awsLink = createAppSyncLink({
  url: url,
  region: region,
  auth: {
    type: awsmobile.aws_appsync_authenticationType,
    credentials: () => Auth.currentCredentials(),
    jwtToken: async () =>
      (await Auth.currentSession()).idToken.jwtToken
  },
  complexObjectsCredentials: () => Auth.currentCredentials(),
  options: {
    reconnect: true
  }
});

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createHttpLink({ uri: url })
])

const client = new ApolloClient({
  link: awsLink.concat(link).concat(wsLink),
  credentials: 'include',
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          items: offsetLimitPagination()
        },
      },
    },
  }),
  complexObjectsCredentials: () => Auth.currentCredentials(),
  // defaultOptions: {
  //   watchQuery: {
  //     fetchPolicy: "network-only",
  //     nextFetchPolicy: "cache-only"
  //   }
  // },
})

export const apolloClient = createContext(client)

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      {/* <Rehydrated> */}
        <App />
      {/* </Rehydrated> */}
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
