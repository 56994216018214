import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ConversationPreview from '../components/messaging/ConversationPreview'
import { Breakpoint } from 'react-socks'
import Conversation from '../components/messaging/Conversation'
import useCurrentUser from '../helpers/store/useCurrentUser'
// import useApolloClient from '../helpers/useApolloClient'
// import { gql } from '@apollo/client'
// import { getUserAndConversations } from '../graphql/custom-queries'


//TODO: Should change up routing and params so only conversation pulls convoID?

export default function InboxPage() {
  // let history = useHistory()
  let { convoID } = useParams()

  const [ convo, setConvo ] = useState(false)
  const [ loadedConvos, setLoadedConvos ] = useState([])

  const { id, conversations } = useCurrentUser()

  useEffect(() => {
    if ( convoID && convoID !== convo ) {
      setConvo(convoID)
    }
  }, [convoID, convo, setConvo])

  //NOTE: Needs to check currentUser for conversations
  //      if they exists needs to parse them into conversationPreview
  useEffect(() => {
    if (conversations && conversations.items && conversations.items !== loadedConvos) {
      setLoadedConvos(conversations.items)
    }
  }, [loadedConvos, conversations])


  return (
    <div className="inbox-page">
    
    { convo ? 
        ( <Conversation setConvo={setConvo}/> )
      :
        (
          <>
            <Breakpoint m down >
              <div className="header center">
                  Messages 
              </div>
            </Breakpoint>

            <div className="convo-container">
              { loadedConvos.map(c => (
                <ConversationPreview convo={c} currentUserID={id} key={c.id}/>
              ))}
            </div>
          </>
        )
    }
    
    </div>
  )
}